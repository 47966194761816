import React from "react";
import Header from "../layout/Header/Header.js";

import Footer from "../layout/Footer/Footer.js";
import Banner from "../components/NovaPoints/Banner/Banner.js";

import Top from "../components/top.js";
import usePageTitle from "../hooks/usePageTitle.js";

const NovaPoints = () => {
  usePageTitle(
    "Nova Points | Stacknova AI - Unlock Exclusive Rewards & Rankings"
  );
  return (
    <div className="dashboard-mn">
      <Top />
      <Header />

      <div className="min-h-screen relative bg-[#000] referral">
        <div>
          <div className="top a pt- 5">
            <Banner />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NovaPoints;
