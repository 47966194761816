import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Referral from "../pages/Referral";
import Profile from "../pages/Dashboard";
import Staking from "../pages/Staking.js";
import IntercomInitializer from "../components/IntercomInitializer";
import useScrollRestoration from "../hooks/useScrollRestoration";
import ScrollPositionSaver from "./ScrollPositionSaver";
import NovaPoints from "../pages/NovaPoints.js";
import HowToBuy from "../pages/HowToBuy.js";
import PresaleCountdown from "./presaleCountdown.js";
import CuriosityBanner from "./curiosityBanner.js";

const ScrollRestorationWrapper = ({ children }) => {
  useScrollRestoration();
  return children;
};

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollRestorationWrapper>
        <ScrollPositionSaver />
        <IntercomInitializer />
        <Routes>
          <Route index path="/" exact element={<Home />} />
          <Route path="referral" element={<Referral />} />
          <Route path="dashboard" element={<Profile />} />
          <Route path="staking" element={<Staking />} />
          <Route path="nova-points" element={<NovaPoints />} />
          <Route path="how-to-buy" element={<HowToBuy />} />
        </Routes>
        {/* <CuriosityBanner /> */}
        <PresaleCountdown />
      </ScrollRestorationWrapper>
    </BrowserRouter>
  );
}
