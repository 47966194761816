// utils/getFallbackProvider.js
import { ethers } from "ethers";
import providersByChainId from "./providersByChainId";

/**
 * Returns an Ethers FallbackProvider with multiple RPC endpoints for a specific chainId.
 * @param {number} chainId e.g. 1 (ETH), 56 (BSC), etc.
 * @returns {FallbackProvider}
 */
export function getFallbackProvider(chainId) {
  const urls = providersByChainId[chainId]; // e.g. ["https://mainnet...", "https://ankr.com/..."]
  if (!urls || urls.length === 0) {
    throw new Error(`No provider URLs configured for chainId: ${chainId}`);
  }

  // Wrap each RPC URL in a StaticJsonRpcProvider
  const providerConfigs = urls.map((url) => {
    return new ethers.providers.StaticJsonRpcProvider(url, chainId);
  });

  // Create a FallbackProvider with a "quorum" of 1 (meaning only 1 response needed)
  const fallbackProvider = new ethers.providers.FallbackProvider(
    providerConfigs,
    1
  );

  return fallbackProvider;
}
