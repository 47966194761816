import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllRewards,
  setGlobalRewardsOfUser,
} from "../reducers/referralRewardsSlice";
import { setReferralCount } from "../reducers/referralCountSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { debounce } from "../utils/debounce";
import tokensByChainId from "../utils/tokensByChainId";
import { fetchPrices } from "../utils/helpers";

const useFetchAllReferralRewards = () => {
  const dispatch = useDispatch();
  const { isConnected, address } = useWeb3ModalAccount();
  const user = useSelector((state) => state.user);
  const allRewards = useSelector((state) => state.referralRewards.allRewards);
  const authReady = useSelector((state) => state.auth.authReady);

  const fetchReferralRewards = async () => {
    if (!isConnected || !address || !user?.walletAddress || !authReady) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferralRewards`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ walletAddress: address }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error fetching referral rewards:", errorData.error);
        return;
      }

      const data = await response.json();

      if (!data.rewardsFound) {
        // No referral rewards found
        return;
      }

      const { pending_rewards, claimed_rewards, active_referrals } =
        data.referralLink;
      const prices = await fetchPrices();
      const tokenMap = {
        ETH: "ethereum",
        BNB: "binancecoin",
        POL: "polygon",
        AVAX: "avalanche-2",
        ARB: "arbitrum",
        OP: "optimism",
        ZK: "zk-rollups",
      };

      // Format pending rewards (as before)
      let formattedRewards = {};
      Object.keys(tokensByChainId).forEach((chainId) => {
        formattedRewards[chainId] = {};
        Object.keys(tokensByChainId[chainId]).forEach((currency) => {
          const price = ["USDT", "USDC", "DAI"].includes(currency)
            ? 1
            : prices[tokenMap[currency.toUpperCase()]]?.usd || 0;

          const pendingReward = pending_rewards
            .find((reward) => reward.network === chainId)
            ?.currencies.find((curr) => curr.currency === currency);

          if (pendingReward) {
            const usdValue = pendingReward.amount * price;
            formattedRewards[chainId][currency] = {
              amount: pendingReward.amount.toString(),
              usdValue: usdValue.toString(),
            };
          } else {
            formattedRewards[chainId][currency] = {
              amount: "0",
              usdValue: "0",
            };
          }
        });
      });

      // Now calculate total rewards = claimed + pending
      // We'll create a structure and sum them up
      let totalRewardsMap = {};

      const ensureChainCurrencyExists = (chainId, currency) => {
        if (!totalRewardsMap[chainId]) {
          totalRewardsMap[chainId] = {};
        }
        if (!totalRewardsMap[chainId][currency]) {
          totalRewardsMap[chainId][currency] = { amount: 0, usdValue: 0 };
        }
      };

      // Process claimed rewards
      // claimed_rewards have a 'claimed_price' that we must use for usd value
      if (claimed_rewards) {
        claimed_rewards.forEach((reward) => {
          const { network, currencies } = reward;
          currencies.forEach((c) => {
            const currency = c.currency;
            const amount = c.amount;
            const claimedPrice = c.claimed_price || 0;
            const usdValue = amount * claimedPrice;

            ensureChainCurrencyExists(network, currency);
            totalRewardsMap[network][currency].amount += amount;
            totalRewardsMap[network][currency].usdValue += usdValue;
          });
        });
      }

      // Process pending rewards for total (using current dynamic prices)
      if (pending_rewards) {
        pending_rewards.forEach((reward) => {
          const { network, currencies } = reward;
          currencies.forEach((c) => {
            const currency = c.currency;
            const amount = c.amount;
            const currencyUpper = currency.toUpperCase();
            // For pending, use current price
            const price = ["USDT", "USDC", "DAI"].includes(currencyUpper)
              ? 1
              : prices[tokenMap[currencyUpper]]?.usd || 0;

            const usdValue = amount * price;

            ensureChainCurrencyExists(network, currency);
            totalRewardsMap[network][currency].amount += amount;
            totalRewardsMap[network][currency].usdValue += usdValue;
          });
        });
      }

      // Convert totalRewardsMap to formatted structure
      let formattedTotalRewards = {};
      Object.keys(tokensByChainId).forEach((chainId) => {
        formattedTotalRewards[chainId] = {};
        Object.keys(tokensByChainId[chainId]).forEach((currency) => {
          if (totalRewardsMap[chainId] && totalRewardsMap[chainId][currency]) {
            formattedTotalRewards[chainId][currency] = {
              amount: totalRewardsMap[chainId][currency].amount.toString(),
              usdValue: totalRewardsMap[chainId][currency].usdValue.toString(),
            };
          } else {
            formattedTotalRewards[chainId][currency] = {
              amount: "0",
              usdValue: "0",
            };
          }
        });
      });

      // Dispatch the results
      dispatch(setAllRewards(formattedRewards));
      dispatch(setGlobalRewardsOfUser(formattedTotalRewards));

      const activeReferralCount = active_referrals.length;
      dispatch(setReferralCount(activeReferralCount));
    } catch (error) {
      console.error("Error fetching referral rewards:", error.message);
    }
  };

  const debouncedFetchReferralRewards = useCallback(
    debounce(fetchReferralRewards, 300),
    [address, dispatch]
  );

  useEffect(() => {
    if (!address) return;
    debouncedFetchReferralRewards();
  }, [address, debouncedFetchReferralRewards]);

  return { allRewards, fetchReferralRewards };
};

export default useFetchAllReferralRewards;
