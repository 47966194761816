// src/App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/main.css";
import "./assets/css/styles.css";
import "./assets/css/ande.css";
import "./assets/css/cookie.css";
import "./assets/css/static.css";
import Router from "./config/Router";
import { web3Modal } from "./config/walletConfig";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import useReferralLink from "./hooks/useReferralLink";
import useFetchInvestorDashboard from "./hooks/useFetchInvestorDashboard";
import useFetchAllReferralRewards from "./hooks/useFetchAllReferralRewards";
import useFetchAllReferralTransactions from "./hooks/useFetchReferralTransactions";
import useFetchTopUsersByRewards from "./hooks/useFetchTopUsersByRewards";

import { debounce } from "./utils/debounce";
import { getCookie } from "./utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "./reducers/userSlice";
import { setSnovaData } from "./reducers/snovaDataSlice";
import { setReferralLink } from "./reducers/referralSlice";
import { setMainBalancesData } from "./reducers/mainBalancesSlice";
import axios from "axios";

// 1) Import our new action from authSlice
import { setAuthReady } from "./reducers/authSlice";

/**
 * 1) GET call to prime CSRF cookie (and retrieve any data you want)
 */
async function fetchSnovaData() {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/getSnovaData`,
    { withCredentials: true } // allow setting cookies
  );
  return response.data;
}

async function fetchMainBalancesPublic(walletAddress) {
  try {
    // Build the query string ?walletAddress=...
    const url = `${process.env.REACT_APP_SERVER_URL}/getMainBalances?walletAddress=${walletAddress}`;
    const response = await axios.get(url, {
      withCredentials: true, // if you still want the CSRF cookie
    });
    return response.data; // { totalSnovaTokens, totalSnovaValue, totalReferralEarnings }
  } catch (error) {
    console.error("Error fetching main balances:", error);
    throw error;
  }
}

/**
 * 2) POST call: read 'csrfToken' from cookie and pass as X-CSRF-Token
 */
async function callUserHandler(walletAddress) {
  try {
    const csrfToken = getCookie("csrfToken");
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/userHandler`,
      { walletAddress },
      {
        withCredentials: true,
        headers: { "X-CSRF-Token": csrfToken || "" },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in callUserHandler:", error);
    throw error;
  }
}

/**
 * 3) GET /whoami to see if user is already authed
 */
async function checkIfAuthenticated() {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_SERVER_URL}/whoami`, {
      withCredentials: true,
    });
    if (!resp.data.isAuthenticated) {
      return { isAuthenticated: false };
    }
    return { isAuthenticated: true, userData: resp.data };
  } catch (err) {
    return { isAuthenticated: false };
  }
}

/**
 * 4) POST /auth to set JWT cookie if not yet authenticated
 */
async function callAuth(walletAddress) {
  try {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/auth`,
      { walletAddress },
      { withCredentials: true }
    );
  } catch (error) {
    console.error("Error in callAuth:", error);
    throw error;
  }
}

/**
 * 5) GET /logout to clear cookies (logout)
 */
async function callLogout() {
  try {
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/logout`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error in callLogout:", error);
    throw error;
  }
}

function App() {
  const [isWeb3ModalReady, setWeb3ModalReady] = useState(false);

  // from web3Modal
  const { address, isConnected } = useWeb3ModalAccount();

  // from Redux
  const user = useSelector((state) => state.user);
  // instead of local state for authReady, we read from Redux:
  const authReady = useSelector((state) => state.auth.authReady);

  // we still use the custom hooks as before
  const { referralLink, error } = useReferralLink(address, isConnected);
  const dispatch = useDispatch();

  // Track previous connection status & address
  const [previouslyConnected, setPreviouslyConnected] = useState(false);
  const [previousAddress, setPreviousAddress] = useState(null);

  // ----------------------------------------------------------------------
  // (A) On mount, do GET to set CSRF cookie (and fetch Snova data)
  useEffect(() => {
    (async () => {
      try {
        const data = await fetchSnovaData();
        dispatch(setSnovaData(data));
      } catch (err) {
        console.error("Failed to fetchSnovaData:", err);
      }
    })();
  }, [dispatch]);
  useEffect(() => {
    (async () => {
      if (address) {
        const data = await fetchMainBalancesPublic(address);
        dispatch(setMainBalancesData(data));
      }
    })();
  }, [address]);

  // ----------------------------------------------------------------------
  // (B) Sync with wallet changes => logout & authenticate as needed
  useEffect(() => {
    (async () => {
      // If connected:
      if (isConnected && address) {
        // 1) If address changed => logout old address
        if (
          previouslyConnected &&
          previousAddress &&
          previousAddress !== address
        ) {
          dispatch(
            setUser({
              walletAddress: null,
              verified: null,
            })
          );
          dispatch(setReferralLink(null));
          await callLogout();
          dispatch(setAuthReady(false));
        }

        // 2) Check if we are already authenticated
        const { isAuthenticated, userData } = await checkIfAuthenticated();
        if (!isAuthenticated) {
          // If not, call /auth to set JWT cookie
          await callAuth(address);
        } else {
          // If yes, store user data in Redux
          dispatch(
            setUser({
              walletAddress: userData.walletAddress,
              verified: userData.verified || false,
            })
          );
        }

        // 3) Next, call /userHandler to ensure user is created/updated in DB
        const userDataFromHandler = await callUserHandler(address);
        // // (F1) Finally, fetch the main balances
        // fetchMainBalances();
        dispatch(
          setUser({
            walletAddress: userDataFromHandler.wallet_address,
            verified: userDataFromHandler.verified || false,
          })
        );

        // 4) Mark that auth flow is complete in Redux
        dispatch(setAuthReady(true));

        // 5) Mark that we are connected now
        setPreviouslyConnected(true);
        setPreviousAddress(address);
      } else if (previouslyConnected && !isConnected) {
        // if we were connected but now not
        dispatch(
          setUser({
            walletAddress: null,
            verified: null,
          })
        );
        dispatch(setReferralLink(null));
        await callLogout();
        dispatch(setAuthReady(false));
        setPreviouslyConnected(false);
        setPreviousAddress(null);
      }
    })();
  }, [isConnected, address, previouslyConnected, previousAddress, dispatch]);

  // ----------------------------------------------------------------------
  // (C) Init web3Modal
  useEffect(() => {
    try {
      if (web3Modal) {
        setWeb3ModalReady(true);
      }
    } catch (error) {
      console.error("Failed to initialize web3Modal", error);
    }
  }, []);

  // ----------------------------------------------------------------------
  // (D) Save scroll position on refresh
  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };
    window.addEventListener("beforeunload", saveScrollPosition);
    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);

  const { fetchInvestorDashboard } = useFetchInvestorDashboard();
  const { fetchReferralRewards } = useFetchAllReferralRewards();
  const { fetchReferralTransactions } = useFetchAllReferralTransactions();
  useFetchTopUsersByRewards();

  useEffect(() => {
    // Only fetch if:
    // 1) isConnected
    // 2) user?.walletAddress is set
    // 3) authReady === true (meaning /auth + /userHandler is done in Redux)
    // 4) addresses match
    if (
      isConnected &&
      user?.walletAddress &&
      authReady &&
      user?.walletAddress === address &&
      referralLink
    ) {
      debounce(fetchInvestorDashboard, 1000)();
      fetchReferralRewards();
      fetchReferralTransactions(address);
    } else if (error) {
      console.error("Error managing referral link:", error);
    }
  }, [isConnected, address, user, error, authReady, referralLink]);

  if (!isWeb3ModalReady) {
    return <div />;
  }

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
