// src/utils/verificationUtils.js

export async function fetchNonceForAddress(address) {
  const resp = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/nonce?address=${address}`,
    {
      method: "GET",
      credentials: "include",
    }
  );
  if (!resp.ok) {
    throw new Error("Failed to fetch nonce");
  }
  return await resp.json(); // { nonce, nonceId }
}

export async function verifyWalletOwner(address, signature, nonceId) {
  const resp = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/verifyWalletOwner`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ address, signature, nonceId }),
    }
  );
  if (!resp.ok) {
    const errData = await resp.json();
    throw new Error(errData.error || "Failed to verify wallet");
  }
  return await resp.json(); // { message: "Wallet verified successfully" }
}

export async function checkSession() {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/checkSession`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (resp.ok) {
      return await resp.json(); // { verified: boolean, walletAddress }
    }
  } catch (error) {
    console.error("Error calling /checkSession:", error);
  }
  return { verified: false, walletAddress: null };
}

export async function signAndVerifyIfNeeded(address, provider) {
  //   console.log("→ Checking if user is already verified...");
  const sessionData = await checkSession();
  if (sessionData.verified) {
    // console.log("User is already verified. Skip sign flow.");
    return;
  }
  //   console.log("User is NOT verified. Doing EIP-4361 sign...");

  const { nonce, nonceId } = await fetchNonceForAddress(address);

  const signer = provider.getSigner();
  const signature = await signer.signMessage(nonce);

  await verifyWalletOwner(address, signature, nonceId);

  const sessionData2 = await checkSession();
  if (sessionData2.verified) {
    // console.log("User now verified on server!");
  } else {
    console.warn("User still not verified — something went wrong!");
  }
}
