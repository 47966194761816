import { useState, useCallback } from "react";
import { getCookie } from "../utils/helpers";

const useStoreReferralLink = () => {
  const [error, setError] = useState(null);

  const storeReferralLink = useCallback(
    async (walletAddress, referralId, referrer) => {
      if (!walletAddress || !referralId) {
        console.error("storeReferralLink: Missing walletAddress or referralId");
        return;
      }
      try {
        const csrfToken = getCookie("csrfToken");
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/referralLinkHandler`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken || "",
            },
            body: JSON.stringify({
              walletAddress,
              referrerId: referrer || null,
              referralId,
              totalRewards: [],
              totalRewardsClaimed: [],
              pendingRewards: [],
              boughtSnova: 0,
              pendingRewardsSnova: 0,
              totalSnova: 0,
              boughtNovaPoints: 0,
              novaRewards: 0,
              totalNovaPoints: 0,
              activeReferrals: [],
              totalRewardsInDollar: 0,
            }),
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to store referral link");
        }

        const data = await response.json();
        return data.referral_id;
      } catch (error) {
        console.error("storeReferralLink: Error storing referral link", error);
        setError(error);
        throw error;
      }
    },
    []
  );

  return { storeReferralLink, error };
};

export default useStoreReferralLink;
