// useFetchInvestorDashboard.js

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTotalSnovaTokens } from "../reducers/totalSnovaTokensSlice";
import { setTotalSnovaValue } from "../reducers/totalSnovaValueSlice";
import { setAllTransactions } from "../reducers/transactionsSlice";
import { setTotalNovaPoints } from "../reducers/totalNovaPointsSlice";
import {
  setTotalReferralRewards,
  setPendingReferralRewardsInDollars,
} from "../reducers/totalReferralRewardsSlice";
import { setUserRankingByRewards } from "../reducers/userRankingByRewardsSlice";
import { setUserRankingByNovaPoints } from "../reducers/userRankingByNovaPointsSlice";
import { setNovaPoints } from "../reducers/amountsSlice";
import { setRewards } from "../reducers/referralRewardsSlice";
import { getCookie } from "../utils/helpers";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const useFetchInvestorDashboard = () => {
  const dispatch = useDispatch();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const user = useSelector((state) => state.user);
  const authReady = useSelector((state) => state.auth.authReady);
  const referralLink = useSelector((state) => state.referral.referral);

  const fetchInvestorDashboardWithRetry = async (maxRetries = 3) => {
    if (
      !isConnected ||
      !address ||
      !user?.walletAddress ||
      user?.walletAddress !== address ||
      !authReady ||
      !referralLink
    )
      return;

    const cachedData = localStorage.getItem("investorDashboardDataCashed");
    const lastFetchTime = localStorage.getItem("investorDashboardFetchTime");

    const now = Date.now();

    if (cachedData && lastFetchTime && now - lastFetchTime < 60000) {
      const data = JSON.parse(cachedData);
      dispatchData(data);
      return;
    }

    let attempts = 0;

    while (attempts < maxRetries) {
      try {
        const csrfToken = getCookie("csrfToken");

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/getInvestorDashboard`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken || "",
            },
            body: JSON.stringify({ walletAddress: address }),
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("investorDashboardData", JSON.stringify(data));
          localStorage.setItem(
            "investorDashboardDataCashed",
            JSON.stringify(data)
          );
          localStorage.setItem("investorDashboardFetchTime", Date.now());

          dispatchData(data);
          return;
        } else {
          let errorData = null;
          try {
            errorData = await response.json();
          } catch (jsonError) {
            console.error("Error parsing error response JSON:", jsonError);
          }
          const errorMessage =
            errorData && errorData.error ? errorData.error : "Unknown error";
          // console.error(
          //   "Error fetching investor dashboard data:",
          //   errorMessage
          // );
        }
      } catch (error) {
        console.error(`Attempt ${attempts + 1} failed: ${error.message}`);
      }

      attempts += 1;

      if (attempts >= maxRetries) {
        console.error(
          "Max retries reached. Could not fetch investor dashboard data."
        );
        return;
      }
    }
  };

  // Dispatch function to update Redux store
  const dispatchData = (data) => {
    dispatch(setTotalSnovaTokens(data.totalSnovaTokens || 0));
    dispatch(setRewards({ SNOVA: data.earnedSnovaTokens || 0 }));
    dispatch(setTotalSnovaValue(data.totalSnovaValue || 0));
    dispatch(setAllTransactions(data.transactions || null));
    dispatch(setTotalNovaPoints(data.totalNovaPoints || 0));
    dispatch(setNovaPoints(data.earnedNovaPoints || 0));
    dispatch(setTotalReferralRewards(data.totalReferralRewardsInDollars || 0));
    dispatch(
      setPendingReferralRewardsInDollars(
        data.pendingReferralRewardsInDollars || 0
      )
    );
    dispatch(setUserRankingByRewards(data.userRankingByRewards || null));
    dispatch(setUserRankingByNovaPoints(data.userRankingByNovaPoints || null));
  };

  // Function to reset Redux state
  const resetState = () => {
    dispatch(setTotalSnovaTokens(null));
    dispatch(setRewards({ SNOVA: 0 }));
    dispatch(setTotalSnovaValue(null));
    dispatch(setAllTransactions(null));
    dispatch(setTotalNovaPoints(0));
    dispatch(setNovaPoints(0));
    dispatch(setTotalReferralRewards(0));
    dispatch(setPendingReferralRewardsInDollars(0));
    dispatch(setUserRankingByRewards(null));
    dispatch(setUserRankingByNovaPoints(null));
  };

  // useEffect Hook to watch for address or chainId changes
  useEffect(() => {
    // Clear local storage cache
    localStorage.removeItem("investorDashboardData");
    localStorage.removeItem("investorDashboardFetchTime");

    // Reset Redux state
    resetState();

    // Fetch new data if address and chainId are available
    if (address && chainId) {
      fetchInvestorDashboardWithRetry();
    }
  }, [address]);

  return { fetchInvestorDashboard: fetchInvestorDashboardWithRetry };
};

export default useFetchInvestorDashboard;
