import React from "react";
import "./shimmer.css"; // Ensure you have the shimmer styles imported

const ReferralsListShimmerPlaceholder = () => {
  return (
    <>
      {Array.from({ length: 10 }).map((_, index) => (
        <li
          key={index}
          className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
          style={{ background: "rgb(28,28,28)" }}
        >
          <span className="flex-[1_1_12%] flex">
            <span className="w-[32px] h-[32px] flex justify-center items-center relative">
              <span
                className="shimmer shimmer-text ml-auto"
                style={{ width: "40px" }}
              />
            </span>
          </span>
          <span className="flex-[1_1_25%] flex-evm">
            <div className="shimmer no-wef shimmer-icon w-[32px] h-[32px] rounded-full" />
            <span className="shimmer shimmer-text" style={{ width: "155px" }} />
          </span>
          <span className="flex-[1_1_20%]">
            <span
              className="shimmer shimmer-text ml-auto"
              style={{ width: "120px" }}
            />
          </span>
          <span className="flex-[1_1_20%]">
            <span
              className="shimmer shimmer-text ml-auto"
              style={{ width: "140px" }}
            />
          </span>
          <span className="flex-[1_1_18%] text-right">
            <span
              className="shimmer shimmer-text ml-auto"
              style={{ width: "90px" }}
            />
          </span>
        </li>
      ))}
    </>
  );
};

export default ReferralsListShimmerPlaceholder;
