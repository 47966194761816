// Banner.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import blockies from "ethereum-blockies";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react"; // or correct path

// Local/Custom imports (adjust paths as needed)
import bgRank from "../../../assets/img/bg-rank.webp";
import novaGuide from "../../../assets/img/nova-guide-white.png";
import useFetchTopUsersByRewards from "../../../hooks/useFetchTopUsersByRewards";
import { formatRanking } from "../../../utils/helpers"; // your ranking format helper
import novaPoints from "../../../assets/img/nova-points.svg";
import Eth from "../../../assets/img/eth-icon.svg";
import "./Banner.css";
import { useTranslation } from "react-i18next";
import ConnectButton from "../../Home/Banner/subcomponents/ConnectButton"; // your connect button component
import UserListShimmerPlaceholder from "./shimmers/UserListShimmerPlaceholder";
import UserListShimmerPlaceholderMobile from "./shimmers/UserListShimmerPlaceholderMobile";

// Optional helper for pagination
function getPaginationPages(currentPage, totalPages) {
  const pages = [];
  let start = Math.max(1, currentPage - 1);
  let end = Math.min(totalPages, currentPage + 1);
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
}

const Banner = () => {
  const { t } = useTranslation();
  const { isConnected, address } = useWeb3ModalAccount();

  // Custom hook to fetch data from the server (Nova Points, etc.)
  const { loading, error } = useFetchTopUsersByRewards();

  // Grab data from Redux (Nova Points ranking)
  const topByNovaPoints = useSelector(
    (state) => state.userRankings.topUsersByNovaPoints
  );

  // Grab data from Redux (Earnings ranking) — adapt if your slice/store is named differently
  const topUsersByEarnings = useSelector(
    (state) => state.userRankings.topUsersByEarnings
  );

  // Example: user ranking, total points, etc.
  const userRankingByNovaPoints = useSelector(
    (state) => state.userRankingByNovaPoints.ranking
  );
  const totalNovaPoints = useSelector(
    (state) => state.totalNovaPoints.totalNovaPoints
  );

  // Local states
  const [topEarningPage, setTopEarningPage] = useState(1);
  const [TopEarning, setTopEarning] = useState(true); // example toggle if needed

  // For pagination
  const itemsPerPage = 5;

  // ============ Nova Points PAGINATION (Scoreboard) ============
  const totalEarnerCount = Array.isArray(topByNovaPoints)
    ? topByNovaPoints.length
    : 0;
  const topEarningTotalPages = Math.max(
    1,
    Math.ceil(totalEarnerCount / itemsPerPage)
  );

  const topEarningPageData = Array.isArray(topByNovaPoints)
    ? topByNovaPoints.slice(
        (topEarningPage - 1) * itemsPerPage,
        topEarningPage * itemsPerPage
      )
    : [];

  // ============ Earnings PAGINATION (Mobile Top Earners) ============
  // If you want separate pagination for "Top Earners":
  const totalEarningsCount = Array.isArray(topUsersByEarnings)
    ? topUsersByEarnings.length
    : 0;
  const topEarningsData = Array.isArray(topUsersByEarnings)
    ? topUsersByEarnings.slice(
        (topEarningPage - 1) * itemsPerPage,
        topEarningPage * itemsPerPage
      )
    : [];

  // Detect desktop vs. mobile
  const [isDesk, setIsDesk] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesk(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // If connected, create a blockies icon for the user (scoreboard row)
  const icon = blockies.create({
    seed: address?.toLowerCase() ?? "0x0000000000",
    size: 8,
    scale: 4,
    color: "#FC6432",
    bgcolor: "#3B334C",
    spotcolor: "#C04000",
  });
  const iconDataURL = icon.toDataURL();

  return (
    <>
      <div className="relative overflow-hidden dab-k pt-5 banner-sd bg-black">
        {/* ======== Animated background globe (optional) ======== */}
        <div
          className="opacity-0 h-baner animate-[hero-enter_2s_ease-out_0.5s_forwards] absolute top-[216px] ai-dt:top-[184px] globe w-[1459px] h-[1461px]"
          style={{ left: "50%", transform: "translate(-50%, 0%)" }}
        >
          <div
            className="w-[100%] banner-globe height-ati h-[100%] relative"
            style={{
              transform: "rotate(180deg)",
              zIndex: 4,
              flexShrink: 0,
              borderRadius: "100%",
              opacity: "0.4",
              mixBlendMode: "luminosity",
              rotate: "180deg",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={1459}
              viewBox="0 0 1459 1461"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M0.499936 730.5C0.499901 1133.67 326.885 1460.5 729.5 1460.5C1132.11 1460.5 1458.5 1133.67 1458.5 730.5C1458.5 327.331 1132.11 0.499971 729.5 0.499936C326.885 0.499901 0.499971 327.331 0.499936 730.5Z"
                stroke="transparent"
              />
            </svg>
          </div>
        </div>

        <div>
          <div className="styles_hero_wrapper__YKVi1">
            <div className="styles_heading__G8N8m dgra text-banner">
              <div
                className="_initial_401a9_1 _show_401a9_24 mb-676"
                style={{ transitionDelay: "0.1s" }}
              >
                {/* ======== Return to Dashboard button ======== */}
                <div>
                  <div className="pt-[ text-left laptop:pt-[52px]">
                    <Link
                      className="_btn_dcpj6_1 mb-10 inline-flex items-center self-start gap-[10px] p-[8px]"
                      to="/dashboard"
                    >
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 14 12"
                        fill="#fff"
                        width={14}
                        height={12}
                      >
                        <polygon
                          className="st0"
                          points="3.5 5 3.5 3.5 5.1 3.5 5.1 1.9 6.8 1.9 6.8 .2 5.1 .2 5.1 1.8 3.4 1.8 3.4 3.5 1.8 3.5 1.8 5 0 5 0 6.8 1.7 6.8 1.7 8.5 3.4 8.5 3.4 6.8 13.9 6.8 13.9 5 3.5 5"
                        />
                        <polygon
                          className="st0"
                          points="5.2 8.5 3.5 8.5 3.5 10.2 5.1 10.2 5.1 11.8 6.8 11.8 6.8 10.1 5.2 10.1 5.2 8.5"
                        />
                      </svg>
                      <span className="font-[500] text-[white]">
                        {t("novaPointsPage.banner.returnToDashboard")}
                      </span>
                    </Link>
                  </div>
                </div>

                {/* 
                  ===========
                  Main Container for everything (ConnectButton or Leaderboard)
                  ===========
                */}
                <div
                  className="page_tableContainer__p8Vj_"
                  style={{ width: isDesk ? "750px" : "800px" }}
                >
                  <div
                    className={
                      isDesk
                        ? "rounded-[30px] bg-baseBg flex flex-col text-left w-full p-[32px]"
                        : "rounded-[30px] bg-baseBg flex flex-col text-left w-full p-[20px]"
                    }
                  >
                    <div className="flex mb-7 justify-between items-center">
                      <div>
                        <p className="headingOne flex items-center white-text font-semibold dashboard-snova-heading">
                          {t("novaPointsPage.banner.ScoreLeaderboard")}
                        </p>
                      </div>

                      {/* Nova Guide Button */}
                      {isDesk ? (
                        <a
                          href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale/nova-points"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flat-toggle box-text bx-2 box-btn-nova-guide corner-box flex items-center"
                        >
                          <span className="toggle-title">
                            {t("homePage.platforms.learnMore")}
                          </span>
                        </a>
                      ) : (
                        <a
                          href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale/nova-points"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="docs-btn">
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                            <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                            <img
                              alt="asset icon"
                              loading="lazy"
                              width={24}
                              height={24}
                              decoding="async"
                              data-nimg={1}
                              className="mb-1 smallera ms-2"
                              src={novaGuide}
                              style={{
                                color: "transparent",
                                marginRight: "5px",
                              }}
                            />
                          </button>
                        </a>
                      )}
                    </div>

                    {/* 
                      ============ DESKTOP VIEW ============ 
                      If user is on Desktop, show either:
                        1) Connect Button (if NOT connected)
                        2) Or the Desktop scoreboard (if connected)
                    */}
                    {isDesk ? (
                      !isConnected ? (
                        <div className="grid grid-cols-1 gap-6 mb-7 items-center justify-center">
                          <ConnectButton
                            className="ant-btn flex jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                            style={{
                              width: "100%",
                              backgroundColor: "#fc6432",
                              color: "#fff",
                              boxShadow: "6px 6px #C04000",
                            }}
                            buttonText={t("Header.connectWallet")}
                          >
                            <svg
                              width={20}
                              className="wallet-icon no-tp"
                              viewBox="0 0 16 15"
                              fill="white"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                              />
                            </svg>
                            <span className="btn-buy-token" />
                          </ConnectButton>
                        </div>
                      ) : (
                        <>
                          {/* If connected, show scoreboard (Nova Points) */}
                          <div className="rank-updated main-nets">
                            <div className="dab-k no-dbk w-full">
                              <div className="_line_18dqk_1 mb-[8px]" />
                              <div
                                className="pr-[20px] pl-[12px] flex items-center text-[16px] font-medium leading-[120%] mb-[18px] thishid"
                                style={{
                                  color: "rgba(255, 255, 255, 0.32)",
                                }}
                              >
                                <span
                                  className="pl-[8px]"
                                  style={{ width: "20%" }}
                                >
                                  #
                                </span>
                                <span className="flex-[1_1_25%]">
                                  <span className="flex gap-[8px] items-center">
                                    <img className="max-w-[20px]" src={Eth} />
                                    EVM Wallet Address
                                  </span>
                                </span>
                                <span
                                  className="inline-flex"
                                  style={{ width: "20%" }}
                                >
                                  Nova Points
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* SCOREBOARD TABLE (Your Ranking, Nova Points) */}
                          <div
                            className="user-list rank-updated main-nets"
                            style={{ marginTop: "0px" }}
                          >
                            <div className="dab-k no-dbk w-full">
                              <div
                                className="page_tableContainer__p8Vj_ max-diffee"
                                style={{
                                  background: "transparent",
                                  marginBottom: "0",
                                }}
                              >
                                <div
                                  style={{ padding: "0" }}
                                  className="text-center table-wrappers text-white rounded-[30px] bg-baseBg flex flex-col text-left w-full p-[32px] sm:p-[20px] py-20"
                                >
                                  <div className="p-0 bg-black rounded-[20px] w-full relative">
                                    <li
                                      className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
                                      style={{
                                        background:
                                          "linear-gradient(135deg, #1c1c1c, #fc643233)",
                                        border: "0.7px solid #FC6432",
                                      }}
                                    >
                                      <span
                                        className="flex"
                                        style={{ width: "20%" }}
                                      >
                                        <span className="w-[32px] h-[32px] flex justify-center items-center relative">
                                          {/* corner borders */}
                                          <svg
                                            fill="none"
                                            height="34"
                                            width="34"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 34 34"
                                          >
                                            <path
                                              d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                              stroke="#fc6432"
                                              strokeOpacity="0.5"
                                            />
                                            <path
                                              d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                              stroke="#fc6432"
                                              strokeOpacity="0.5"
                                            />
                                            <path
                                              d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                              stroke="#fc6432"
                                              strokeOpacity="0.5"
                                            />
                                            <path
                                              d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                              stroke="#fc6432"
                                              strokeOpacity="0.5"
                                            />
                                            <text
                                              x="50%"
                                              y="53%"
                                              fill="#FAFAFA"
                                              fontFamily="Matter SQ"
                                              fontSize="16"
                                              fontWeight="bold"
                                              textAnchor="middle"
                                              dominantBaseline="middle"
                                            >
                                              {userRankingByNovaPoints ||
                                              userRankingByNovaPoints === 0
                                                ? userRankingByNovaPoints
                                                : "..."}
                                            </text>
                                          </svg>
                                        </span>
                                      </span>
                                      <span className="flex-[1_1_25%] flex-evm">
                                        <div className="w-[32px] h-[32px] rounded-full bg-defaultOrange overflow-hidden mr-[8px]">
                                          <img
                                            src={iconDataURL}
                                            alt="user-avatar"
                                          />
                                        </div>
                                        {/* Truncated user wallet address */}
                                        {address.slice(0, 6)}
                                        ...
                                        {address.slice(-4)}
                                      </span>
                                      <span
                                        className="inline-flex"
                                        style={{ width: "15%" }}
                                      >
                                        <img
                                          alt="asset icon"
                                          src={novaPoints}
                                          style={{
                                            alignItems: "right",
                                            height: "18px",
                                            paddingRight: "5px",
                                          }}
                                        />
                                        {totalNovaPoints ||
                                        totalNovaPoints === 0
                                          ? parseFloat(totalNovaPoints).toFixed(
                                              2
                                            )
                                          : "..."}
                                      </span>
                                    </li>
                                    {!Array.isArray(topByNovaPoints) ||
                                    topByNovaPoints.length === 0 ? (
                                      <UserListShimmerPlaceholder />
                                    ) : (
                                      topByNovaPoints.map((user, idx) => {
                                        // Create blockies icon for each user
                                        const userIcon = blockies.create({
                                          seed: user.wallet_address.toLowerCase(),
                                          size: 8,
                                          scale: 4,
                                          color: "#FC6432",
                                          bgcolor: "#3B334C",
                                          spotcolor: "#C04000",
                                        });
                                        const userIconDataURL =
                                          userIcon.toDataURL();
                                        const isCurrentUser =
                                          address &&
                                          address.toLowerCase() ===
                                            user.wallet_address.toLowerCase();
                                        return (
                                          <li
                                            key={idx}
                                            className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
                                            style={{
                                              background: isCurrentUser
                                                ? "linear-gradient(135deg, #1c1c1c, #fc643233)"
                                                : "rgb(28,28,28)",
                                            }}
                                          >
                                            <span
                                              className="flex"
                                              style={{ width: "20%" }}
                                            >
                                              <span className="w-[32px] h-[32px] flex justify-center items-center relative">
                                                {/* corner borders */}
                                                <svg
                                                  fill="none"
                                                  height="34"
                                                  width="34"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 34 34"
                                                >
                                                  <path
                                                    d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                                    stroke="#fc6432"
                                                    strokeOpacity="0.5"
                                                  />
                                                  <path
                                                    d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                                    stroke="#fc6432"
                                                    strokeOpacity="0.5"
                                                  />
                                                  <path
                                                    d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                                    stroke="#fc6432"
                                                    strokeOpacity="0.5"
                                                  />
                                                  <path
                                                    d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                                    stroke="#fc6432"
                                                    strokeOpacity="0.5"
                                                  />
                                                  <text
                                                    x="50%"
                                                    y="53%"
                                                    fill="#FAFAFA"
                                                    fontFamily="Matter SQ"
                                                    fontSize="16"
                                                    fontWeight="bold"
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                  >
                                                    {user.nova_points_ranking}
                                                  </text>
                                                </svg>
                                              </span>
                                            </span>
                                            <span className="flex-[1_1_25%] flex-evm">
                                              <div className="w-[32px] h-[32px] rounded-full bg-defaultOrange overflow-hidden mr-[8px]">
                                                <img
                                                  src={userIconDataURL}
                                                  alt="user-avatar"
                                                />
                                              </div>
                                              {/* Truncated user wallet address */}
                                              {user.wallet_address.slice(0, 6)}
                                              ...
                                              {user.wallet_address.slice(-4)}
                                            </span>
                                            <span
                                              className="inline-flex"
                                              style={{ width: "15%" }}
                                            >
                                              <img
                                                alt="asset icon"
                                                src={novaPoints}
                                                style={{
                                                  alignItems: "right",
                                                  height: "18px",
                                                  paddingRight: "5px",
                                                }}
                                              />
                                              {parseFloat(
                                                user.total_nova_points
                                              ).toFixed(2)}
                                            </span>
                                          </li>
                                        );
                                      })
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      // ============ MOBILE VIEW ============
                      <>
                        {/* If we're on mobile, show shimmer if loading/error/no data,
                            otherwise show the "Top Earners (Mobile)" list */}
                        {!Array.isArray(topByNovaPoints) ||
                        topByNovaPoints.length === 0 ? (
                          <UserListShimmerPlaceholderMobile />
                        ) : (
                          <div
                            className="user-list rank-updated"
                            style={{ width: "100%" }}
                          >
                            <div className="dab-k no-dbk">
                              <div
                                className="_line_18dqk_1 mb-[8px]"
                                style={{ width: "100%" }}
                              />
                              <div className="flex flex-col gap-[6px] laptop:gap-[4px]">
                                <li
                                  className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
                                  style={{
                                    // background:
                                    //   "linear-gradient(135deg, #1c1c1c, #fc643233)",
                                    color: "rgba(255, 255, 255, 0.32)",
                                    border: "0.7px solid #FC6432",

                                    position: "relative", // So our gradient overlay is positioned correctly
                                  }}
                                >
                                  {/* The gradient overlay */}
                                  <div
                                    className="absolute rounded-[8px] top-0 left-0 w-full h-full"
                                    style={{
                                      zIndex: 1,
                                      background:
                                        "linear-gradient(135deg, rgba(28,28,28,0.6), rgba(252,100,50,0.3))",
                                      pointerEvents: "none", // so it doesn't block clicks, if desired
                                    }}
                                  ></div>
                                  <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
                                    {/* The background image */}
                                    <img
                                      className="absolute w-full h-full object-cover top-0 left-0"
                                      src={bgRank}
                                      alt="bg"
                                      style={{ zIndex: 0 }}
                                    />

                                    <div
                                      className="flex items-center"
                                      style={{
                                        position: "relative",
                                        zIndex: 2,
                                      }}
                                    >
                                      <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px] text-[#FAFAFA]">
                                        {/* corners */}

                                        <svg
                                          fill="none"
                                          height="34"
                                          width="34"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 34 34"
                                        >
                                          <path
                                            d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                            stroke="#fc6432"
                                            strokeOpacity="0.5"
                                          />
                                          <path
                                            d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                            stroke="#fc6432"
                                            strokeOpacity="0.5"
                                          />
                                          <path
                                            d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                            stroke="#fc6432"
                                            strokeOpacity="0.5"
                                          />
                                          <path
                                            d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                            stroke="#fc6432"
                                            strokeOpacity="0.5"
                                          />
                                          <text
                                            x="50%"
                                            y="53%"
                                            fill="#FAFAFA"
                                            fontFamily="Matter SQ"
                                            fontSize="16"
                                            fontWeight="bold"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                          >
                                            {userRankingByNovaPoints ||
                                            userRankingByNovaPoints === 0
                                              ? userRankingByNovaPoints
                                              : "..."}
                                          </text>
                                        </svg>
                                      </span>
                                      <span className="flex gap-[8px] items-center">
                                        {/* <img
                                            className="max-w-[20px]"
                                            src={Eth}
                                          /> */}
                                        EVM
                                      </span>
                                    </div>
                                    <span
                                      className="text-[#FAFAFA]"
                                      style={{
                                        position: "relative",
                                        zIndex: 2,
                                      }}
                                    >
                                      {address.slice(0, 6)}
                                      ...
                                      {address.slice(-4)}
                                    </span>
                                  </div>
                                  <div className="flex flex-col gap-[12px] text-white">
                                    <div className="flex justify-between items-center">
                                      <span>Nova Points</span>
                                      <span className="text-[#FAFAFA] inline-flex gap-1">
                                        <img
                                          alt="asset icon"
                                          src={novaPoints}
                                          style={{
                                            height: "18px",
                                          }}
                                        />
                                        {totalNovaPoints ||
                                        totalNovaPoints === 0
                                          ? parseFloat(totalNovaPoints).toFixed(
                                              2
                                            )
                                          : "..."}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                {topByNovaPoints.map((user, idx) => {
                                  const isCurrentUser =
                                    address &&
                                    address.toLowerCase() ===
                                      user.wallet_address.toLowerCase();
                                  return (
                                    <li
                                      key={idx}
                                      className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
                                      style={{
                                        background: isCurrentUser
                                          ? "linear-gradient(135deg, #1c1c1c, #fc643233)"
                                          : "rgb(28,28,28)",
                                        color: "rgba(255, 255, 255, 0.32)",
                                        position: "relative",
                                      }}
                                    >
                                      {/* The gradient overlay */}
                                      {isCurrentUser ? (
                                        <div
                                          className="absolute rounded-[8px] top-0 left-0 w-full h-full"
                                          style={{
                                            zIndex: 1,
                                            background:
                                              "linear-gradient(135deg, rgba(28,28,28,0.6), rgba(252,100,50,0.3))",
                                            pointerEvents: "none", // so it doesn't block clicks, if desired
                                          }}
                                        ></div>
                                      ) : (
                                        <></>
                                      )}
                                      <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
                                        {/* The background image */}
                                        <img
                                          className="absolute w-full h-full object-cover top-0 left-0"
                                          src={bgRank}
                                          alt="bg"
                                          style={{ zIndex: 0 }}
                                        />

                                        <div
                                          className="flex items-center"
                                          style={{
                                            position: "relative",
                                            zIndex: 2,
                                          }}
                                        >
                                          <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px] text-[#FAFAFA]">
                                            {/* corners */}

                                            <svg
                                              fill="none"
                                              height="34"
                                              width="34"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 34 34"
                                            >
                                              <path
                                                d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                                stroke="#fc6432"
                                                strokeOpacity="0.5"
                                              />
                                              <path
                                                d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                                stroke="#fc6432"
                                                strokeOpacity="0.5"
                                              />
                                              <path
                                                d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                                stroke="#fc6432"
                                                strokeOpacity="0.5"
                                              />
                                              <path
                                                d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                                stroke="#fc6432"
                                                strokeOpacity="0.5"
                                              />
                                              <text
                                                x="50%"
                                                y="53%"
                                                fill="#FAFAFA"
                                                fontFamily="Matter SQ"
                                                fontSize="16"
                                                fontWeight="bold"
                                                textAnchor="middle"
                                                dominantBaseline="middle"
                                              >
                                                {user.nova_points_ranking}
                                              </text>
                                            </svg>
                                          </span>
                                          <span className="flex gap-[8px] items-center">
                                            {/* <img
                                            className="max-w-[20px]"
                                            src={Eth}
                                          /> */}
                                            EVM
                                          </span>
                                        </div>
                                        <span
                                          className="text-[#FAFAFA]"
                                          style={{
                                            position: "relative",
                                            zIndex: 2,
                                          }}
                                        >
                                          {user.wallet_address.slice(0, 6)}
                                          ...
                                          {user.wallet_address.slice(-4)}
                                        </span>
                                      </div>
                                      <div className="flex flex-col gap-[12px] text-white">
                                        <div className="flex justify-between items-center">
                                          <span>Nova Points</span>
                                          <span className="text-[#FAFAFA] inline-flex gap-1">
                                            <img
                                              alt="asset icon"
                                              src={novaPoints}
                                              style={{
                                                height: "18px",
                                              }}
                                            />
                                            {parseFloat(
                                              user.total_nova_points
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ================== Stars BG Animation (optional) ================== */}
        <img
          className="star absolute hero-star"
          alt="Star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "22%",
            bottom: "59%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "242s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          alt="Star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "22%",
            bottom: "59%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "242s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          alt="Star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "135%",
            bottom: "84%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "130s, 6s",
            zIndex: 90,
          }}
        />
      </div>
    </>
  );
};

export default Banner;
