import React from "react";
import "./index.css";
import rocketIcon from "../../assets/img/rocket-icon.svg";
import needHelp from "../../assets/img/need-help.png";
import Mblstep2 from "../../assets/img/how-to-buy/Step2_Connect_Wallet_Mobile.png";
import Mblstep3 from "../../assets/img/how-to-buy/Step3_Select_Network_Mobile.png";
import Mblstep4 from "../../assets/img/how-to-buy/Step4_Choose_Payment_Mobile.png";
import Mblstep5 from "../../assets/img/how-to-buy/Step5_Enter_Amount_Mobile.png";
import Mblstep6 from "../../assets/img/how-to-buy/Step6_Complete_Purchase_Mobile.png";
import Mblstep7 from "../../assets/img/how-to-buy/Step7_Purchase_Summary_Mobile.png";
import Mblstep8 from "../../assets/img/how-to-buy/Step8_View_SNOVA_Tokens_Mobile.png";
import step2 from "../../assets/img/how-to-buy/Step2_Connect_Wallet.png";
import step3 from "../../assets/img/how-to-buy/Step3_Select_Network.png";
import step4 from "../../assets/img/how-to-buy/Step4_Choose_Payment.png";
import step5 from "../../assets/img/how-to-buy/Step5_Enter_Amount.png";
import step6 from "../../assets/img/how-to-buy/Step6_Complete_Purchase.png";
import step7 from "../../assets/img/how-to-buy/Step7_Purchase_Summary.png";
import step8 from "../../assets/img/how-to-buy/Step8_View_SNOVA_Tokens.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="main-buy">
      <div class="flex flex-col  items-center justify-center gap-[32px] laptop:gap-[40px]">
        <div className="relative w-full">
          <div className="relative  z-20 laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div className="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div className="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="53%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      1
                    </text>
                  </svg>
                </div>
                <span className="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.connectWallet")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.connectWalletDescription")}{" "}
                <Link to="/" className="text-[#fc6432] underline">
                  {t("howToBuy.platform")}
                </Link>
                . {t("howToBuy.connectWalletInstructions")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.connectWalletInstructionsBold")}
                </span>{" "}
                {t("howToBuy.connectWalletInstructionsMiddle")}{" "}
                <span className="font-bold">{t("howToBuy.metaMask")}</span>,{" "}
                <span className="font-bold">{t("howToBuy.trustWallet")}</span>,{" "}
                <span className="font-bold">
                  {t("howToBuy.coinbaseWallet")}
                </span>
                , {""}
                {t("howToBuy.connectWalletInstructionsLast")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <div className="snapshot-container desk-snap">
                <img
                  className="snapshot-image"
                  src={step2}
                  alt={t("howToBuy.step1Alt")}
                />
              </div>
              <div class="snapshot-container mbl-snap">
                <img class="snapshot-image" src={Mblstep2} alt="Step 2" />
              </div>
            </div>
          </div>
        </div>

        <div className="relative w-full">
          <div className="absolute right-0">
            <div className="hidden laptop:block">
              <svg
                fill="none"
                height="733"
                viewBox="0 0 640 733"
                width="640"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_9893)">
                  <path
                    clipRule="evenodd"
                    d="M449.34 533C496.522 533 558.171 521.503 604.634 529.704C614.074 531.371 623.789 532.24 633.706 532.24C725.452 532.24 799.826 457.865 799.826 366.12C799.826 274.374 725.452 200 633.706 200C551.368 200 483.02 259.905 469.871 338.509C468.256 348.162 459.127 355.096 449.34 355.096C426.938 355.096 397.666 339.423 386.354 320.086C369.075 290.547 337.017 270.705 300.326 270.705C245.32 270.705 200.73 315.296 200.73 370.301C200.73 425.307 245.32 469.897 300.326 469.897C327.971 469.897 367.093 487.774 386.73 507.234C402.804 523.162 424.923 533 449.34 533Z"
                    fill="url(#paint0_radial_1109_9893)"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="733"
                    id="filter0_f_1109_9893"
                    width="999.097"
                    x="0.729492"
                    y="0"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feBlend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_9893"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(246.346 392.349) rotate(-5.94758) scale(631.003 97.471)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_9893"
                    r="1"
                  >
                    <stop stopColor="#FFAA66"></stop>

                    <stop
                      offset="0.230625"
                      stopColor="#FF7F3F"
                      stopOpacity="0.8"
                    ></stop>

                    <stop offset="1" stopColor="#FF5A1F" stopOpacity="0"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="relative max-w-1000 z-20 laptop:mx-auto gap-[24px]  flex flex-col justify-center">
            <div className="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div className="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="50%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      2
                    </text>
                  </svg>
                </div>
                <span className="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.selectBlockchain")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.selectBlockchainDescription")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.chooseNetwork")}
                </span>{" "}
                {t("howToBuy.selectBlockchainDescriptionSecond")}{" "}
                <span className="font-bold ">{t("howToBuy.ethereum")}</span>,{" "}
                <span className="font-bold ">{t("howToBuy.polygon")}</span>,{" "}
                {t("howToBuy.or")}{" "}
                <span className="font-bold ">{t("howToBuy.arbitrum")}</span>.{" "}
                {t("howToBuy.selectBlockchainDescriptionLast")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <div className="snapshot-container desk-snap">
                <img
                  className="snapshot-image"
                  src={step3}
                  alt={t("howToBuy.step2Alt")}
                />
              </div>
              <div class="snapshot-container mbl-snap">
                <img class="snapshot-image" src={Mblstep3} alt="Step 3" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full">
          <div className="absolute left-0">
            <div className="laptop:hidden">
              <svg
                fill="none"
                height="648"
                viewBox="0 0 360 648"
                width="360"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_10972)">
                  <path
                    clipRule="evenodd"
                    d="M3.21424 254.685C-10.7475 249.295 -20.6889 234.347 -19.1942 219.455C-16.5351 192.964 -21.8635 165.42 -36.1921 140.602C-72.5411 77.6434 -153.046 56.0723 -216.004 92.4213C-278.962 128.77 -300.533 209.275 -264.184 272.233C-247.929 300.388 -222.843 320.266 -194.445 330.389C-164.2 341.169 -135.347 369.944 -119.292 397.751C-91.7173 445.513 -30.6449 461.877 17.1166 434.302C35.3378 423.782 60.4863 422.329 78.9898 432.344C115.567 452.142 161.262 453.045 199.922 430.724C259.131 396.54 279.417 320.831 245.233 261.622C211.049 202.414 135.34 182.127 76.1311 216.311C62.8336 223.989 51.4993 233.76 42.3044 244.97C33.2011 256.067 16.6042 259.855 3.21424 254.685Z"
                    fill="url(#paint0_radial_1109_10972)"
                    fillOpacity="0.9"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="772.933"
                    id="filter0_f_1109_10972"
                    width="943.681"
                    x="-481.842"
                    y="-125.236"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feBlend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_10972"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(-212.811 196.273) rotate(14.5891) scale(486.79 78.9258)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_10972"
                    r="1"
                  >
                    <stop stopColor="#FFAA66"></stop>
                    <stop
                      offset="0.17"
                      stopColor="#FF7F3F"
                      stopOpacity="0.85"
                    ></stop>
                    <stop offset="0.428018" stopColor="#FF5A1F"></stop>
                    <stop offset="0.69" stopColor="#CC3700"></stop>
                    <stop offset="1" stopColor="#12111B"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div className="hidden laptop:block">
              <svg
                fill="none"
                height="837"
                viewBox="0 0 596 837"
                width="596"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_9925)">
                  <path
                    clipRule="evenodd"
                    d="M56.5287 635.534C2.30069 629.177 -66.731 616.314 -119.789 629.197C-136.313 633.21 -153.574 635.336 -171.332 635.336C-291.547 635.336 -389 537.883 -389 417.668C-389 297.453 -291.547 200 -171.332 200C-63.4429 200 26.1128 278.494 43.3433 381.489C45.4593 394.137 57.421 403.223 70.2452 403.223C99.599 403.223 137.953 382.687 152.774 357.35C175.415 318.644 217.421 292.646 265.499 292.646C337.572 292.646 396 351.073 396 423.147C396 495.221 337.572 553.648 265.499 553.648C229.275 553.648 178.015 577.072 152.284 602.57C131.223 623.441 102.239 636.332 70.2452 636.332C65.6047 636.332 61.0276 636.061 56.5287 635.534Z"
                    fill="url(#paint0_radial_1109_9925)"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="836.333"
                    id="filter0_f_1109_9925"
                    width="1185"
                    x="-589"
                    y="0"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feBlend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_9925"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(336.228 452.037) rotate(-174.052) scale(826.808 154.115)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_9925"
                    r="1"
                  >
                    <stop stopColor="#FFAA66"></stop>
                    <stop
                      offset="0.230625"
                      stopColor="#FF7F3F"
                      stopOpacity="0.85"
                    ></stop>
                    <stop offset="1" stopColor="#12111B" stopOpacity="0"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="relative z-20 laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div className="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div className="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="53%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      3
                    </text>
                  </svg>
                </div>
                <span className="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.choosePaymentMethod")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.choosePaymentMethodDescription")}{" "}
                <span className="font-bold ">
                  {t("howToBuy.choosePaymentMethodDescriptionBold")}
                </span>
                . {t("howToBuy.choosePaymentMethodDescriptionLast")}{" "}
                <a
                  href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale/faqs-for-presale#what-are-the-accepted-currencies-for-the-stacknova-presale-and-on-which-blockchain-platforms-can-i-p"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#fc6432] underline"
                >
                  {t("howToBuy.documentationLink")}
                </a>
                .
              </span>
            </div>
            <div className="snapshot-container desk-snap">
              <img
                className="snapshot-image"
                src={step4}
                alt={t("howToBuy.step3Alt")}
              />
            </div>
            <div class="snapshot-container mbl-snap">
              <img class="snapshot-image" src={Mblstep4} alt="Step 4" />
            </div>
          </div>
        </div>

        <div className="relative w-full">
          <div className="relative z-20 laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div className="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div className="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="53%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      4
                    </text>
                  </svg>
                </div>
                <span className="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.enterPurchaseAmount")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.enterPurchaseAmountDescription")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.enterPurchaseAmountDescriptionOrange")}
                </span>
                , {t("howToBuy.enterPurchaseAmountDescriptionLast")}
              </span>
            </div>
            <div className="snapshot-container desk-snap">
              <img
                className="snapshot-image"
                src={step5}
                alt={t("howToBuy.step4Alt")}
              />
            </div>
            <div class="snapshot-container mbl-snap">
              <img class="snapshot-image" src={Mblstep5} alt="Step 5" />
            </div>
          </div>
        </div>

        {/*  */}

        <div class="relative w-full">
          <div className="absolute right-0">
            <div className="hidden laptop:block">
              <svg
                fill="none"
                height="733"
                viewBox="0 0 640 733"
                width="640"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_9893)">
                  <path
                    clipRule="evenodd"
                    d="M449.34 533C496.522 533 558.171 521.503 604.634 529.704C614.074 531.371 623.789 532.24 633.706 532.24C725.452 532.24 799.826 457.865 799.826 366.12C799.826 274.374 725.452 200 633.706 200C551.368 200 483.02 259.905 469.871 338.509C468.256 348.162 459.127 355.096 449.34 355.096C426.938 355.096 397.666 339.423 386.354 320.086C369.075 290.547 337.017 270.705 300.326 270.705C245.32 270.705 200.73 315.296 200.73 370.301C200.73 425.307 245.32 469.897 300.326 469.897C327.971 469.897 367.093 487.774 386.73 507.234C402.804 523.162 424.923 533 449.34 533Z"
                    fill="url(#paint0_radial_1109_9893)"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="733"
                    id="filter0_f_1109_9893"
                    width="999.097"
                    x="0.729492"
                    y="0"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feBlend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_9893"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(246.346 392.349) rotate(-5.94758) scale(631.003 97.471)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_9893"
                    r="1"
                  >
                    <stop stopColor="#FFAA66"></stop>

                    <stop
                      offset="0.230625"
                      stopColor="#FF7F3F"
                      stopOpacity="0.8"
                    ></stop>

                    <stop offset="1" stopColor="#FF5A1F" stopOpacity="0"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div class="relative z-20   laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div class="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div class="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="53%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      5
                    </text>
                  </svg>
                </div>
                <span class="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.completeYourPurchase")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.click")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.buySnovaToken")}{" "}
                </span>{" "}
                {t("howToBuy.toProcess")}{" "}
                <span className="font-bold">USDT</span>,{" "}
                <span className="font-bold">USDC</span>, {t("howToBuy.or")}{" "}
                <span className="font-bold">DAI</span>,{" "}
                {t("howToBuy.youNeedToImprove")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.purchaseSummary")}{" "}
                </span>{" "}
                {t("howToBuy.popupWindow")}.
              </span>
            </div>
            <div class="snapshot-container desk-snap">
              <img
                class="snapshot-image"
                src={step6}
                alt="Step 5: Complete Your Purchase"
              />
            </div>
            <div class="snapshot-container mbl-snap">
              <img class="snapshot-image" src={Mblstep6} alt="Step 6" />
            </div>
          </div>
        </div>

        <div class="relative w-full">
          <div class="absolute right-0">
            <div class="laptop:hidden">
              <svg
                fill="none"
                height="714"
                viewBox="0 0 360 714"
                width="360"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_10984)">
                  <path
                    clipRule="evenodd"
                    d="M395.425 514C439.914 514 498.047 503.159 541.859 510.893C550.76 512.464 559.921 513.283 569.272 513.283C655.783 513.283 725.914 443.152 725.914 356.642C725.914 270.131 655.783 200 569.272 200C491.632 200 427.185 256.487 414.785 330.605C413.262 339.708 404.654 346.246 395.425 346.246C374.301 346.246 346.7 331.468 336.034 313.234C319.741 285.381 289.512 266.671 254.914 266.671C203.047 266.671 161.001 308.718 161.001 360.584C161.001 412.451 203.047 454.498 254.914 454.498C280.982 454.498 317.87 471.354 336.387 489.703C351.544 504.723 372.401 514 395.425 514Z"
                    fill="url(#paint0_radial_1109_10984)"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="714"
                    id="filter0_f_1109_10984"
                    width="964.913"
                    x="-38.999"
                    y="0"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feblend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feblend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_10984"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradienttransform="translate(204.014 381.374) rotate(-5.94758) scale(595 110.906)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_10984"
                    r="1"
                  >
                    <stop stopColor="#FFDCFF"></stop>
                    <stop
                      offset="0.230625"
                      stopColor="#914AFF"
                      stopOpacity="0.67"
                    ></stop>
                    <stop offset="1" stopColor="#070012" stopOpacity="0"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div class="hidden laptop:block">
              <svg
                fill="none"
                height="810"
                viewBox="0 0 680 810"
                width="680"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_9929)">
                  <path
                    clipRule="evenodd"
                    d="M512.739 609.802C567.241 606.541 637.347 593.441 690.777 604.681C704.431 607.554 718.586 609.064 733.094 609.064C846.054 609.064 937.626 517.492 937.626 404.532C937.626 291.572 846.054 200 733.094 200C631.716 200 547.565 273.757 531.374 370.536C529.386 382.421 518.146 390.959 506.096 390.959C478.513 390.959 442.474 371.661 428.547 347.853C407.272 311.484 367.802 287.055 322.625 287.055C254.901 287.055 200 341.956 200 409.68C200 477.405 254.901 532.306 322.625 532.306C356.664 532.306 404.831 554.317 429.008 578.275C448.799 597.887 476.033 610 506.096 610C508.327 610 510.541 609.933 512.739 609.802Z"
                    fill="url(#paint0_radial_1109_9929)"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="810"
                    id="filter0_f_1109_9929"
                    width="1137.63"
                    x="0"
                    y="0"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feblend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feblend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_9929"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradienttransform="translate(256.164 436.826) rotate(-5.94757) scale(776.911 144.814)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_9929"
                    r="1"
                  >
                    <stop stopColor="#FFDCFF"></stop>
                    <stop
                      offset="0.230625"
                      stopColor="#914AFF"
                      stopOpacity="0.67"
                    ></stop>
                    <stop offset="1" stopColor="#070012" stopOpacity="0"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div class="relative z-20   laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div class="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div class="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="53%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      6
                    </text>
                  </svg>
                </div>
                <span class="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.viewPurchaseSummary")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.afterCompletion")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {" "}
                  {t("howToBuy.purchaseSummary")}
                </span>{" "}
                {t("howToBuy.popupWindowAppear")}
                <ul className="list-disc ml-[20px]">
                  <li className="font-bold">{t("howToBuy.totalSnova")} </li>
                  <li className="font-bold">{t("howToBuy.novaPointsBonus")}</li>
                  <li className="font-bold">
                    {t("howToBuy.yourPaymentSummary")}
                  </li>
                </ul>
                {t("howToBuy.thisPopup")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.buyMore")}
                </span>{" "}
                {t("howToBuy.tokensOr")}{" "}
                <span className="font-bold text-[#fc6432]">
                  {t("howToBuy.accessDashboard")}
                </span>{" "}
                {t("howToBuy.furtherActions")}.
              </span>
            </div>
            <div class="snapshot-container desk-snap">
              <img
                class="snapshot-image"
                src={step7}
                alt="Step 6: View Your Purchase Summary"
              />
            </div>
            <div class="snapshot-container mbl-snap">
              <img class="snapshot-image" src={Mblstep7} alt="Step 3" />
            </div>
          </div>
        </div>

        {/*  */}

        <div class="relative w-full">
          <div className="absolute left-0">
            <div className="laptop:hidden">
              <svg
                fill="none"
                height="648"
                viewBox="0 0 360 648"
                width="360"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_10972)">
                  <path
                    clipRule="evenodd"
                    d="M3.21424 254.685C-10.7475 249.295 -20.6889 234.347 -19.1942 219.455C-16.5351 192.964 -21.8635 165.42 -36.1921 140.602C-72.5411 77.6434 -153.046 56.0723 -216.004 92.4213C-278.962 128.77 -300.533 209.275 -264.184 272.233C-247.929 300.388 -222.843 320.266 -194.445 330.389C-164.2 341.169 -135.347 369.944 -119.292 397.751C-91.7173 445.513 -30.6449 461.877 17.1166 434.302C35.3378 423.782 60.4863 422.329 78.9898 432.344C115.567 452.142 161.262 453.045 199.922 430.724C259.131 396.54 279.417 320.831 245.233 261.622C211.049 202.414 135.34 182.127 76.1311 216.311C62.8336 223.989 51.4993 233.76 42.3044 244.97C33.2011 256.067 16.6042 259.855 3.21424 254.685Z"
                    fill="url(#paint0_radial_1109_10972)"
                    fillOpacity="0.9"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="772.933"
                    id="filter0_f_1109_10972"
                    width="943.681"
                    x="-481.842"
                    y="-125.236"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feBlend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_10972"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(-212.811 196.273) rotate(14.5891) scale(486.79 78.9258)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_10972"
                    r="1"
                  >
                    <stop stopColor="#FFAA66"></stop>
                    <stop
                      offset="0.17"
                      stopColor="#FF7F3F"
                      stopOpacity="0.85"
                    ></stop>
                    <stop offset="0.428018" stopColor="#FF5A1F"></stop>
                    <stop offset="0.69" stopColor="#CC3700"></stop>
                    <stop offset="1" stopColor="#12111B"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div className="hidden laptop:block">
              <svg
                fill="none"
                height="837"
                viewBox="0 0 596 837"
                width="596"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1109_9925)">
                  <path
                    clipRule="evenodd"
                    d="M56.5287 635.534C2.30069 629.177 -66.731 616.314 -119.789 629.197C-136.313 633.21 -153.574 635.336 -171.332 635.336C-291.547 635.336 -389 537.883 -389 417.668C-389 297.453 -291.547 200 -171.332 200C-63.4429 200 26.1128 278.494 43.3433 381.489C45.4593 394.137 57.421 403.223 70.2452 403.223C99.599 403.223 137.953 382.687 152.774 357.35C175.415 318.644 217.421 292.646 265.499 292.646C337.572 292.646 396 351.073 396 423.147C396 495.221 337.572 553.648 265.499 553.648C229.275 553.648 178.015 577.072 152.284 602.57C131.223 623.441 102.239 636.332 70.2452 636.332C65.6047 636.332 61.0276 636.061 56.5287 635.534Z"
                    fill="url(#paint0_radial_1109_9925)"
                    fillRule="evenodd"
                  ></path>
                </g>
                <defs>
                  <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="836.333"
                    id="filter0_f_1109_9925"
                    width="1185"
                    x="-589"
                    y="0"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      mode="normal"
                      result="shape"
                    ></feBlend>
                    <feGaussianBlur
                      result="effect1_foregroundBlur_1109_9925"
                      stdDeviation="100"
                    ></feGaussianBlur>
                  </filter>
                  <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(336.228 452.037) rotate(-174.052) scale(826.808 154.115)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_1109_9925"
                    r="1"
                  >
                    <stop stopColor="#FFAA66"></stop>
                    <stop
                      offset="0.230625"
                      stopColor="#FF7F3F"
                      stopOpacity="0.85"
                    ></stop>
                    <stop offset="1" stopColor="#12111B" stopOpacity="0"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div class="relative z-20   laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div class="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div class="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                <div>
                  <svg
                    fill="none"
                    height="34"
                    width="34"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 34"
                  >
                    <path
                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <path
                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                      stroke="#fc6432"
                      strokeOpacity="0.5"
                    />
                    <text
                      x="50%"
                      y="53%"
                      fill="#FAFAFA"
                      fontFamily="Matter SQ"
                      fontSize="16"
                      fontWeight="bold"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      7
                    </text>
                  </svg>
                </div>
                <span class="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  {t("howToBuy.viewSnovaTokens")}
                </span>
              </div>
            </div>
            <div>
              <span className="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.checkYourSNovaTokens")}{" "}
                <span className="font-bold"> {t("howToBuy.presaleForm")}</span>.
                {t("howToBuy.forMoreDetails")}{" "}
                <span className="font-bold">
                  {" "}
                  {t("howToBuy.purchaseHistory")}{" "}
                </span>{" "}
                {t("howToBuy.and")}{" "}
                <span className="font-bold"> {t("howToBuy.rankings")}</span>,{" "}
                {t("howToBuy.visitYours")}{" "}
                <Link to="/dashboard" className="text-[#fc6432] underline">
                  {" "}
                  {t("howToBuy.investerDashboard")}
                </Link>
                . {t("howToBuy.maximizeSnovaHolding")}:
                <ul className="list-disc pl-[20px] mt-[8px]">
                  <li>
                    <Link to="/staking" className="text-[#fc6432] underline">
                      {" "}
                      {t("howToBuy.stakingTokens")}
                    </Link>{" "}
                    {t("howToBuy.toUpTo")}
                    <span className="font-bold text-[#fc6432]">
                      {" "}
                      111.5% APY
                    </span>
                    .
                  </li>
                  <li>
                    <Link to="/referral" className="text-[#fc6432] underline">
                      {" "}
                      {t("howToBuy.sharingReferralLinks")}
                    </Link>{" "}
                    {t("howToBuy.toEarnA")}
                    <span className="font-bold text-[#fc6432]">
                      {" "}
                      {t("howToBuy.commissionLinks")}{" "}
                    </span>{" "}
                    {t("howToBuy.onEvery")}
                  </li>
                </ul>
                {t("howToBuy.startWith")}{" "}
                <span className="font-bold">Stacknova</span>!
              </span>
            </div>
            <div class="snapshot-container desk-snap">
              <img
                class="snapshot-image"
                src={step8}
                alt="Step 7: View Your SNOVA Tokens"
              />
            </div>
            <div class="snapshot-container mbl-snap">
              <img class="snapshot-image" src={Mblstep8} alt="Step 8" />
            </div>
          </div>
        </div>

        <div class="relative w-full">
          <div class="relative z-20   laptop:mx-auto gap-[24px] max-w-1000 flex flex-col justify-center">
            <div class="flex gap-[12px] items-center justify-start laptop:gap-[18px]">
              <div class="flex gap-[12px] w-full rounded-[10px] items-center justify-start laptop:gap-[18px] relative p-[16px] bg-cover bg-roadmapBg">
                {/*
                                <div>
                                    <svg fill="none" height="34" viewBox="0 0 34 34" width="34" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1" stroke="#FAFAFA" strokeOpacity="0.16">
                                        </path>
                                        <path d="M1 27V31C1 32.1046 1.89543 33 3 33H7" stroke="#FAFAFA" strokeOpacity="0.16">
                                        </path>
                                        <path d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27" stroke="#FAFAFA" strokeOpacity="0.16">
                                        </path>
                                        <path d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1" stroke="#FAFAFA" strokeOpacity="0.16">
                                        </path>
                                        <path d="M13.6489 23.0005L12.1769 21.5285V13.2725L13.6489 11.8005H20.1929V13.4325H14.0329V15.9285H19.6809L20.8169 17.0645V21.5285L19.3449 23.0005H13.6489ZM14.0329 21.4005H18.9609V17.4005H14.0329V21.4005Z" fill="#FAFAFA">
                                        </path>
                                    </svg>
                                </div>
                                */}
                <span class="mattersq laptop:text-[26px] font-medium leading-[110%] capitalize bg-gradient-title bg-clip-text">
                  <img src={needHelp} className="need-help" />{" "}
                  {t("howToBuy.needHelp")}?
                </span>
              </div>
            </div>
            <div>
              <span class="text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                {t("howToBuy.needAssistance")}:
              </span>
              <ul className="list-disc pl-[20px] mt-[8px] text-[rgba(250,_250,_250,_0.64)] font-ITCAvantGardeGothic text-[16px] laptop:text-[20px] font-medium leading-[150%]">
                <li>
                  <span className="font-bold text-[#fc6432]">
                    {t("howToBuy.presaleFaqs")}
                  </span>{" "}
                  {t("howToBuy.presaleFaqsDescription")}{" "}
                  <a
                    href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale/faqs-for-presale"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#fc6432] underline"
                  >
                    {t("howToBuy.presaleFaqsLink")}
                  </a>
                  .
                </li>
                <li>
                  <span className="font-bold text-[#fc6432]">
                    {t("howToBuy.customerSupportWidget")}
                  </span>{" "}
                  {t("howToBuy.customerSupportWidgetDescription")}
                </li>
                <li>
                  <span className="font-bold text-[#fc6432]">
                    {t("howToBuy.emailSupport")}
                  </span>{" "}
                  {t("howToBuy.emailSupportDescription")}{" "}
                  <a
                    href="mailto:presale@stacknova.ai"
                    className="text-[#fc6432] underline"
                  >
                    presale@stacknova.ai
                  </a>
                  .
                </li>
                <li>
                  <span className="font-bold text-[#fc6432]">
                    {t("howToBuy.telegramCommunity")}
                  </span>{" "}
                  {t("howToBuy.telegramCommunityDescription")}{" "}
                  <a
                    href="https://t.me/StacknovaBTC"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#fc6432] underline"
                  >
                    t.me/StacknovaBTC
                  </a>
                  .
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
