// src/store/cookieSlice.js
import { createSlice } from "@reduxjs/toolkit";

const cookieSlice = createSlice({
  name: "cookie",
  initialState: {
    isBannerVisible: true,
  },
  reducers: {
    hideCookieBanner(state) {
      state.isBannerVisible = false;
    },
    // If you want to show it again for some reason:
    showCookieBanner(state) {
      state.isBannerVisible = true;
    },
  },
});

export const { hideCookieBanner, showCookieBanner } = cookieSlice.actions;

export default cookieSlice.reducer;
