import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // We store only the text (or you can store more data if needed)
  bannerText: "",
};

const bannerTextSlice = createSlice({
  name: "bannerText",
  initialState,
  reducers: {
    // Action to set new text
    setBannerText(state, action) {
      state.bannerText = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBannerText } = bannerTextSlice.actions;

export default bannerTextSlice.reducer;
