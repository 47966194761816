import React from "react";
import MainBody from "../components/HowToBuy/index.js";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import Top from "../components/top";
import usePageTitle from "../hooks/usePageTitle";
import HowToBuyHeader from "../components/HowToBuy/Header.js";

const HowToBuy = () => {
  usePageTitle(
    "How to Buy | Stacknova AI - Grow with SNOVA & Maximize Returns"
  );

  return (
    <div className=" min-ref">
      <Header />
      <Top />
      <div className="min-h-screen mainbuy relative bg-[#000] referral">
        <div className="max-widt">
          <div
            className="absolute"
            style={{ pointerEvents: "none" }}
            bis_skin_checked={1}
          >
            <div className="laptop:hidden" bis_skin_checked={1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={360}
                height={648}
                viewBox="0 0 360 648"
                fill="none"
              >
                <g filter="url(#filter0_f_1109_10972)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.21424 254.685C-10.7475 249.295 -20.6889 234.347 -19.1942 219.455C-16.5351 192.964 -21.8635 165.42 -36.1921 140.602C-72.5411 77.6434 -153.046 56.0723 -216.004 92.4213C-278.962 128.77 -300.533 209.275 -264.184 272.233C-247.929 300.388 -222.843 320.266 -194.445 330.389C-164.2 341.169 -135.347 369.944 -119.292 397.751C-91.7173 445.513 -30.6449 461.877 17.1166 434.302C35.3378 423.782 60.4863 422.329 78.9898 432.344C115.567 452.142 161.262 453.045 199.922 430.724C259.131 396.54 279.417 320.831 245.233 261.622C211.049 202.414 135.34 182.127 76.1311 216.311C62.8336 223.989 51.4993 233.76 42.3044 244.97C33.2011 256.067 16.6042 259.855 3.21424 254.685Z"
                    fill="url(#paint0_radial_1109_10972)"
                    fillOpacity="0.8"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_1109_10972"
                    x="-481.842"
                    y="-125.236"
                    width="943.681"
                    height="772.933"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation={100}
                      result="effect1_foregroundBlur_1109_10972"
                    />
                  </filter>
                  <radialGradient
                    id="paint0_radial_1109_10972"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-212.811 196.273) rotate(14.5891) scale(486.79 78.9258)"
                  >
                    <stop stopColor="#FC6432" />
                    <stop offset="0.0001" stopColor="#FFFFFF" />
                    <stop offset="0.17" stopColor="#FC6432" />
                    <stop offset="0.428018" stopColor="#FC6432" />
                    <stop offset="0.69" stopColor="#FF7A32" />
                    <stop offset={1} stopColor="#12111B" />
                  </radialGradient>
                </defs>
              </svg>
            </div>

            <div className="hidden laptop:block" bis_skin_checked={1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={635}
                height={730}
                viewBox="0 0 635 730"
                fill="none"
              >
                <g filter="url(#filter0_f_1109_9885)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96.7381 277.047C78.4807 269.997 65.4803 250.45 67.435 230.977C70.9122 196.335 63.9444 160.315 45.2071 127.862C-2.32578 45.5321 -107.6 17.324 -189.929 64.8569C-272.259 112.39 -300.467 217.664 -252.934 299.993C-231.677 336.811 -198.873 362.806 -161.737 376.043C-122.186 390.14 -84.4556 427.768 -63.4615 464.131C-27.4021 526.588 52.4612 547.987 114.918 511.928C138.746 498.171 171.632 496.271 195.828 509.368C243.659 535.257 303.414 536.437 353.97 507.249C431.396 462.547 457.924 363.543 413.222 286.118C368.52 208.692 269.516 182.164 192.09 226.866C174.701 236.905 159.88 249.684 147.856 264.341C135.951 278.853 114.248 283.807 96.7381 277.047Z"
                    fill="url(#paint0_radial_1109_9885)"
                    fillOpacity="0.8"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_1109_9885"
                    x="-476.024"
                    y="-158.233"
                    width="1110.96"
                    height="887.678"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation={100}
                      result="effect1_foregroundBlur_1109_9885"
                    />
                  </filter>
                  <radialGradient
                    id="paint0_radial_1109_9885"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-185.754 200.662) rotate(14.5891) scale(636.566 103.21)"
                  >
                    <stop stopColor="#FC6432" />
                    <stop offset="0.0001" stopColor="#FFFFFF" />
                    <stop offset="0.17" stopColor="#FC6432" />
                    <stop offset="0.428018" stopColor="#FF7A32" />
                    <stop offset="0.69" stopColor="#FF7A32" />
                    <stop offset={1} stopColor="#12111B" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>

          <div className="pt-[96px] mbl-smalle laptop:pt-[132px]">
            <div className="max-width">
              <HowToBuyHeader />
            </div>
            <MainBody />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HowToBuy;
