// reducers/mainBalancesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const mainBalancesSlice = createSlice({
  name: "mainBalances",
  initialState: {
    data: {
      totalSnovaTokens: null,
      totalSnovaValue: null,
      totalReferralEarnings: null,
    },
  },
  reducers: {
    setMainBalancesData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setMainBalancesData } = mainBalancesSlice.actions;

export default mainBalancesSlice.reducer;
