// src/components/PresaleCountdown.jsx

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useDeviceType from "../hooks/useDeviceType";
import rocketIcon from "../assets/img/rocket-icon.svg";

import { LAUNCH_DATE } from "../config/launchConfig";

// Import your custom countdown hook
import useCountdown from "../hooks/useCountdown";

const PresaleCountdown = () => {
  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    if (typeof navigator !== "undefined") {
      const userAgent = navigator.userAgent;
      const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
      setIsSafari(isSafariBrowser);
    }
  }, []);
  // 2. Use the countdown hook
  const [days, hours, minutes, seconds] = useCountdown(LAUNCH_DATE);

  // 3. If the difference is <= 0, it means date/time is past
  const isPresaleLive = days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0;

  // Redux state for cookie banner
  const cookieBannerVisible = useSelector(
    (state) => state.cookie.isBannerVisible
  );

  // Determine device type
  const deviceType = useDeviceType();

  // Decide bottom offset
  let bottomPosition;
  if (cookieBannerVisible) {
    if (deviceType === "mobile") {
      bottomPosition = "360px";
    } else if (deviceType === "air") {
      bottomPosition = "320px";
    } else if (deviceType === "pro") {
      bottomPosition = "200px";
    } else {
      // desktop
      bottomPosition = "115px";
    }
  } else {
    bottomPosition = "10px";
  }

  // Helper function to format numbers: e.g., 0 -> "00", 9 -> "09"
  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  // 4. Decide which block to render
  if (isPresaleLive) {
    // 🔥 Presale is live
    return (
      <div>
        <Link
          to="/"
          style={{
            position: "fixed",
            bottom: bottomPosition,
            left: "0%",
            right: "0",
            zIndex: 99,
            background: "rgba(0, 0, 0, 0.8)",
            padding: "10px 20px",
            borderRadius: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
          }}
          className="presale-countdown"
        >
          <div>
            <span
              className="presale-c-head"
              style={{
                color: "#FAFAFA",
                fontSize: "16px",
                marginRight: "10px",
              }}
            >
              🔥 Presale Live:
            </span>
          </div>
          <div>
            <span
              style={{
                color: "#fc6432",
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "5px",
                animation: `${
                  isSafari ? "glow-safari" : "glow-safari"
                } 1.5s infinite`,
              }}
            >
              Join Now!
            </span>
          </div>
        </Link>
      </div>
    );
  }

  // Otherwise, render the normal numeric countdown
  return (
    <div>
      <Link
        to="/"
        style={{
          position: "fixed",
          bottom: bottomPosition,
          left: "0%",
          right: "0",
          zIndex: 98,
          background: "rgba(0, 0, 0, 0.8)",
          padding: "10px 20px",
          borderRadius: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
        }}
        className="presale-countdown"
      >
        {/* Heading */}
        <div>
          <span
            className="presale-c-head"
            style={{ color: "#FAFAFA", fontSize: "16px", marginRight: "10px" }}
          >
            <img
              src={rocketIcon}
              alt="Rocket Icon"
              style={{
                width: "20px",
                height: "20px",
                display: "inline",
                marginRight: "5px",
                flexShrink: 0,
                transform: "translateY(-2px)",
              }}
            />
            Stage 2 Countdown:
          </span>
        </div>

        {/* Countdown */}
        <div>
          {/* Days */}
          <span
            style={{
              color: "#fc6432",
              fontSize: "20px",
              fontWeight: "bold",
              marginRight: "5px",
              animation: `${isSafari ? "glow-safari" : "glow"} 1.5s infinite`,
            }}
          >
            {formatTime(days)}d
          </span>
          <span
            style={{
              color: "rgb(250,250,250)",
              fontSize: "20px",
              marginRight: "5px",
              opacity: "50%",
            }}
          >
            :
          </span>

          {/* Hours */}
          <span
            style={{
              color: "#fc6432",
              fontSize: "20px",
              fontWeight: "bold",
              marginRight: "5px",
              animation: `${isSafari ? "glow-safari" : "glow"} 1.5s infinite`,
            }}
          >
            {formatTime(hours)}h
          </span>
          <span
            style={{
              color: "rgb(250,250,250)",
              fontSize: "20px",
              marginRight: "5px",
              opacity: "50%",
            }}
          >
            :
          </span>

          {/* Minutes */}
          <span
            style={{
              color: "#fc6432",
              fontSize: "20px",
              fontWeight: "bold",
              marginRight: "5px",
              animation: `${isSafari ? "glow-safari" : "glow"} 1.5s infinite`,
            }}
          >
            {formatTime(minutes)}m
          </span>
          <span
            style={{
              color: "rgb(250,250,250)",
              fontSize: "20px",
              marginRight: "5px",
              opacity: "50%",
            }}
          >
            :
          </span>

          {/* Seconds */}
          <span
            style={{
              color: "#fc6432",
              fontSize: "20px",
              fontWeight: "bold",
              animation: `${isSafari ? "glow-safari" : "glow"} 1.5s infinite`,
            }}
          >
            {formatTime(seconds)}s
          </span>
        </div>
      </Link>
    </div>
  );
};

export default PresaleCountdown;
