import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { metadata, networks } from "./networkConfig";

const projectId = process.env.REACT_APP_PROJECT_ID;

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "...", // Specific to your use case
  defaultChainId: 1,
});

export const web3Modal = createWeb3Modal({
  ethersConfig,
  chains: [
    networks.eth,
    networks.bsc,
    networks.plg,
    networks.arb,
    networks.avax,
    networks.op,
    networks.base,
    networks.zkSync,
    // networks.blast,
  ],
  projectId,
  enableAnalytics: true,
});
