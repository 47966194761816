import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setTopUsersByReward,
  setTopUsersByNovaPoints,
} from "../reducers/userRankingsSlice";

const useFetchTopUsersByRewards = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/getTopUsers`
        );
        if (response.ok) {
          const data = await response.json();
          dispatch(setTopUsersByReward(data.topByRewards));
          dispatch(setTopUsersByNovaPoints(data.topByNovaPoints));
        } else {
          const errorData = await response.json();
          setError(errorData.error);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTopUsers();
  }, [dispatch]);

  return { loading, error };
};

export default useFetchTopUsersByRewards;
