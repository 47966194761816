import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import tokensByChainId from "../utils/tokensByChainId";
import { ethers } from "ethers";
import { getFallbackProvider } from "../utils/getFallbackProvider";

function truncateNumber(number, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(number * factor) / factor;
}

const useBalanceData = ({
  isConnected,
  walletProvider, // e.g. from Web3Modal or WalletConnect
  chainId,
  selectedCurrency,
  address, // optional: in case you want to pass an address other than the signer's
  fetchPriceForCurrency,
}) => {
  const [balanceData, setBalanceData] = useState({
    balance: null,
    balanceInDollar: null,
  });

  const balanceRefreshTrigger = useSelector(
    (state) => state.balance.balanceRefreshTrigger
  );

  // Core logic to fetch balance (with fallback)
  const fetchBalanceAndPrice = useCallback(async () => {
    // If not connected or no provider, clear out balance
    if (!isConnected || !walletProvider) {
      setBalanceData({ balance: null, balanceInDollar: null });
      return;
    }

    try {
      // -------------------------
      // STEP 1: CREATE PROVIDERS
      // -------------------------
      // "readProvider" = fallback read-only provider (e.g., Infura, Alchemy, etc.)
      const readProvider = getFallbackProvider(chainId);

      // "ethersProvider" = provider from the user’s wallet connection
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();

      // If user didn’t pass an address, fetch from signer
      const userAddress = address || (await signer.getAddress());

      // -------------------------
      // STEP 2: FETCH BALANCE
      // -------------------------
      let newBalance;
      let formattedBalance;

      if (["ETH", "BNB", "POL", "AVAX"].includes(selectedCurrency)) {
        // If it's a native currency, try ethersProvider first, fallback to readProvider:
        try {
          newBalance = await ethersProvider.getBalance(userAddress);
        } catch (err) {
          console.warn(
            "Could not fetch native balance from wallet provider, falling back to read provider:",
            err
          );
          newBalance = await readProvider.getBalance(userAddress);
        }

        const rawFormatted = ethers.utils.formatEther(newBalance);
        formattedBalance = truncateNumber(rawFormatted, 4);
      } else {
        // If it's an ERC-20 token, we need the token contract info
        const tokenInfo = tokensByChainId[chainId]?.[selectedCurrency];

        if (tokenInfo?.address) {
          const erc20Abi = [
            "function balanceOf(address) view returns (uint256)",
          ];
          const contractFromWalletProvider = new ethers.Contract(
            tokenInfo.address,
            erc20Abi,
            ethersProvider
          );
          const contractFromReadProvider = new ethers.Contract(
            tokenInfo.address,
            erc20Abi,
            readProvider
          );

          try {
            // Try fetching with wallet provider first:
            newBalance = await contractFromWalletProvider.balanceOf(
              userAddress
            );
          } catch (err) {
            console.warn(
              "Could not fetch token balance from wallet provider, falling back to read provider:",
              err
            );
            newBalance = await contractFromReadProvider.balanceOf(userAddress);
          }

          formattedBalance = truncateNumber(
            Number(newBalance) / 10 ** tokenInfo.decimals,
            4
          );
        } else {
          // If we don't know this token, bail
          setBalanceData({ balance: null, balanceInDollar: null });
          return;
        }
      }

      // -------------------------
      // STEP 3: FETCH PRICE & SET STATE
      // -------------------------
      const currencyPrice = await fetchPriceForCurrency(
        selectedCurrency,
        chainId
      );
      const newBalanceInDollar =
        formattedBalance !== null && currencyPrice !== null
          ? currencyPrice * formattedBalance
          : 0;

      const updatedState = {
        balance: formattedBalance,
        balanceInDollar: newBalanceInDollar,
      };

      // Only update if values actually changed (prevents extra re-renders)
      setBalanceData((prev) => {
        if (
          prev.balance !== updatedState.balance ||
          prev.balanceInDollar !== updatedState.balanceInDollar
        ) {
          return updatedState;
        }
        return prev;
      });
    } catch (error) {
      console.error("Error fetching balance and price:", error);
      setBalanceData({ balance: null, balanceInDollar: null });
    }
  }, [
    isConnected,
    walletProvider,
    chainId,
    selectedCurrency,
    address,
    fetchPriceForCurrency,
  ]);

  // Debounce to avoid spamming calls
  const debouncedFetchBalanceAndPrice = useCallback(
    debounce(fetchBalanceAndPrice, 200, { leading: true, trailing: false }),
    [fetchBalanceAndPrice]
  );

  // Fetch whenever this hook is used (mount) or when balanceRefreshTrigger changes
  useEffect(() => {
    debouncedFetchBalanceAndPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFetchBalanceAndPrice, balanceRefreshTrigger]);

  // Optionally: Re-fetch on every new block from the fallback provider
  // (If you don't want that many updates, consider removing or throttling it)
  useEffect(() => {
    if (isConnected && walletProvider) {
      const readProvider = getFallbackProvider(chainId);

      // For fewer updates, you might implement a block counter or remove this entirely
      const handleNewBlock = () => {
        debouncedFetchBalanceAndPrice();
      };

      // Some fallback providers won't emit "block" unless WS-based
      readProvider.on("block", handleNewBlock);

      return () => {
        readProvider.off("block", handleNewBlock);
      };
    }
  }, [isConnected, chainId, walletProvider, debouncedFetchBalanceAndPrice]);

  return {
    balance: balanceData.balance,
    balanceInDollar: balanceData.balanceInDollar,
    fetchBalanceAndPrice: debouncedFetchBalanceAndPrice,
  };
};

export default useBalanceData;
