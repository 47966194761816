import React from "react";
import "../../../Referral/Rank/shimmers/shimmer.css";

const UserListShimmerPlaceholderMobile = () => {
  return (
    <div className="user-list rank-updated" style={{ width: "100%" }}>
      {" "}
      <div className="_line_18dqk_1 mb-[8px]" style={{ width: "100%" }} />
      <div className="flex flex-col gap-[6px] laptop:gap-[4px]">
        <>
          {Array.from({ length: 10 }).map((_, index) => (
            <li
              key={index}
              className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
              style={{
                background: "rgba(250, 250, 250, 0.04)",
                color: "rgba(255, 255, 255, 0.32)",
              }}
            >
              <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
                <div
                  className="flex items-center"
                  style={{
                    position: "relative",
                    zIndex: 2,
                  }}
                >
                  <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px] text-[#FAFAFA]">
                    <span
                      className="shimmer shimmer-text"
                      style={{ width: "25px" }}
                    />
                  </span>
                  <span className="flex gap-[8px] items-center">
                    <span
                      className="shimmer shimmer-text"
                      style={{ width: "40px" }}
                    />
                  </span>
                </div>
                <span
                  className="text-[#FAFAFA]"
                  style={{
                    position: "relative",
                    zIndex: 2,
                  }}
                >
                  <span
                    className="shimmer shimmer-text"
                    style={{ width: "155px" }}
                  />
                </span>
              </div>
              <div className="flex flex-col gap-[12px] text-white">
                <div className="flex justify-between items-center">
                  <span
                    className="shimmer shimmer-text"
                    style={{ width: "120px" }}
                  />
                  <span className="text-[#FAFAFA]">
                    <span
                      className="shimmer shimmer-text"
                      style={{ width: "55px" }}
                    />
                  </span>
                </div>
              </div>
            </li>

            // <li
            //   key={index}
            //   className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
            //   style={{
            //     background: "rgba(250, 250, 250, 0.04)",
            //     color: "rgba(255, 255, 255, 0.32)",
            //   }}
            // >
            //   {/* Top section (ranking, EVM, wallet address) */}
            //   <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
            //     {/* If you have a background image, you can put a shimmer block or leave it commented out */}
            //     {/*
            //     <span className="shimmer shimmer-bg absolute w-full h-full top-0 left-0 z-[-1]" />
            //     */}
            //     <div className="flex items-center">
            //       {/* Ranking shimmer placeholder */}
            //       <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px]">
            //         <span
            //           className="shimmer shimmer-text"
            //           style={{ width: "25px" }}
            //         />
            //       </span>
            //       {/* EVM shimmer placeholder */}
            //       <span
            //         className="shimmer shimmer-text"
            //         style={{ width: "40px" }}
            //       />
            //     </div>

            //     {/* Wallet address shimmer placeholder */}
            //     <span className="shimmer shimmer-text" style={{ width: "155px" }} />
            //   </div>

            //   {/* Bottom section (Referrals and Earnings) */}
            //   <div className="flex flex-col gap-[12px] text-white">
            //     <div className="flex justify-between items-center">
            //       <span
            //         className="shimmer shimmer-text"
            //         style={{ width: "100px" }}
            //       />
            //       {/* Referrals shimmer placeholder */}
            //       <span
            //         className="shimmer shimmer-text"
            //         style={{ width: "40px" }}
            //       />
            //     </div>
            //     <div className="flex justify-between items-center">
            //       <span
            //         className="shimmer shimmer-text"
            //         style={{ width: "180px" }}
            //       />
            //       {/* Earnings shimmer placeholder */}
            //       <span
            //         className="shimmer shimmer-text"
            //         style={{ width: "90px" }}
            //       />
            //     </div>
            //   </div>
            // </li>
          ))}
        </>{" "}
      </div>
    </div>
  );
};

export default UserListShimmerPlaceholderMobile;
