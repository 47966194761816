import React from "react";
import "./shimmer.css";

const RewardsShimmer = () => {
  return (
    <div className="shimmer-wrapper">


      <div className="flex gap-[8px] flex-col mobile:flex-row mb-[42px]">
        <div
          className="flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px] shimmer"
          style={{ height: "80px", borderRadius: "10px" }}
        />
      </div>
      <div
        className="relative shimmer"
        style={{ height: "40px", borderRadius: "10px" }}
      />
      <div className="shimmer shimmer-button" />
    </div>
  );
};

export default RewardsShimmer;
