import analytics_full_white from "../../../assets/img/analytics-full-white.png";
import marketing_funnel_2 from "../../../assets/img/marketing-funnel-2.png";
import kyberswap from "../../../assets/img/kyberswap.png";
import mantle from "../../../assets/img/mantle.png";
import woo from "../../../assets/img/woo.png";
import linea from "../../../assets/img/linea.png";
import gameswift from "../../../assets/img/gameswift.png";
import dodo from "../../../assets/img/dodo.png";
import eesee from "../../../assets/img/eesee.png";
import insomnia from "../../../assets/img/insomnia.png";
import score_full_color_white from "../../../assets/img/score-full-color-white.png";
import React from "react";
import "./Platforms.css";
import { useTranslation } from "react-i18next";
const Platforms = () => {
  const { t } = useTranslation();
  return (
    <section id="platforms" className="mt-16 scroll-my-32 lg:mt-32 relative">
      
      <div className="relative mx-auto max-w-content px-0 lg:px-4 xl:px-0">
        <div>
          <div className="section mx-auto my-32 asase max-w-content lg:mt-64 lg:hidden"></div>
          <div className="max-width">
            <div className="technology max-widh">
              <div className="innerTechnolodyDiv">
                <p className="technologyTag">
                  {t("homePage.platforms.tokenUtility")}
                </p>
                <p className="technologyMainHeading">
                  {t("homePage.platforms.tokenUtilityDesc")}
                </p>
                <p className="technologySubHeading">
                  {t("homePage.platforms.tokenUtilityLongDesc")}
                </p>
                <div className="mt-4">
                  <a
                    href="https://stacknova-ai.gitbook.io/stacknova-ai"
                    className="flat-toggle box-text box-btn corner-box"
                    aria-label="Learn more about stacknova AI"
                  >
                    <span className="toggle-title">
                      {t("homePage.platforms.learnMore")}
                      <span className="sr-only"> about the Stacknova AI</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Platforms;
