// useFetchAllReferralTransactions.js
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReferralTransactionsData } from "../reducers/referralRewardsSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { debounce } from "../utils/debounce";

const useFetchAllReferralTransactions = () => {
  const dispatch = useDispatch();
  const { isConnected, address } = useWeb3ModalAccount();
  // If you store transaction data in Redux, let's assume it's in `referralRewards.referralTransactions`
  const referralTransactions = useSelector(
    (state) => state.referralRewards.referralTransactions
  );
  const user = useSelector((state) => state.user);
  const authReady = useSelector((state) => state.auth.authReady);
  const referralLink = useSelector((state) => state.referral.referral);

  // 1) The plain function to fetch and dispatch
  const fetchReferralTransactions = async () => {
    if (
      !isConnected ||
      !address ||
      !user?.walletAddress ||
      user?.walletAddress !== address ||
      !authReady ||
      !referralLink
    )
      return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferralTransactions?walletAddress=${address}`
      );
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error fetching referral transactions:", errorData.error);
        return;
      }

      const data = await response.json();
      // data shape: { address, referralsCount, referralTransactions: [...] }

      // 2) Dispatch results to Redux
      dispatch(setReferralTransactionsData(data));
    } catch (error) {
      console.error("Error fetching referral transactions:", error.message);
    }
  };

  // 3) Debounce if you want to avoid spamming the server
  const debouncedFetchReferralTransactions = useCallback(
    debounce(fetchReferralTransactions, 300),
    [address, dispatch]
  );

  // 4) Auto-call it whenever `address` changes (optional)
  useEffect(() => {
    if (!address) return;
    debouncedFetchReferralTransactions();
  }, [address, debouncedFetchReferralTransactions]);

  // 5) Return anything you want—like the data, the fetch function, etc.
  return {
    referralTransactions,
    fetchReferralTransactions,
  };
};

export default useFetchAllReferralTransactions;
