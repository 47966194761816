import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "./Footer.css";
import stacknovaLogo from "../../assets/img/stacknova-full.png";
import mediumIcon from "../../assets/img/medium-icon.webp";
import mirrorIcon from "../../assets/img/Mirror.svg";
import githubIcon from "../../assets/img/github-icon.png";
import gitbookIcon from "../../assets/img/gitbook-icon.svg";
import telegramIcon from "../../assets/img/tg-footer-icon.svg";
import twitterIcon from "../../assets/img/twitter-icon.png";
import cookieIcon from "../../assets/img/cookies.webp";
import { hideCookieBanner } from "../../reducers/cookieSlice";
import { Link } from "react-router-dom";

const Footer = () => {
  const dispatch = useDispatch();
  const isCookieBannerVisible = useSelector(
    (state) => state.cookie.isBannerVisible
  );

  const loadGoogleTagManager = () => {
    if (!window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WMQJ26N3";

      script.onload = () => console.log();
      script.onerror = () => console.error();

      document.head.appendChild(script);

      const noscript = document.createElement("noscript");
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WMQJ26N3"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.appendChild(noscript);
    }
  };

  const acceptCookies = () => {
    Cookies.set("cookiePreference", "accepted", { expires: 365 });
    localStorage.setItem("cookiePreference", "accepted");
    loadGoogleTagManager();
    dispatch(hideCookieBanner()); // Hide the banner in Redux
  };

  const declineCookies = () => {
    Cookies.set("cookiePreference", "declined", { expires: 365 });
    localStorage.setItem("cookiePreference", "declined");
    dispatch(hideCookieBanner()); // Hide the banner in Redux
  };

  useEffect(() => {
    const cookiePreference = Cookies.get("cookiePreference");
    if (cookiePreference === "accepted") {
      loadGoogleTagManager();
      dispatch(hideCookieBanner());
    }
  }, [dispatch]);

  return (
    <>
      <div className="footer_footer__Hhmm8 swx">
        <div className="max-width">
          <div className="footer_wrapper__qdczL">
            <div className="footer_box__WbJW_">
              <p className="footer_title__QdQ4j">
                Join the Stacknova <b>community</b>
              </p>
              <div className="footer-socials_socials__85gHT">
                <a
                  className="footer-socials_socials_link__QdqJI"
                  href="https://t.me/StacknovaBTC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  telegram
                  <div className="footer-socials_socials_wrapper__k9UV2">
                    <img
                      src={telegramIcon}
                      alt=""
                      aria-hidden="true"
                      loading="lazy"
                    />
                    <span>telegram</span>
                  </div>
                </a>
                <a
                  className="footer-socials_socials_link__QdqJI"
                  href="https://twitter.com/StacknovaBTC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  twitter
                  <div className="footer-socials_socials_wrapper__k9UV2">
                    <img
                      src={twitterIcon}
                      alt=""
                      aria-hidden="true"
                      loading="lazy"
                    />
                    <span>twitter</span>
                  </div>
                </a>
                <a
                  className="footer-socials_socials_link__QdqJI"
                  href="https://mirror.xyz/stacknova.eth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  mirror
                  <div className="footer-socials_socials_wrapper__k9UV2">
                    <img
                      src={mirrorIcon}
                      alt=""
                      aria-hidden="true"
                      loading="lazy"
                    />
                    <span>mirror</span>
                  </div>
                </a>
                <a
                  className="footer-socials_socials_link__QdqJI"
                  href="https://github.com/Stacknova-AI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  github
                  <div className="footer-socials_socials_wrapper__k9UV2">
                    <img
                      src={githubIcon}
                      alt=""
                      aria-hidden="true"
                      loading="lazy"
                    />
                    <span>github</span>
                  </div>
                </a>
                <a
                  className="footer-socials_socials_link__QdqJI"
                  href="https://docs.stacknova.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  whitepaper
                  <div className="footer-socials_socials_wrapper__k9UV2">
                    <img
                      src={gitbookIcon}
                      alt=""
                      aria-hidden="true"
                      loading="lazy"
                    />
                    <span>whitepaper</span>
                  </div>
                </a>
              </div>
              <div className="footer-nav_root__tqeWW">
                <div className="footer-nav_content__wBpDg">
                  <Link to="/" className="footer-nav_title__Jf6Ag">
                    <img
                      src={stacknovaLogo}
                      alt="logo"
                      className="footer-logo"
                      loading="lazy"
                    />
                  </Link>
                  <p className="footer-nav_text__XNB93">
                    The World's First AI-Powered Bitcoin Launchpad.
                  </p>
                  <p className="footer-nav_copyright__vzn9_ mbl-only">
                    © 2024 Stacknova AI
                  </p>
                </div>
                <nav className="footer-nav_nav__92SOe">
                  <ul className="footer-nav_nav_list__htZdT">
                    <li className="footer-nav_nav_item__XJ8A_">
                      <p className="footer-nav_nav_title__tBH4B">Company</p>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://stacknova.ai/stacknova_one-pager.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>One-Pager</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://docs.stacknova.ai/stacknova-ai/resources/brand-kit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Brand Kit</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://docs.stacknova.ai/stacknova-ai/getting-started/general-faqs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>FAQs</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://link3.to/stacknova"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Links</span>
                      </a>
                    </li>
                    <li className="footer-nav_nav_item__XJ8A_">
                      <p className="footer-nav_nav_title__tBH4B">Resources</p>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://stacknova-ai.gitbook.io/stacknova-ai/legal/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Terms</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://stacknova-ai.gitbook.io/stacknova-ai/legal/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Privacy</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://x8mxdnqdnu4.typeform.com/to/GFvsq3kr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Apply</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://x8mxdnqdnu4.typeform.com/to/RYjzknp7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Partner</span>
                      </a>
                    </li>
                    <li className="footer-nav_nav_item__XJ8A_">
                      <p className="footer-nav_nav_title__tBH4B">
                        Get Involved
                      </p>
                      <Link
                        to="/how-to-buy"
                        className="footer-nav_nav_link__NagoM"
                      >
                        <span>How to Buy</span>
                      </Link>
                      <Link
                        to="/referral"
                        className="footer-nav_nav_link__NagoM"
                      >
                        <span>Referral</span>
                      </Link>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="https://t.me/StacknovaBTC_News"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>News</span>
                      </a>
                      <a
                        className="footer-nav_nav_link__NagoM"
                        href="mailto:info@stacknova.ai?subject=Stacknova%20-%20Question%20from%20the%20website"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Contact Us</span>
                      </a>
                    </li>
                  </ul>

                  <p className="footer-nav_copyright__vzn9_ desk-only">
                    © 2025 Stacknova AI
                  </p>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCookieBannerVisible && (
        <div className="cookie-banner">
          <div className="fixed bottom-[20px] w-full laptop:max-w-[1148px] left-[50%] translate-x-[-50%] z-[100]">
            <div className="container">
              <div className="_wrapper_1r8yr_1 px-[16px] py-[16px] laptop:px-[24px] laptop:py-[12px] flex flex-col laptop:flex-row laptop:items-center relative">
                <div className="mb-[16px] laptop:mb-0 max-w-[68px]">
                  <img src={cookieIcon} alt="cookies" loading="lazy" />
                </div>
                <p className="text-[#D6D6D6] text-midc text-[14px] laptop:pl-[16px] font-normal grow mb-[32px] laptop:mb-0">
                  Accept cookies to ensure the best browsing experience on our
                  platform. <br /> By continuing on our site, you agree to our{" "}
                  <a
                    href="https://stacknova-ai.gitbook.io/stacknova-ai/legal/cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="orange"
                  >
                    Cookie Policy
                  </a>
                  .
                </p>
                <button
                  className="bl-inline-flex bl-items-center ex-wid bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                  target="_blank"
                  rel="noopener noreferrer"
                  bis_skin_checked={1}
                  onClick={declineCookies}
                  style={{
                    background: "transparent",
                    height: "43px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                    bis_skin_checked={1}
                  />
                  Decline
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                    bis_skin_checked={1}
                  />
                  <div
                    className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                    bis_skin_checked={1}
                  />
                </button>
                <button
                  onClick={acceptCookies}
                  className="ant-btn ms-3a button-bna ex-wid  flex items-center content jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                  type="button"
                  style={{
                    backgroundColor: "rgb(252, 100, 50)",
                    color: "rgb(255, 255, 255)",
                    boxShadow: "rgb(192, 64, 0) 6px 6px",
                  }}
                >
                  Accept
                </button>

                <div className="absolute laptop:static top-[20px] right-[16px] laptop:ml-[32px]">
                  <div className="cross-ic2">
                    <svg
                      width={12}
                      height={12}
                      onClick={declineCookies}
                      viewBox="0 0 12 12"
                      fill="none"
                      className="styles_close__hZeAJ"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
