import React from "react";
import "./shimmer.css";

const KeyPointsShimmer = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer-grid">
        <div className="shimmer-item">
          <span className="shimmer shimmer-title" />
          <span className="shimmer shimmer-text" />
        </div>
        <div className="shimmer-item">
          <span className="shimmer shimmer-title" />
          <span className="shimmer shimmer-text" />
        </div>
      </div>
      <div className="shimmer-grid " style={{ marginBottom: '0' }}>
        <div className="shimmer-item">
          <span className="shimmer shimmer-title" />
          <div className="flex items-center gap-[8px] mt-[16px]">
            <span className="shimmer shimmer-icon" />
            <span className="shimmer shimmer-text" />
          </div>
        </div>
        <div className="shimmer-item">
          <span className="shimmer shimmer-title" />
          <div className="flex items-center gap-[8px] mt-[16px]">
            <span className="shimmer shimmer-icon" />
            <span className="shimmer shimmer-text" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default KeyPointsShimmer;
