import { useState, useEffect, useRef } from "react";

export function useDebouncedValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const handler = useRef(null);

  useEffect(() => {
    if (handler.current) {
      clearTimeout(handler.current);
    }

    handler.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (handler.current) {
        clearTimeout(handler.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
}

export function truncateToDecimalPlace(num, decimalPlaces) {
  const regex = new RegExp(`^(.*\\..{${decimalPlaces}})|.*$`);
  return num.toString().match(regex)[1] || num.toString();
}

export const formatValue = (value, decimals) => {
  if (isNaN(value) || value === undefined) return "...";
  return Number(truncateToDecimalPlace(value, decimals)).toFixed(decimals);
};

export function validateAndFormatInput(
  inputValue,
  previousInput,
  decimalPlaces
) {
  const decimalOnlyInputs = ["0.", "0,", ".", ","];
  let validInput = inputValue.replace(/[^0-9.,]+/g, "").replace(/,/g, ".");

  let parts = validInput.split(".");

  if (parts.length > 2) {
    validInput = `${parts[0]}.${parts.slice(1).join("")}`;
  }

  if (validInput.startsWith(".")) {
    validInput = "0" + validInput;
  }

  parts = validInput.split(".");

  if (parts.length > 1 && parts[1].length > decimalPlaces) {
    parts[1] = parts[1].substring(0, decimalPlaces);
    validInput = parts.join(".");
  }

  if (decimalOnlyInputs.includes(previousInput) && validInput === "0") {
    validInput = "";
  }

  return validInput;
}

export const getChainIdFromSelectedCoin = (coin) => {
  const coinToChainIdMap = {
    eth: 1,
    bnb: 56,
    plg: 137,
    arbi: 42161,
    avax: 43114,
    base: 8453,
    opt: 10,
    zksync: 324,
  };
  return coinToChainIdMap[coin];
};

export const formatRanking = (ranking) => {
  return ranking > 0 ? `#${ranking}` : "#";
};

export const formatDateOnly = (isoString) => {
  // Construct the Date object
  const dateObj = new Date(isoString);

  // Convert to "16 Dec 2024" in UTC
  return dateObj.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  });
};

export const formatDateTimeUtc = (isoString) => {
  const dateObj = new Date(isoString);

  // e.g. "16 Dec 2024"
  const datePart = dateObj.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  });

  // e.g. "14:35"
  const timePart = dateObj.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
    hour12: false, // if you prefer 24h format
  });

  // Combine: "16 Dec 2024, 14:35 UTC"
  return `${datePart}, ${timePart} UTC`;
};

export const fetchPrices = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getPricesHandler`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error fetching prices:", errorData.error);
      throw new Error("Failed to fetch prices");
    }

    const pricesData = await response.json();
    const tokenMap = {
      ETH: "ethereum",
      BNB: "binancecoin",
      POL: "polygon",
      AVAX: "avalanche-2",
      ARB: "arbitrum",
      OP: "optimism",
      ZK: "zk-rollups",
    };

    const prices = {};

    Object.keys(tokenMap).forEach((key) => {
      const tokenName = tokenMap[key];
      if (pricesData[key]) {
        prices[tokenName] = { usd: pricesData[key] };
      } else {
        prices[tokenName] = { usd: 0 };
      }
    });

    // Stablecoins default to 1 or the provided rate
    prices["USDT"] = { usd: pricesData.USDT || 1 };
    prices["USDC"] = { usd: pricesData.USDC || 1 };
    prices["DAI"] = { usd: pricesData.DAI || 1 };

    return prices;
  } catch (error) {
    console.error("Error fetching prices:", error.message);
    throw error;
  }
};

// Helper to parse a specific cookie value from document.cookie
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
