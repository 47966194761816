import launchpad from "../../../assets/img/stacknova-launchpad.webp";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setModal1,
  setModal2,
  setModal3,
  setModal5,
  setModal6,
} from "../../../reducers/modalSlice"; // Import modal actions
import { currencyMap, networkMap } from "../../../utils/modalsHelpers";
import "./LaunchApp.css";

import novaPoints from "../../../assets/img/nova-points.svg";
import UsdC from "../../../assets/img/usdc-symbol.webp";
import done from "../../../assets/img/done.svg";
import referralCoins from "../../../assets/img/referral-coins.svg";
import CopyIco from "../../../assets/img/copy.svg";
import purchaseSuccess from "../../../assets/img/purchase-successful.webp";
import claimSuccess from "../../../assets/img/claim-successful.png";
import purchaseError from "../../../assets/img/caution.webp";
import purchaseSummary from "../../../assets/img/purchase-summary-icon.svg";
import transactionError from "../../../assets/img/crossed-circle.svg";
import snovaCoin from "../../../assets/img/snova-coin-white.svg";

import Tether from "../../../assets/img/tether-icon.webp";
import Usd from "../../../assets/img/usd-icon-flag.svg";
import transactionLink from "../../../assets/img/transaction-link.svg";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import formatReferralLink from "../../../utils/formatReferralLink";
import { truncateToDecimalPlace } from "../../../utils/helpers";
import networkMapping from "../../../utils/networkMapping";

import EthSymbol from "../../../assets/img/eth-symbol.webp";
import BnbSymbol from "../../../assets/img/bnb-symbol.webp";
import MaticSymbol from "../../../assets/img/matic-symbol.webp";
import AvaxSymbol from "../../../assets/img/avax-symbol.webp";
import UsdtSymbol from "../../../assets/img/tether-icon.webp";
import UsdcSymbol from "../../../assets/img/usdc-symbol.webp";
import DaiSymbol from "../../../assets/img/dai-symbol.webp";

import Eth from "../../../assets/img/eth-icon.svg";
import Bnb from "../../../assets/img/bnb-symbol.webp";
import Matic from "../../../assets/img/matic-symbol.webp";
import Arb from "../../../assets/img/eth-icon.svg";
import Avax from "../../../assets/img/avax-symbol.webp";
import Op from "../../../assets/img/eth-icon.svg";
import Base from "../../../assets/img/eth-icon.svg";
import ZkSync from "../../../assets/img/eth-icon.svg";
import ecosystemFade from "../../../assets/img/snova-ecosystem-fade.png";
import ReactDOM from "react-dom";
import ModalPortal from "../../ModalPortal";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div style={{ position: "relative", cursor: "pointer" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>
      {showTooltip && (
        <div style={{ width: "max-content" }} className="main-tp to-os">
          <div />
          {text}
        </div>
      )}
    </div>
  );
};

const LaunchApp = () => {
  const dispatch = useDispatch();
  const { modal1, modal2, modal3, modal5, modal6 } = useSelector(
    (state) => state.modals
  );
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(911);

  const togglePopup = () => {
    if (!modal1.isOpen) {
      const currentScrollPos = window.scrollY;
      setScrollPosition(currentScrollPos + window.innerHeight / 2);
    }
    dispatch(setModal1({ ...modal1, isOpen: !modal1.isOpen }));
  };

  const toggleError = () => {
    dispatch(setModal2({ ...modal2, isOpen: !modal2.isOpen }));
  };
  const toggleUniversalError = () => {
    dispatch(setModal6({ ...modal6, isOpen: !modal6.isOpen }));
  };
  const toggleDeclined = () => {
    dispatch(setModal5({ ...modal5, isOpen: !modal5.isOpen }));
  };

  const toggleSuccess = () => {
    dispatch(setModal3({ ...modal3, isOpen: !modal3.isOpen }));
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((error) => console.error("Error copying text: ", error));
  };

  let currency, currencyName, network, networkName, explorerUrl, txnHash;
  if (modal1.data) {
    const currencyData = currencyMap[modal1.data.currency];
    const networkData = networkMap[modal1.data.network];
    const networkExplorer = networkMapping[modal1.data.network];

    if (currencyData) {
      currency = currencyData.symbol;
      currencyName = currencyData.name;
    }
    if (networkData) {
      network = networkData.icon;
      networkName = networkData.name;
    }
    if (networkExplorer) {
      explorerUrl = networkExplorer.explorer;
    }

    txnHash = modal1.data.transactionHash;
  }

  return (
    <>
      <div className="relative ">
        <img
          style={{ width: "450px" }}
          className="absolute w-[378px] max-12 h-[568px] left-[-100px] top-[-250px] md:w-[453px] md:h-[681px] md:left-[-50px] md:top-[-200px] lg:w-[604px] lg:h-[908px] lg:left-[-150px] lg:top-[-200px] xl:w-[756px] xl:h-[1136px] xl:left-[-100px] xl:top-[-250px] 2xl:w-[756px] 2xl:h-[1136px] 2xl:left-[-50px] 2xl:top-[-250px]"
          src={ecosystemFade}
          alt="orderly-network-main-integrate-sdk-background"
        />
        <div className="launchapp">
          <div className="styles_launchpadApp__cRj7u ">
            <div className="w-full max-width max-w-[144rem] eor justify-end my-0 mx-auto px-[6rem] gap-10 flex lg:flex-wrap lg:justify-center sm:px-[2rem]">
              <div className="whitespace-nowrap w-[100%] flex flex-col gap-[40px] mt-[100px] lg:order-2 lg:w-auto md:mt-[40px] sm:gap-[16px] sm:w-full">
                <h3 className="flex items-center gap-[14px] text-[44px] leading-normal sm:text-[28px]  __className_523ea1">
                  <span>SNOVA App</span>
                  <div className="relative">
                    <div className="w-[17px] h-[17px] bg-orange opacity-80 absolute top-[-3px] left-[-3px] rounded-full m-0 animate-ping" />
                    <div className="w-[11px] h-[11px] bg-orange rounded-full m-0" />
                  </div>
                  <span className="orange uppercase">
                    {t("homePage.launchApp.heading2")}
                  </span>
                </h3>
                <ul className="flex flex-col gap-[14px] mt-[20px]">
                  <li className="flex gap-[10px] items-center whitespace-break-spaces">
                    <div className="styles_triangle__J5S87" />
                    <p className="text-[24px] m-0 font-medium sm:text-[18px]">
                      {t("homePage.launchApp.feature1")}
                    </p>
                  </li>
                  <li className="flex gap-[10px] items-center whitespace-break-spaces">
                    <div className="styles_triangle__J5S87" />
                    <p className="text-[24px] m-0 font-medium sm:text-[18px]">
                      {t("homePage.launchApp.feature2")}
                    </p>
                  </li>
                  <li className="flex gap-[10px] items-center whitespace-break-spaces">
                    <div className="styles_triangle__J5S87" />
                    <p className="text-[24px] m-0 font-medium sm:text-[18px]">
                      {t("homePage.launchApp.feature3")}
                    </p>
                  </li>
                  <li className="flex gap-[10px] items-center whitespace-break-spaces">
                    <div className="styles_triangle__J5S87" />
                    <p className="text-[24px] m-0 font-medium sm:text-[18px]">
                      {t("homePage.launchApp.feature4")}
                    </p>
                  </li>
                  <li className="flex gap-[10px] items-center whitespace-break-spaces">
                    <div className="styles_triangle__J5S87" />
                    <p className="text-[24px] m-0 font-medium sm:text-[18px]">
                      {t("homePage.launchApp.feature5")}
                    </p>
                  </li>
                </ul>
                <div className="mt-5">
                  <a
                    href="https://t.me/StacknovaBTC"
                    target="_blank"
                    className="flat-toggle box-text box-btn corner-box"
                  >
                    <span className="toggle-title">
                      {t("homePage.launchApp.btnText")}
                    </span>
                  </a>
                  {/* <span
                  onClick={togglePopup}
                  className="flat-toggle me-2 ms-2 inline-flex box-text box-btn corner-box"
                >
                  <span className="toggle-title">Open Popup</span>
                </span>
                <span
                  onClick={toggleError}
                  className="flat-toggle me-2 ms-2 inline-flex box-text box-btn corner-box"
                >
                  <span className="toggle-title">Open Popup</span>
                </span>
                <br />
                <span
                  onClick={toggleSuccess}
                  className="flat-toggle mt-3 inline-flex box-text box-btn corner-box"
                >
                  <span className="toggle-title">Open Referral popup</span>
                </span> */}
                </div>
              </div>
              <div className="mr-[-250px] relative w-[100%] lg:mr-[-100px] lg:min-w-fit">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    className="absolute top-[-16px] left-[-16px]"
                  >
                    <path d="M24 1L1 0.999999L1 24" stroke="#fc6432" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    className="absolute bottom-[-16px] left-[-16px] rotate-[270deg]"
                  >
                    <path d="M24 1L1 0.999999L1 24" stroke="#fc6432" />
                  </svg>
                </div>

                <img
                  alt="launchpad"
                  loading="lazy"
                  width={758}
                  height={594}
                  decoding="async"
                  data-nimg={1}
                  className="styles_launchpadShadow__iZexE"
                  src={launchpad}
                  style={{ color: "transparent" }}
                />
                <div className="styles_launchpadBlackShadow__w0bSk" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Model popup */}
      {modal1.isOpen ? (
        <div onClick={togglePopup} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal1.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {/* <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
            {modal1.isOpen ? ( */}

                {/* <div className="heo"> */}
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  {/* <div className="relative mya myasp border-border bg-bg fixed left-1/2 top-1/2 z-[999] flex w-[370px] max-w-full flex-col gap-6 rounded border p-8"> */}
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />
                      {t("homePage.popup.purchaseSummary")}
                    </h2>
                    <div onClick={togglePopup} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseSuccess}
                      className="purchase-success"
                      alt="Purchase Success"
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("homePage.popup.congratulations")} <br />
                      {t("homePage.popup.purchaseSuccess")}
                    </h2>
                  </div>
                  {/* <div className="confirmation-block">
                <img src={purchaseSuccess} className="purchase-success" />
              </div> */}

                  <h3 className="text-center double-blk bjpar2 your-tokens-bg">
                    <div>
                      <img src={snovaCoin} className="purchased-snova" />
                      <span className="your-tokens">
                        {modal1.data
                          ? truncateToDecimalPlace(modal1.data.snovaTokens, 4) +
                            " SNOVA"
                          : ""}
                      </span>
                    </div>
                    <span className="your-tokens">
                      {" "}
                      {modal1.data ? "+" : ""}
                    </span>
                    <div>
                      <img src={novaPoints} className="purchased-snova" />
                      <span className="your-tokens">
                        {modal1.data
                          ? modal1.data.novaPoints + " Nova Points"
                          : ""}
                      </span>
                    </div>
                  </h3>
                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "2px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp column-dark"
                        >
                          {t("homePage.popup.tokenPrice")}
                        </td>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-cell no-tp column-light"
                        >
                          {modal1.data
                            ? "$" +
                              modal1.data.presaleStage.snova_price +
                              " (Stage " +
                              modal1.data.presaleStage.stage_id +
                              ")"
                            : "$0.05 (Stage 2)"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderTop: "none" }}
                          className="table-header column-light"
                        >
                          {t("homePage.popup.paymentDetails")}
                        </td>
                        <td
                          style={{ borderBottom: "none", borderTop: "none" }}
                          className="table-cell column-dark flex items-centers"
                        >
                          <img src={currency} className="payment-icon" />{" "}
                          {modal1.data
                            ? modal1.data.purchaseAmount +
                              " " +
                              modal1.data.currency
                            : "350 USDT"}
                          <span className="mbl-hidden">
                            &nbsp;
                            {modal1.data
                              ? "($" + modal1.data.purchase_amount_dollar + ")"
                              : "($350.00)"}
                          </span>
                          <Tooltip
                            text={t("homePage.popup.viewTransactionDetails")}
                          >
                            <a
                              target="_blank"
                              href={`${explorerUrl}${txnHash}`}
                            >
                              <img
                                src={transactionLink}
                                className="transaction-link"
                              />
                            </a>
                          </Tooltip>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header column-dark">
                          {t("homePage.popup.networkUsed")}
                        </td>
                        <td className="table-cell nb column-light">
                          <img src={network} className="network-icon" />
                          {networkName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex main-gb">
                    <button
                      onClick={togglePopup}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("homePage.popup.buyMore")}
                    </button>

                    <NavLink
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      to="/dashboard"
                      bis_skin_checked={1}
                      style={{ background: "transparent" }}
                    >
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                        bis_skin_checked={1}
                      />
                      {t("homePage.popup.accessDashboard")}
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                        bis_skin_checked={1}
                      />
                    </NavLink>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("homePage.popup.tokensUnlock")}</i>
                  </div>
                  <div className="invite-card">
                    <h1 className="invite-title primary-color">
                      <img src={referralCoins} className="referral-coins" />{" "}
                      {t("homePage.popup.earn20")}
                    </h1>
                    <p className="invite-text">
                      {t("homePage.popup.inviteFriends")}
                    </p>
                    <div className="invite-link-container">
                      <div className="invite-link">
                        <span>
                          {modal1.data
                            ? formatReferralLink(modal1.data.referralLink)
                            : "stacknova.ai/...1W6ASOL1"}
                        </span>
                        <button className="copy-button">
                          {copied ? (
                            <div className="_copyBtn_1fjrj_20 we">
                              <img src={done} alt="Copy" />
                            </div>
                          ) : (
                            <div
                              className="_copyBtn_1fjrj_20 we"
                              onClick={() =>
                                handleCopy(modal1.data.referralLink)
                              }
                            >
                              <img src={CopyIco} alt="Copy" />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal2.isOpen ? (
        <div onClick={toggleDeclined} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal2.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={transactionError}
                        className="purchase-summary"
                      />

                      {t("popups.errorModal.transactionError")}
                    </h2>
                    <div onClick={toggleError} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseError}
                      className="purchase-success"
                      alt="Purhase Error"
                      style={{ width: "200px" }}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("popups.errorModal.transactionErrorDetail")}
                    </h2>
                  </div>
                  <h3 className="text-center bjpar your-tokens-bg">
                    <span className="your-tokens">
                      {t("popups.errorModal.insuficientGas.title")}
                    </span>
                  </h3>
                  <div className="card-stam">
                    <div className="card-item-stam">
                      <ol className="list-stam">
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.insuficientGas.errorTable.heading1"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.insuficientGas.errorTable.description1"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.insuficientGas.errorTable.heading2"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.insuficientGas.errorTable.description2"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.insuficientGas.errorTable.heading3"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.insuficientGas.errorTable.description3"
                          )}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleError}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.errorModal.tryAgain")}
                    </button>

                    <a
                      href="https://t.me/StacknovaBTC"
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      target="_blank"
                      bis_skin_checked={1}
                      style={{ background: "transparent" }}
                    >
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                        bis_skin_checked={1}
                      />{" "}
                      {t("popups.errorModal.getSupport")}
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                        bis_skin_checked={1}
                      />
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.errorModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal3.isOpen ? (
        <div onClick={toggleDeclined} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal3.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-custom-tx-modal rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />

                      {t("popups.summaryModal.rewardsSummary")}
                    </h2>
                    <div onClick={toggleSuccess} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <h2 id="radix-:R1r6mH1:" className="orange mibj">
                    {t("popups.summaryModal.wellDone")}
                  </h2>

                  <div className="confirmation-block">
                    <img src={claimSuccess} className="claim-success" />
                  </div>
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <div>
                      <img src={Usd} className="purchased-snova" />
                      <span className="your-tokens">1000 USD</span>
                    </div>
                    <span className="your-tokens">+</span>
                    <div>
                      <img src={snovaCoin} className="purchased-snova" />
                      <span className="your-tokens">1000 SNOVA</span>
                    </div>
                    <span className="your-tokens">+</span>
                    <div>
                      <img src={novaPoints} className="purchased-snova" />
                      <span className="your-tokens">1200 Nova Points</span>
                    </div>
                  </h3>
                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "2px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp nobot  column-dark"
                        >
                          Network
                        </td>
                        <td
                          className="table-cell no-tp column-light"
                          style={{ display: "flex", borderBottom: "none" }}
                        >
                          <img src={Eth} className="network-icon" />
                          Ethereum
                          <Tooltip
                            text={t("homePage.popup.viewTransactionDetails")}
                          >
                            <a target="_blank" href="">
                              <img
                                src={transactionLink}
                                className="transaction-link add-space"
                              />
                            </a>
                          </Tooltip>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header no-tp nmop column-light">
                          ETH Claimed
                        </td>
                        <td className="table-cell column-dark no-tp  flex items-centers">
                          <img src={EthSymbol} className="network-icon" /> 0.21
                          ETH ($800.00)
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header column-dark">
                          USDT Claimed
                        </td>
                        <td
                          className="table-cell nb column-light"
                          style={{ display: "flex", borderTop: "none" }}
                        >
                          <img src={Tether} className="payment-icon" />
                          100 USDT ($100.00)
                        </td>
                      </tr>
                      <tr>
                        <td className="table-header column-light">
                          USDC Claimed
                        </td>
                        <td className="table-cell nb column-dark ">
                          <img src={UsdC} className="network-icon" />
                          100 USDC ($100.00)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleSuccess}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.summaryModal.referMore")}
                    </button>

                    <NavLink
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      to="/dashboard"
                      bis_skin_checked={1}
                      style={{ background: "transparent" }}
                    >
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                        bis_skin_checked={1}
                      />
                      {t("popups.summaryModal.accessDashboard")}
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                        bis_skin_checked={1}
                      />
                    </NavLink>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.summaryModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal5.isOpen ? (
        <div onClick={toggleDeclined} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal5.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={transactionError}
                        className="purchase-summary"
                      />
                      {t("popups.errorModal.transactionError")}
                    </h2>
                    <div onClick={toggleDeclined} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseError}
                      className="purchase-success"
                      alt="Purhase Error"
                      style={{ width: "200px" }}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("popups.errorModal.transactionErrorDetail")}
                    </h2>
                  </div>
                  <h3 className="text-center bjpar your-tokens-bg">
                    <span className="your-tokens">
                      {t("popups.errorModal.rejection.title")}
                    </span>
                  </h3>
                  <div className="card-stam">
                    <div className="card-item-stam">
                      <ol className="list-stam">
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.rejection.errorTable.heading1"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.rejection.errorTable.description1"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.rejection.errorTable.heading2"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.rejection.errorTable.description2"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.rejection.errorTable.heading3"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.rejection.errorTable.description3"
                          )}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleDeclined}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.errorModal.tryAgain")}
                    </button>

                    <a
                      href="https://t.me/StacknovaBTC"
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      target="_blank"
                      bis_skin_checked={1}
                      style={{ background: "transparent" }}
                    >
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                        bis_skin_checked={1}
                      />{" "}
                      {t("popups.errorModal.getSupport")}
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                        bis_skin_checked={1}
                      />
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.errorModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal6.isOpen ? (
        <div onClick={toggleDeclined} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal6.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={transactionError}
                        className="purchase-summary"
                      />
                      {t("popups.errorModal.transactionError")}
                    </h2>
                    <div onClick={toggleUniversalError} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseError}
                      className="purchase-success"
                      alt="Purhase Error"
                      style={{ width: "200px" }}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("popups.errorModal.transactionErrorDetail")}
                    </h2>
                  </div>
                  <h3 className="text-center bjpar your-tokens-bg">
                    <span className="your-tokens">
                      {t("popups.errorModal.universalFailure.title")}
                    </span>
                  </h3>
                  <div className="card-stam">
                    <div className="card-item-stam">
                      <ol className="list-stam">
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.universalFailure.errorTable.heading1"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.universalFailure.errorTable.description1"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.universalFailure.errorTable.heading2"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.universalFailure.errorTable.description2"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.universalFailure.errorTable.heading3"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.universalFailure.errorTable.description3"
                          )}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleUniversalError}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.errorModal.tryAgain")}
                    </button>

                    <a
                      href="https://t.me/StacknovaBTC"
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      target="_blank"
                      bis_skin_checked={1}
                      style={{ background: "transparent" }}
                    >
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"
                        bis_skin_checked={1}
                      />{" "}
                      {t("popups.errorModal.getSupport")}
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"
                        bis_skin_checked={1}
                      />
                      <div
                        className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"
                        bis_skin_checked={1}
                      />
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.errorModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {/* Model popup */}
    </>
  );
};

export default LaunchApp;
