// src/reducers/userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletAddress: null,
  verified: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      // Expecting an object like { walletAddress, verified }
      state.walletAddress = action.payload.walletAddress || null;
      state.verified = !!action.payload.verified;
    },
    clearUser(state) {
      state.walletAddress = null;
      state.verified = false;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
