import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rewards: {},
  totalRewards: "0",
  allRewards: undefined, // Properly initialized as an empty object
  totalValuesByChain: {},
  referralTransactionsData: null,
};

const referralRewardsSlice = createSlice({
  name: "referralRewards",
  initialState,
  reducers: {
    setRewards(state, action) {
      state.rewards = action.payload;
    },
    setTotalRewards(state, action) {
      state.totalRewards = action.payload;
    },
    setAllRewards(state, action) {
      state.allRewards = action.payload ?? {}; // Ensure it's always an object
    },
    setGlobalRewardsOfUser(state, action) {
      state.globalRewardsOfUser = action.payload ?? {}; // Ensure it's always an object
    },
    setReferralTransactionsData(state, action) {
      state.referralTransactionsData = action.payload;
    },
  },
});

export const {
  setRewards,
  setTotalRewards,
  setAllRewards,
  setGlobalRewardsOfUser,
  setReferralTransactionsData,
} = referralRewardsSlice.actions;
export default referralRewardsSlice.reducer;
