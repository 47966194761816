// Define primary and fallback providers for each chain
const providersByChainId = {
  1: [
    //1.alchemy 2.getBlock 3.ankr
    "https://cloudflare-eth.com",
    "https://rpc.ankr.com/eth", // ETH mainnet fallback
    "https://eth-mainnet.g.alchemy.com/v2/t5giCoRhjhT7twECy6z9sbvFt_rX0vVQ", // ETH mainnet primary
    "https://go.getblock.io/30bcffbb97444e61951770aad28f634a", // ETH mainnet fallback
  ],
  10: [
    //1.alchemy 2.getBlock 3.ankr
    "https://mainnet.optimism.io",
    "https://rpc.ankr.com/optimism", // Optimism fallback
    "https://opt-mainnet.g.alchemy.com/v2/t5giCoRhjhT7twECy6z9sbvFt_rX0vVQ", // Optimism primary
    "https://go.getblock.io/c97617310f3c440c88f7b5b7e6e675d1", // Optimism fallback
  ],
  56: [
    //1.getBlock 2.ankr
    "https://bsc-dataseed1.bnbchain.org",
    "https://rpc.ankr.com/bsc", // BSC fallback
    "https://go.getblock.io/20fc392b166a4222984b093dc68ac930", // BSC primary
  ],
  137: [
    //1.aclchemy 2.infura 3.ankr
    "https://polygon-rpc.com/",
    "https://rpc.ankr.com/polygon",
    "https://polygon-mainnet.g.alchemy.com/v2/t5giCoRhjhT7twECy6z9sbvFt_rX0vVQ", // Polygon primary
    "https://polygon-mainnet.infura.io/v3/0255ee7a6e8c49fc86059632ce5ce405", // Polygon fallback
  ],
  324: [
    //1.alchemy 2.getBlock 3.ankr
    "https://mainnet.era.zksync.io",
    "https://zksync-mainnet.g.alchemy.com/v2/t5giCoRhjhT7twECy6z9sbvFt_rX0vVQ", // zkSync primary
    "https://go.getblock.io/657373a963324b79a0d6fc99f5fdfc9d", // zkSync fallback
    "https://rpc.ankr.com/zksync_era", // zkSync fallback
  ],
  8453: [
    //1.alchemy 2.ankr
    "https://mainnet.base.org/",
    "https://base-mainnet.g.alchemy.com/v2/t5giCoRhjhT7twECy6z9sbvFt_rX0vVQ", // Base primary
    "https://rpc.ankr.com/base", // Base fallback
  ],
  42161: [
    //1 alchemy 2.ankr
    "https://arb1.arbitrum.io/rpc",
    "https://rpc.ankr.com/arbitrum",
    "https://arb-mainnet.g.alchemy.com/v2/t5giCoRhjhT7twECy6z9sbvFt_rX0vVQ", // Arbitrum primary
  ],
  43114: [
    //1.quicknode 2.getBlock 3.ankr
    "https://api.avax.network/ext/bc/C/rpc",
    "https://rpc.ankr.com/avalanche", // Avalanche fallback
    "https://solemn-lively-wildflower.avalanche-mainnet.quiknode.pro/0146ccc69e62c19846c5d8f4ca4e8c2ec17aebd9/ext/bc/C/rpc/", // Avalanche primary
    "https://go.getblock.io/6addfdd6073a463e9ea3c2622859b9d1", // Avalanche fallback
  ],
};

export default providersByChainId;
