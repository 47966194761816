import React from "react";
import Background from "../components/Referral/Background/Background";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import Banner from "../components/Staking/Banner";
import Top from "../components/top";
import usePageTitle from "../hooks/usePageTitle";
import StakingHeader from "../components/Staking/Header";

const Staking = () => {
    usePageTitle("Staking | Stacknova AI - Earn Up to 111.5% APY on Your SNOVA");

    return (
        <div className=" min-ref">
            <Header />
            <Top />
            <div className="min-h-screen relative bg-[#000] referral">
                <Background />
                <div className="max-width">
                    <div className="pt-[96px] mbl-smalle laptop:pt-[132px]">
                        <StakingHeader />
                        <Banner />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Staking;
