// hooks/useComputeButtonContent.js
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { WalletIcon } from "../components/Home/Banner/Icons/WalletIcon";
import { ExclamationIcon } from "../components/Home/Banner/Icons/ExclamationIcon";
import { CoinsIcon } from "../components/Home/Banner/Icons/CoinsIcon";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import useCheckApproval from "../hooks/useCheckApproval";
import { ethers } from "ethers";
import tokensByChainId from "../utils/tokensByChainId";

const truncateToDecimalPlace = (num, decimalPlaces) => {
  const numStr = num?.toString();
  const regex = new RegExp(`^(.*\\..{${decimalPlaces}})|.*$`);
  const matches = numStr?.match(regex);
  return matches && matches[1] ? matches[1] : numStr;
};

const BANNER_CLASSNAME = "itmes-center conten";
const CONNECT_MBL_CLASSNAME =
  "connect-mbl connect-lan bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-text-primary";

const TRANSLATION_KEYS = {
  HOME_PAGE: {
    BANNER: {
      CONNECT_WALLET: "homePage.banner.connectWallet",
      MINIMUM_INVESTMENT: "homePage.banner.minimumInvestment",
      INSUFFICIENT_BALANCE: "homePage.banner.insuficientBalance",
      BUY_TOKEN: "homePage.banner.buyToken",
      CONNECT_YOUR_WALLET: "homePage.banner.connectYourWallet",
      APPROVE_TOKEN: "homePage.banner.approveTokens",
      APPROVING: "homePage.banner.approvingTokens",
      CONFIRMING: "homePage.banner.buying",
    },
  },
  HEADER: {
    CONNECT_WALLET: "Header.connectWallet",
  },
};

export const useComputeButtonContent = ({
  isConnected,
  address,
  balance,
  balanceInDollar,
  selectedCurrency,
  buttonText,
  dollarAmount,
  className,
}) => {
  const { t } = useTranslation();
  const { chainId } = useWeb3ModalAccount();
  const EMPTY_OBJECT = Object.freeze({});
  const EMPTY_APPROVED_STATUS = Object.freeze({
    approvedCurrencies: EMPTY_OBJECT,
    approvedAmount: "0",
  });
  const approvedStatus = useSelector(
    (state) => state.approval[chainId] || EMPTY_APPROVED_STATUS
  );
  const approvedCurrencies = approvedStatus.approvedCurrencies || EMPTY_OBJECT;
  const approvedAmount = approvedStatus.approvedAmount || "0";
  const ethAmount = useSelector((state) => state.amounts.ethAmount);
  const isBlurred = useSelector((state) => state.blur.isBlurred);
  useCheckApproval(selectedCurrency, ethAmount);

  return useMemo(() => {
    let IconComponent = WalletIcon;
    let buttonTextOutput = buttonText;
    let isDisabled = false;
    let isAddressVersion = false;
    let isApproved = false;

    if (ethAmount && ethAmount !== "") {
      isApproved =
        approvedCurrencies[selectedCurrency] &&
        ethers.BigNumber.from(approvedAmount).gte(
          ethers.utils.parseUnits(
            ethAmount.toString(),
            tokensByChainId?.[chainId]?.[selectedCurrency]?.decimals
          )
        );
    }

    const handleConnectYourWallet = () => {
      return balance !== null && balance !== undefined
        ? `${Number(truncateToDecimalPlace(balance, 4)).toFixed(
            4
          )} ${selectedCurrency} ($${Number(
            truncateToDecimalPlace(balanceInDollar, 2)
          ).toFixed(2)})`
        : t(TRANSLATION_KEYS.HOME_PAGE.BANNER.CONNECT_YOUR_WALLET);
    };

    const handleHomePageBanner = () => {
      if (isBlurred) {
        buttonTextOutput = isApproved
          ? t(TRANSLATION_KEYS.HOME_PAGE.BANNER.CONFIRMING)
          : t(TRANSLATION_KEYS.HOME_PAGE.BANNER.APPROVING);
        isDisabled = true;
      } else {
        switch (buttonText) {
          case t(TRANSLATION_KEYS.HOME_PAGE.BANNER.CONNECT_WALLET):
            if (dollarAmount < 50) {
              buttonTextOutput = t(
                TRANSLATION_KEYS.HOME_PAGE.BANNER.MINIMUM_INVESTMENT
              );
              IconComponent = ExclamationIcon;
              isDisabled = true;
            } else if (dollarAmount > balanceInDollar) {
              buttonTextOutput = t(
                TRANSLATION_KEYS.HOME_PAGE.BANNER.INSUFFICIENT_BALANCE
              );
              IconComponent = ExclamationIcon;
              isDisabled = true;
            } else {
              if (
                selectedCurrency === "USDT" ||
                selectedCurrency === "USDC" ||
                selectedCurrency === "DAI" ||
                selectedCurrency === "ARB" ||
                selectedCurrency === "OP" ||
                selectedCurrency === "ZK"
              ) {
                if (isApproved) {
                  buttonTextOutput = t(
                    TRANSLATION_KEYS.HOME_PAGE.BANNER.BUY_TOKEN
                  );
                  isDisabled = process.env.REACT_APP_ACTIVE_PRESALE === "false";
                  IconComponent = CoinsIcon;
                } else {
                  buttonTextOutput = t(
                    TRANSLATION_KEYS.HOME_PAGE.BANNER.APPROVE_TOKEN
                  );
                  isDisabled = process.env.REACT_APP_ACTIVE_PRESALE === "false";
                  IconComponent = CoinsIcon;
                }
              } else {
                buttonTextOutput = t(
                  TRANSLATION_KEYS.HOME_PAGE.BANNER.BUY_TOKEN
                );
                isDisabled = process.env.REACT_APP_ACTIVE_PRESALE === "false";
                IconComponent = CoinsIcon;
              }
            }
            break;
          case t(TRANSLATION_KEYS.HOME_PAGE.BANNER.CONNECT_YOUR_WALLET):
            buttonTextOutput = handleConnectYourWallet();
            break;
          default:
            buttonTextOutput = "";
            break;
        }
      }
    };

    const handleHeader = () => {
      const targetClassName =
        "MuiTypography-root MuiTypography-body1 css-1enqu04";

      const getButtonTextOutputForDefault = () => {
        switch (buttonText) {
          case t(TRANSLATION_KEYS.HEADER.CONNECT_WALLET):
            isAddressVersion = true;
            return address
              ? `${address.slice(0, 6)}...${address.slice(-4)}`
              : "Connected";
          default:
            return "";
        }
      };

      const getButtonTextOutputForTargetClass = () => {
        switch (buttonText) {
          case t(TRANSLATION_KEYS.HOME_PAGE.BANNER.CONNECT_YOUR_WALLET):
            return handleConnectYourWallet();
          default:
            return "";
        }
      };

      buttonTextOutput =
        className !== targetClassName
          ? getButtonTextOutputForDefault()
          : getButtonTextOutputForTargetClass();
    };

    if (isConnected) {
      if (className.includes(BANNER_CLASSNAME)) {
        handleHomePageBanner();
      } else {
        handleHeader();
      }
    }

    if (className.includes(CONNECT_MBL_CLASSNAME)) {
      isAddressVersion = true;
    }

    return {
      text: buttonTextOutput || "",
      image: IconComponent,
      isDisabled,
      isAddressVersion,
      isApproved,
    };
  }, [
    isConnected,
    address,
    balance,
    balanceInDollar,
    selectedCurrency,
    buttonText,
    dollarAmount,
    className,
    t,
    approvedCurrencies,
    approvedAmount,
    chainId,
    ethAmount,
    isBlurred,
  ]);
};

useComputeButtonContent.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  address: PropTypes.string,
  balance: PropTypes.number,
  balanceInDollar: PropTypes.number,
  selectedCurrency: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  dollarAmount: PropTypes.number,
  className: PropTypes.string.isRequired,
};
