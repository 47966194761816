import { useState, useEffect } from "react";

export default function useDeviceType() {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    function checkDeviceType() {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType("mobile");
      } else if (width < 1024) {
        setDeviceType("air");
      } else if (width < 1025) {
        setDeviceType("pro");
      } else {
        setDeviceType("desktop");
      }
    }

    // Run once on mount:
    checkDeviceType();

    // Listen for window resizes:
    window.addEventListener("resize", checkDeviceType);

    // Cleanup on unmount:
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return deviceType;
}
