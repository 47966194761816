
import React, { useState } from "react";
import snovaStaking from '../../assets/img/snova-staking.png';

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReferralLink from "../Referral/Banner/bannerSelectors/referralLink";

const Banner = () => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();


  return (
    <>
      <Link
        className="_btn_dcpj6_1 inline-flex items-center self-start gap-[10px] p-[8px]"
        to="/"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={14}
          height={12}
          viewBox="0 0 14 12"
          fill="none"
        >
          <path
            d="M13 6L1.51741 6M5.93379 11L1.2587 6.58925C0.913764 6.26382 0.913764 5.73618 1.2587 5.41074L5.93379 1"
            stroke="white"
            strokeWidth={2}
            strokeLinecap="round"
          />
        </svg>
        <span className="font-[500] text-[white]">
          {t("referralPage.banner.presaleStartsSoon")}
        </span>
      </Link>

      <div className="flex justify-center mt-[24px]">
        <div className="laptop:max-w-[504px]">
          <div className="mb-[12px] relative">

            <h1 style={{ lineHeight: "normal" }} className="spbt text-mb mb-[12px] text-defaultOrange text-[42px] laptop:text-[64px] laptop:text-center stake-snova-heading">
              {t("Staking.header.title")}
            </h1>

          </div>
          <p className="text-mb laptop:text-center text-[#D6D6D6] text-[16px]">
            {t("Staking.header.description")}

          </p>
          <div className="flex flex-col mt-[24px]">
            <div className="sc-VFtFE ghPkTE mb-40"><img src={snovaStaking} alt="KyberDAO" style={{ width: "195.3pox", height: "180.52px", "margin-top": "10px" }} /></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
