import { useDispatch, useSelector } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { Contract, ethers } from "ethers";

import { setBlur } from "../reducers/blurSlice";
import {
  setModal1,
  setModal2,
  setModal5,
  setModal6,
} from "../reducers/modalSlice";
import { setSnovaData } from "../reducers/snovaDataSlice";
import { setTotalSnovaTokens } from "../reducers/totalSnovaTokensSlice";
import { setTotalSnovaValue } from "../reducers/totalSnovaValueSlice";
import { setAllTransactions } from "../reducers/transactionsSlice";
import { setTotalNovaPoints } from "../reducers/totalNovaPointsSlice";
import {
  setTotalReferralRewards,
  setPendingReferralRewardsInDollars,
} from "../reducers/totalReferralRewardsSlice";
import { setUserRankingByRewards } from "../reducers/userRankingByRewardsSlice";
import { setUserRankingByNovaPoints } from "../reducers/userRankingByNovaPointsSlice";
import { setNovaPoints } from "../reducers/amountsSlice";
import { setRewards } from "../reducers/referralRewardsSlice";
import { setMainBalancesData } from "../reducers/mainBalancesSlice";

import { truncateToDecimalPlace, getCookie } from "../utils/helpers";
import tokensByChainId from "../utils/tokensByChainId";
import { getPresaleAddress } from "../utils/getAddresses";
import { signAndVerifyIfNeeded } from "../utils/verificationUtils";
import presaleAbi from "../utils/abis/presaleAbi.json";
import axios from "axios";

const PRESALE_ABI = presaleAbi;
const ZERO_ADDRESS = ethers.constants.AddressZero;

async function getReferrerAddress(referrerUrl) {
  if (!referrerUrl) return ZERO_ADDRESS;
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ referrerUrl }),
        credentials: "include",
      }
    );
    if (resp.ok) {
      const data = await resp.json();
      return data.referrerAddress;
    }
    return ZERO_ADDRESS;
  } catch {
    return ZERO_ADDRESS;
  }
}

async function getActivePresaleStage() {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getActivePresaleStage`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (resp.ok) {
      return await resp.json();
    }
  } catch (error) {
    console.error("Error fetching presale stage:", error);
  }
  return null;
}

/* -------------------------------------------------------------------
 * extractTransactionPayload
 * ------------------------------------------------------------------*/
function extractTransactionPayload(
  tokensPurchasedEvent,
  receipt,
  referrerAddr,
  activePresaleStage,
  currency,
  address,
  chainId,
  referrerUrl,
  referralLink
) {
  const decimals = tokensByChainId[chainId][currency].decimals;

  const purchaseAmount = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.amount,
    decimals
  );
  const transactionHash = receipt.transactionHash;
  const blockNumber = receipt.blockNumber;
  const gasUsed = receipt.gasUsed.toNumber();
  const gasPrice = receipt.effectiveGasPrice;
  const snovaTokens = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.sold,
    18
  );

  const transactionDate = new Date().toISOString();
  const investmentUSD = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.investmentUSD,
    18
  );
  const currencyPrice = tokensPurchasedEvent.args.currencyPrice.toString();
  const novaPoints = tokensPurchasedEvent.args.novaPoints.toString();
  const presaleAddress = getPresaleAddress(chainId);

  return {
    address,
    purchaseAmount,
    currency_price: currencyPrice,
    purchase_amount_dollar: truncateToDecimalPlace(investmentUSD, 2),
    currency,
    network: chainId,
    transactionHash,
    contractAddress: presaleAddress,
    snovaTokens,
    novaPoints,
    transactionDate,
    referrerUrl,
    referrerAddress: tokensPurchasedEvent.args.ref.toString(),
    presaleStage: activePresaleStage,
    gasUsed,
    gasPrice: gasPrice.toString(),
    blockNumber,
    referralLink,
  };
}

async function fetchMainBalancesPublic(walletAddress) {
  try {
    // Build the query string ?walletAddress=...
    const url = `${process.env.REACT_APP_SERVER_URL}/getMainBalances?walletAddress=${walletAddress}`;
    const response = await axios.get(url, {
      withCredentials: true, // if you still want the CSRF cookie
    });
    return response.data; // { totalSnovaTokens, totalSnovaValue, totalReferralEarnings }
  } catch (error) {
    console.error("Error fetching main balances:", error);
    throw error;
  }
}

/* -------------------------------------------------------------------
 * Main hook: usePurchase
 * ------------------------------------------------------------------*/
export default function usePurchase() {
  const dispatch = useDispatch();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  // Эти данные берем из Redux
  const referrer = useSelector((state) => state.referrer.referrer);
  const referralLink = useSelector((state) => state.referral.referral);
  const initialReferrerAddr = useSelector(
    (state) => state.referrer.referrerAddress
  );

  /* ----------------------------------------------------------------
   * fetchInvestorDashboardData
   * ----------------------------------------------------------------*/
  const fetchInvestorDashboardData = async () => {
    try {
      const csrfToken = getCookie("csrfToken");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getInvestorDashboard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken || "",
          },
          body: JSON.stringify({ walletAddress: address }),
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch(setTotalSnovaTokens(data.totalSnovaTokens || 0));
        dispatch(setRewards({ SNOVA: data.earnedSnovaTokens || 0 }));
        dispatch(setTotalSnovaValue(data.totalSnovaValue || 0));
        dispatch(setAllTransactions(data.transactions || []));
        dispatch(setTotalNovaPoints(data.totalNovaPoints || 0));
        dispatch(setNovaPoints(data.earnedNovaPoints || 0));
        dispatch(
          setTotalReferralRewards(data.totalReferralRewardsInDollars || 0)
        );
        dispatch(
          setPendingReferralRewardsInDollars(
            data.pendingReferralRewardsInDollars || 0
          )
        );
        dispatch(setUserRankingByRewards(data.userRankingByRewards || null));
        dispatch(
          setUserRankingByNovaPoints(data.userRankingByNovaPoints || null)
        );
      } else {
        const errData = await response.json();
        console.error(errData.error || "Failed to fetch dashboard");
      }
    } catch (error) {
      console.error("Error fetching investor dashboard data:", error);
    }
  };

  /* ----------------------------------------------------------------
   * purchase function
   * ----------------------------------------------------------------*/
  const purchase = async (amount, currency) => {
    if (!getPresaleAddress(chainId)) return;
    dispatch(setBlur(true));

    if (!isConnected) {
      dispatch(setBlur(false));
      throw new Error("Wallet is not connected");
    }

    try {
      // Step A) Referrer address
      let referrerUrl = referrer;
      let referrerAddr = initialReferrerAddr;
      if (!referrerAddr) {
        referrerAddr = await getReferrerAddress(referrerUrl);
      }

      // Step C) On-chain purchase
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      // Step B) Ensure verified
      await signAndVerifyIfNeeded(address, ethersProvider);
      const signer = ethersProvider.getSigner();

      const PresaleContract = new Contract(
        getPresaleAddress(chainId),
        PRESALE_ABI,
        signer
      );
      const decimals = tokensByChainId[chainId]?.[currency]?.decimals;
      if (decimals === undefined) {
        throw new Error(`Unknown currency ${currency} on chainId ${chainId}`);
      }
      const parsedAmount = ethers.utils.parseUnits(amount.toString(), decimals);

      const isNative = ["ETH", "BNB", "POL", "AVAX"].includes(currency);
      const transactionOptions = isNative
        ? { value: parsedAmount }
        : { value: 0 };

      const passedAmount = isNative ? 0 : parsedAmount;

      const tokenAddress = tokensByChainId[chainId]?.[currency]?.address;
      if (!tokenAddress) {
        dispatch(
          setModal2({
            isOpen: true,
            data: {
              error: `Token address for ${currency} not found on chain ${chainId}`,
            },
          })
        );
        dispatch(setBlur(false));
        return;
      }

      // Call purchaseTokens(...)
      const txResponse = await PresaleContract.purchaseTokens(
        referrerAddr || ZERO_ADDRESS,
        tokenAddress,
        passedAmount,
        {
          ...transactionOptions,
          gasLimit: ethers.utils.hexlify(1000000),
        }
      );
      const receipt = await txResponse.wait();
      if (receipt.status !== 1) {
        throw new Error("Transaction failed on-chain (receipt.status !== 1)");
      }

      // Find "TokensPurchased" event
      const tokensPurchasedEvent = receipt.events.find(
        (evt) => evt.event === "TokensPurchased"
      );
      if (!tokensPurchasedEvent) {
        dispatch(
          setModal2({
            isOpen: true,
            data: { error: "TokensPurchased event not found" },
          })
        );
        dispatch(setBlur(false));
        return;
      }

      // Fetch presale stage
      const activePresaleStage = await getActivePresaleStage();
      if (!activePresaleStage) {
        dispatch(
          setModal2({
            isOpen: true,
            data: { error: "Failed to get active presale stage" },
          })
        );
        dispatch(setBlur(false));
        return;
      }

      // Build transaction payload
      const transactionPayload = extractTransactionPayload(
        tokensPurchasedEvent,
        receipt,
        referrerAddr,
        activePresaleStage,
        currency,
        address,
        chainId,
        referrerUrl,
        referralLink
      );

      // Show local summary modal
      dispatch(setModal1({ isOpen: true, data: transactionPayload }));

      // Step D) POST to transactionHandler
      const csrfToken = getCookie("csrfToken");
      const resp = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/transactionHandler`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken || "",
          },
          body: JSON.stringify(transactionPayload),
        }
      );
      if (resp.ok) {
        // Possibly fetch updated data
        try {
          const snovaRes = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getSnovaData`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          if (snovaRes.ok) {
            const snovaData = await snovaRes.json();
            dispatch(setSnovaData(snovaData));
            await fetchInvestorDashboardData();
            const data = await fetchMainBalancesPublic(address);
            dispatch(setMainBalancesData(data));
          }
        } catch (err) {
          console.error("Error fetching updated Snova data:", err);
        }
      } else {
        const errResp = await resp.json();
        console.error("Transaction handler error:", errResp.error);
      }
    } catch (err) {
      console.error("purchase error:", err);
      if (
        err.code === 4001 ||
        (err.code === undefined && err.message.includes("User rejected")) ||
        err.message.includes("rejected") ||
        err.message.includes("declined") ||
        err.message.includes("cancelled")
      ) {
        dispatch(
          setModal5({
            isOpen: true,
            data: { error: "Transaction was declined by the user." },
          })
        );
      } else if (
        (err.code === -32000 &&
          (err.message.includes("intrinsic gas too low") ||
            err.message.includes("gas required exceeds allowance") ||
            err.message.includes("out of gas") ||
            err.message.includes("exceeds gas limit"))) ||
        err.message.includes("gas too low")
      ) {
        dispatch(setModal2({ isOpen: true, data: { error: err.message } }));
      } else {
        dispatch(setModal6({ isOpen: true, data: { error: err.message } }));
      }
    } finally {
      dispatch(setBlur(false));
    }
  };

  return { purchase };
}
