import React from "react";
import "./shimmer.css"; // Make sure this file contains your shimmer styles

const UserListShimmerPlaceholderMobile = () => {
  return (
    <>
      {Array.from({ length: 10 }).map((_, index) => (
        <li
          key={index}
          className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
          style={{
            background: "rgba(250, 250, 250, 0.04)",
            color: "rgba(255, 255, 255, 0.32)",
          }}
        >
          {/* Top section (ranking, EVM, wallet address) */}
          <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
            {/* If you have a background image, you can put a shimmer block or leave it commented out */}
            {/* 
            <span className="shimmer shimmer-bg absolute w-full h-full top-0 left-0 z-[-1]" />
            */}
            <div className="flex items-center">
              {/* Ranking shimmer placeholder */}
              <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px]">
                <span
                  className="shimmer shimmer-text"
                  style={{ width: "25px" }}
                />
              </span>
              {/* EVM shimmer placeholder */}
              <span
                className="shimmer shimmer-text"
                style={{ width: "40px" }}
              />
            </div>

            {/* Wallet address shimmer placeholder */}
            <span className="shimmer shimmer-text" style={{ width: "155px" }} />
          </div>

          {/* Bottom section (Referrals and Earnings) */}
          <div className="flex flex-col gap-[12px] text-white">
            <div className="flex justify-between items-center">
              <span
                className="shimmer shimmer-text"
                style={{ width: "100px" }}
              />
              {/* Referrals shimmer placeholder */}
              <span
                className="shimmer shimmer-text"
                style={{ width: "40px" }}
              />
            </div>
            <div className="flex justify-between items-center">
              <span
                className="shimmer shimmer-text"
                style={{ width: "180px" }}
              />
              {/* Earnings shimmer placeholder */}
              <span
                className="shimmer shimmer-text"
                style={{ width: "90px" }}
              />
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

export default UserListShimmerPlaceholderMobile;
