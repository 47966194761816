import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import blockies from "ethereum-blockies";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import topEarnerIcon from "../../../assets/img/top-earners.png";
import myRefferalIcon from "../../../assets/img/my-referrals.png";
import done from "../../../assets/img/done.svg";
import copy from "../../../assets/img/copy.svg";
import Exclamation from "../../../assets/img/Exclamation.svg";
import bgRank from "../../../assets/img/bg-rank.webp";
import EthDiamond from "../../../assets/img/ethereum-nirzYqqu.webp";
import CircleInfo from "../../../assets/img/circle-info.svg";
import pendingRewards from "../../../assets/img/pending-referral-rewards.png";
import totalEarnings from "../../../assets/img/total-referral-earnings.png";
import snovaCoin from "../../../assets/img/snova-coin-white.svg";
import nPoints from "../../../assets/img/nova-points.svg";
import LoaderIcon from "../../../assets/img/icon.webp";
import Plg from "../../../assets/img/plg-icon.svg";
import Avax from "../../../assets/img/avax-icon.svg";
import Bsc from "../../../assets/img/bsc-icon.svg";
import Arbi from "../../../assets/img/arbitrum-icon.svg";
import Opt from "../../../assets/img/optimism-icon.svg";
import Blast from "../../../assets/img/blast-2.svg";
import zkSync from "../../../assets/img/zksync-2.svg";
import Base from "../../../assets/img/base-icon.svg";
import UsdC from "../../../assets/img/usdc-symbol.webp";
import Dai from "../../../assets/img/Badge_Dai-Bg2iSjNO (1).webp";
import Usd from "../../../assets/img/usd-icon-flag.svg";
import transactionLink from "../../../assets/img/transaction-link.svg";
import claimSuccess from "../../../assets/img/claim-successful.png";
import purchaseError from "../../../assets/img/caution.webp";
import purchaseSummary from "../../../assets/img/purchase-summary-icon.svg";
import transactionError from "../../../assets/img/crossed-circle.svg";
import Tether from "../../../assets/img/tether-icon.webp";
import EthSymbol from "../../../assets/img/eth-symbol.webp";
import BnbSymbol from "../../../assets/img/bnb-symbol.webp";
import MaticSymbol from "../../../assets/img/matic-symbol.webp";
import AvaxSymbol from "../../../assets/img/avax-symbol.webp";
import ArbSymbol from "../../../assets/img/arbitrum-symbol.svg";
import UsdtSymbol from "../../../assets/img/tether-icon.webp";
import UsdcSymbol from "../../../assets/img/usdc-symbol.webp";
import DaiSymbol from "../../../assets/img/dai-symbol.webp";
import Eth from "../../../assets/img/eth-icon.svg";
import Bnb from "../../../assets/img/bnb-symbol.webp";
import Matic from "../../../assets/img/matic-symbol.webp";
import Op from "../../../assets/img/optimism-symbol.svg";
import Zk from "../../../assets/img/zkSync-updated-logo.svg";
import rocketIcon from "../../../assets/img/rocket-icon.svg";
import folderOpen from "../../../assets/img/folder-open.png";

import Highcharts from "highcharts";
import "highcharts/highcharts-3d";
import HighchartsReact from "highcharts-react-official";

import useReferralLink from "../../../hooks/useReferralLink";
import useFetchAllReferralRewards from "../../../hooks/useFetchAllReferralRewards";
import useClaimReferralRewards from "../../../hooks/useClaimReferralRewards";
import useFetchTopUsersByRewards from "../../../hooks/useFetchTopUsersByRewards";
import useFetchReferralTransactions from "../../../hooks/useFetchReferralTransactions";

import {
  formatValue,
  getChainIdFromSelectedCoin,
  formatRanking,
  formatDateOnly,
  formatDateTimeUtc,
  fetchPrices,
} from "../../../utils/helpers";
import { currencyMap, networkMap } from "../../../utils/modalsHelpers";
import networkMapping from "../../../utils/networkMapping";
import { setBlur2 } from "../../../reducers/blurSlice";
import {
  setModal2,
  setModal3,
  setModal5,
  setModal6,
} from "../../../reducers/modalSlice";
import { setDataForModal3 } from "../../../reducers/modal3Slice";

import UserListShimmerPlaceholder from "./shimmers/UserListShimmerPlaceholder";
import ReferralsListShimmerPlaceholder from "./shimmers/ReferralsListShimmerPlaceholder";
import UserListShimmerPlaceholderMobile from "./shimmers/UserListShimmerPlaceholderMobile";
import ReferralsListShimmerPlaceholderMobile from "./shimmers/ReferralsListShimmerPlaceholderMobile";
import RewardsShimmer from "./shimmers/RewardsShimmer";
import KeyPointsShimmer from "./shimmers/KeyPointsShimmer";
import { Tooltip } from "react-tooltip";
import ReactDOM from "react-dom";
import ModalPortal from "../../ModalPortal";

import "./Rank.css";
import "./newRank.css";

// ----------- UTILITY COMPONENTS -----------

// Info Tooltip
const TooltipMain = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div style={{ position: "relative" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="main-tp ab-to">
          <div />
          {text}
        </div>
      )}
    </div>
  );
};

const TooltipMobile = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  // Toggle tooltip on click
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent parents from also handling the click if needed
    setShowTooltip((prev) => !prev);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* The trigger element (icon, text, etc.) */}
      <div
        onClick={handleClick}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>

      {/* The tooltip itself */}
      {showTooltip && <div className="main-tp-mobile">{text}</div>}
    </div>
  );
};

// Disabled Dropdown Tooltip
const TooltipDropdown = ({ text, children, disabled = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => {
    if (disabled) setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div style={{ position: "relative", display: "block" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
        style={{ width: "100%" }}
      >
        {children}
      </div>
      {showTooltip && (
        <div
          className="main-tp"
          style={{ width: "100%", marginBottom: "12px" }}
        >
          <div />
          {text}
        </div>
      )}
    </div>
  );
};

// ----------- CHAIN + TOKEN DATA MAPS -----------
const chainData = {
  eth: {
    name: "Ethereum",
    selectedCoinImg: Eth,
    chainId: 1,
    tokens: [
      { name: "ETH", image: EthDiamond, key: "ETH" },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  bnb: {
    name: "BNB Smart Chain",
    selectedCoinImg: Bsc,
    chainId: 56,
    tokens: [
      {
        name: "BNB",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAFMklEQVR4XtVbvW4TQRhMSWwipYYngCeACsp0pEsJFZTp8gapaXgD6F0gETsIWaRIARIopAAJJAcJCgq4s5M4/zE3l6y1nv253fXe2R5plOR2787f7PfN7u05c3MVIGksLiYbNx92W/XnaavWSFv1TrpZT7Kfg5xXv3fQhj7oi3P4OjOD7ub8ahZUexjguGzW271W/THfZ+qAkYsauMJaI3mzsMz3nThG0rky1jr8OSpHPuJ57fKHq4q1zkRKI9moP5nMqJtYoRBwZ/UDTAdLnzmma9RNLMkb8rRXbjadjF4OfINZIccRhFkaeebYmZCWNMV1390enOy9GAgc7j5T+kRkwnE5oUy3vzxLh8ELXPR/Kv1i0Xt2KCPte1t3OWYtyhLCqxzSyKl//GNdO+omQIQSysKtFGKO/tm/LY5tBEff1wenf17z4REcZ17B1w0llu4cr4I0wuizyelw9ndr2P+iv8fNI4iXDQWLpDRC8KApoMuzZND/uqb0Fzz59ZJPGSJiJphLQdM5mP3dpxxDlhm3lH7M3vs7fNpg/8OS0m8cctw5ktbCMnd0YT9LT8Dk3giIj4EHH5eGAeqEwXm64ygvAcws3O5CrRdc79UpnW1kkzvv7Th9KPYHV9c/+ramzCZBpdGst0eC93V+Dpyxv31POQfEqNrq3OYPmDFsOMxKjs+xsducXx0K4LuPZxKgyOQEdSbpMpI28XwFGMkCpbGALMB5d0c76vAHpCwWQrp6xqgeflpRju9v38/vgTWCrqRgsFwK3gK0JDPkhiKyAEdZgNxHNjnAZJJM2eQEdCJwFgULkLT9H3psAmCki6ALiAXTQe4fQ4D8Iel6Z1dptPEoq3MZsgAuDz3IBlxDnMOCmiB/BlkA/G6abm3Mp8Or11VqowsPPq/kN/cVIBTyvXFfmKLOW1yJ2IPmfyZMSfxelQCnWfD8ObzZrDWC1v+oVz4mWJUAMmGcOl8pZvZwFLLVjZrFqo+Pgy4mGAq+lyCmxBATTPFgpDlYSNm0TNOb7qEmBFhc8bVBni4DBRiMLQCgWweAwiRDgdWhyeSQgTLCBQgsARkmAQSLngEYLsGwsC7naJiXQEfTYKVNAJiR6ckOu0BFwPKYzxM7TPKxOALABAOmwSIBBE5+vxpxZ90mCUO+D2Yb+V5yWxQBMA2GLIRcBQBkk/QVgCG3xRAgXwiFLIV9BABE27QJsL9540H0h6FZEmD4xogbimgTAMTUJe/giOOhAug2RWMIEG0/AGbHfUAx9Ym/fQXAIkg3KyDL4m6IRNoSY9dn+grAxKwAU9XBWwB5Syz/YiN3sNAkgIBp9TaOANgms8FXAOVlqW8WgEUwZQPXLyC/KpOJrXAbcC0+p5CY/xkh06HLEtfkD7JJ6kwOPO9+ka6kQucPLuy9rT3i+HNwRx/a0pv7utIEk2Cu5LiHCHkwkmnKBtGOOjaVBcgbLQyku2+tq6zgDTGyQa5z+fhVIKOvwuQvUMjXEYA/hGx6qiwIHgjxAhMFxN9cJsgG8XJVgM8PMjkDFec3YdxSMJEF0IHPiUeH0RfwfVnqykkK4Dz6AiEPSS607RWaFlDj0vtrcgJlloJskjA53cvVOPRIfR3KKgfQ9C2QWPROexP4wrNCjmMslJkJsRlt5BlphEVS6dy0fA0uBsqaHWIw2O19cV0OHf4AE2M26qWlvQ2TF6LWyXd2J410EiKUXeshwEZDyJsmZzbr7akY8SJc/2NlWwkglFng2Lfk+8wM4M4YuW7T4d/nsz7oW5Wj/wdXGukMTSgLegAAAABJRU5ErkJggg==",
        key: "BNB",
      },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  plg: {
    name: "Polygon",
    selectedCoinImg: Plg,
    chainId: 137,
    tokens: [
      { name: "POL", image: Matic, key: "POL" },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  arbi: {
    name: "Arbitrum One",
    selectedCoinImg: Arbi,
    chainId: 42161,
    tokens: [
      { name: "ARB", image: ArbSymbol, key: "ARB" },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  avax: {
    name: "Avalanche",
    selectedCoinImg: Avax,
    chainId: 43114,
    tokens: [
      {
        name: "AVAX",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACdElEQVR4Xu2bPU7DQBBGuRlrcwTugejgLCBElxZBAQeABiREDzX5awEJIpSAI2Elz9jZ3Zm1dw1Peo298+3MVImibG21xNTkB+MsX9hYnGV9cnAoqcyPFjauLe+LgonJT9locE1+xj46odJYy7Kf1mAjXcv+gsLLY5F9qsMLY5V9q8BLYpf9i2B4KnIOLxiampzHCYalKueyhkHa/sDn2nIuKxiiLeF7bTlfIywOIeH7EHLOWliobR08py3n/BUWhbAJntWW864xzrIHFmi7CZ7XdmTMJecu4WFtbWGdtpy7hAe1tYV12nLuJTykrSus15bz/+0F8KW2vjBH2/8FtLEAX6a7u5UsbYMvYPb0xLmsYVYIl8MPTb7PF1r6Mnt8rGSFcFT8AsWHWkpgVkijWwBzQhtkARKYFdqoFsCcNlRfgARmrfp+e7eYf3xUnktVXcDrYMCZrGHWqi9Hx9ZnXVVdgARmNeXyvUS1BUhglk0uz/na+QKYQ+vgOV9VFiCBWS65PO9j8VH4kA9d9YU5dBOfz8NKjZvZnvjLkARm+eSyzkXxt8G38wv2Yw2zVnWF9baKFyCBWZJc1tsqWoAEZmnkMsfGcgE+S/CFOdQX5mxybfg+LKCAWU1y/iU81OT79Q3vb4T1dfrCnCY5dwkP9lXOXTIx2RUP983Rdn7PuddgQd/kvL/Cor7IOWthYV/knI2wOHU5nxUMSVXOZQ2DUpVzOcGw1OQ8XjA0FTmHCIbHLvtXgZfEKvtWhxfGIvsMCi/vWvbXGmykbdlPJxR/YmRjwd3eOWEfUVBpVFneFy3fzc7ZvMA585NjZIz13+eLs6wPxRfcM7S6mfjOrgAAAABJRU5ErkJggg==",
        key: "AVAX",
      },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  opt: {
    name: "Optimism",
    selectedCoinImg: Opt,
    chainId: 10,
    tokens: [
      { name: "OP", image: Op, key: "OP" },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  base: {
    name: "Base",
    selectedCoinImg: Base,
    chainId: 8453,
    tokens: [
      { name: "ETH", image: EthDiamond, key: "ETH" },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
  zksync: {
    name: "ZKsync Era",
    selectedCoinImg: zkSync,
    chainId: 324,
    tokens: [
      { name: "ZK", image: Zk, key: "ZK" },
      { name: "USDT", image: Tether, key: "USDT" },
      { name: "USDC", image: UsdC, key: "USDC" },
      { name: "DAI", image: Dai, key: "DAI" },
    ],
  },
};

// 1. Given a chainId, returns the matching chainInfo from chainData
function getChainInfoByChainId(chainId) {
  return Object.values(chainData).find((chain) => chain.chainId === chainId);
}

// 2. Given a chainId + currency key (like "USDT"), returns the token object (including the icon)
function getTokenImage(chainId, currencyKey) {
  const chainInfo = getChainInfoByChainId(chainId);
  if (!chainInfo) return null;
  // Look for a token whose "key" matches "ETH", "USDT", etc.
  const tokenData = chainInfo.tokens.find((t) => t.key === currencyKey);
  return tokenData ? tokenData.image : null;
}

// ----------- SMALL HELPER COMPONENT FOR DISPLAYING SELECTED NETWORK TOKEN BLOCKS -----------
function TokenBlock({
  chainInfo,
  allRewards,
  formatValue,
  isClaimingProcess,
  isDesk,
}) {
  const chainId = chainInfo.chainId;
  return (
    <div
      className="responsive-grid mt-[12px] laptop:mt-[16px]"
      style={{ marginBottom: "12px", gap: isDesk ? "16px" : "5px" }}
    >
      {chainInfo.tokens.map(({ name, image, key }) => {
        const reward = allRewards?.[chainId]?.[key] || {
          amount: 0,
          usdValue: 0,
        };
        return (
          <div
            key={key}
            className="animate-ui-element-transition relative flex flex-col justify-center items-center w-full border-b-[1px] border-stroke md:border md:rounded-lg hover:md:shadow-sm confirmation-block-reff"
            style={{
              background: "rgba(0, 0, 0, 0.55)",
              minHeight: "100px",
              padding: isDesk ? "16px" : "0px",
            }}
          >
            {/* Corner borders, but only if we are not claiming */}
            {!isClaimingProcess && (
              <>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
              </>
            )}

            <div className="w-[28px] h-[28px] mb-[8px]">
              <img
                src={image}
                alt={name.toLowerCase()}
                className="w-full h-full object-contain"
              />
            </div>

            <p className="text-[16px] font-semibold text-white">{name}</p>

            <div className="flex items-center justify-center gap-4 w-full text-center">
              <p className="text-[14px] text-white">
                {reward.amount ? formatValue(reward.amount, 4) : "0"}
              </p>
              <p className="text-[14px] text-[#79716B]">
                {"$" + parseFloat(reward.usdValue || 0).toFixed(2)}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

// Helper to create a short referral link
const formatReferralLinkShort = (url) => {
  try {
    const newUrl = new URL(url);
    const refParam = new URLSearchParams(newUrl.search).get("ref");
    if (!refParam) return newUrl.hostname;
    const uniqueRefPart = refParam.split("-")[1];
    return `${newUrl.hostname}...${uniqueRefPart}`;
  } catch (e) {
    console.error("Error formatting URL:", e);
    return "";
  }
};

// Slightly longer version
const formatReferralLinkMedium = (url) => {
  try {
    const newUrl = new URL(url);
    const refParam = new URLSearchParams(newUrl.search).get("ref");
    if (!refParam) return newUrl.hostname;
    return `${newUrl.hostname}/?ref=${refParam}`;
  } catch (e) {
    console.error("Error formatting URL:", e);
    return "";
  }
};

// ----------- EXTRA DASHBOARD COMPONENTS -----------
function ReferralNetworkSummary({
  totalEarnings,
  referralLink,
  handleCopy,
  copied,
  isDesk,
}) {
  const { t } = useTranslation();
  const globalRewardsOfUser = useSelector(
    (state) => state.referralRewards.globalRewardsOfUser
  );

  // Dummy data for demonstration
  // const globalRewardsOfUser = {
  //   1: {
  //     ETH: { amount: 0, usdValue: 0 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 77, usdValue: 77 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  //   56: {
  //     BNB: { amount: 0, usdValue: 0 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 0, usdValue: 0 },
  //     DAI: { amount: 52, usdValue: 52 },
  //   },
  //   137: {
  //     POL: { amount: 88, usdValue: 52 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 0, usdValue: 0 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  //   42161: {
  //     ARB: { amount: 0, usdValue: 0 },
  //     USDT: { amount: 99, usdValue: 99 },
  //     USDC: { amount: 0, usdValue: 0 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  //   43114: {
  //     AVAX: { amount: 3, usdValue: 125 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 0, usdValue: 0 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  //   8453: {
  //     ETH: { amount: 0, usdValue: 0 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 85, usdValue: 85 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  //   10: {
  //     OP: { amount: 400, usdValue: 170 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 0, usdValue: 0 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  //   324: {
  //     ZK: { amount: 200, usdValue: 50 },
  //     USDT: { amount: 0, usdValue: 0 },
  //     USDC: { amount: 0, usdValue: 0 },
  //     DAI: { amount: 0, usdValue: 0 },
  //   },
  // };

  const chainIdToNameMap = {
    1: "Ethereum",
    56: "BNB Chain",
    137: "Polygon",
    42161: "Arbitrum One",
    43114: "Avalanche",
    8453: "Base",
    10: "Optimism",
    324: "ZKsync Era",
  };

  let breakdown = [];
  if (
    globalRewardsOfUser &&
    Object.keys(globalRewardsOfUser).length > 0 &&
    totalEarnings > 0
  ) {
    for (let chainId in globalRewardsOfUser) {
      const networkRewards = globalRewardsOfUser[chainId];
      let sumUsd = 0;
      for (let token in networkRewards) {
        sumUsd += parseFloat(networkRewards[token].usdValue || 0);
      }
      if (sumUsd > 0) {
        breakdown.push({
          network: chainIdToNameMap[chainId] || "Unknown",
          earnings: sumUsd,
        });
      }
    }
  }

  const isEmptyState =
    !totalEarnings || totalEarnings <= 0 || breakdown.length === 0;

  // Sort breakdown in a user-friendly order
  const networkOrder = [
    "Ethereum",
    "BNB Chain",
    "Polygon",
    "Arbitrum One",
    "Avalanche",
    "Optimism",
    "Base",
    "ZKsync Era",
  ];

  breakdown.sort(
    (a, b) => networkOrder.indexOf(a.network) - networkOrder.indexOf(b.network)
  );

  // Brand-like colors for each chain, in matching order:
  const colors = [
    "#627EEA", // Ethereum
    "#F0B90B", // BNB Chain
    "#8247E5", // Polygon
    "#28A0F0", // Arbitrum
    "#E84142", // Avalanche
    "#FF0420", // Optimism
    "#0052FF", // Base
    "#8C8DFB", // ZKsync
  ];

  const chartData = breakdown.map((b, i) => ({
    name: b.network,
    y: b.earnings,
    color: colors[i],
  }));

  const options = {
    chart: {
      type: "pie",
      backgroundColor: "#1c1c1c",
      options3d: {
        enabled: true,
        alpha: 35,
        beta: 0,
        depth: 10,
        viewDistance: 50,
      },
      style: {
        fontFamily: "'Matter Mono', monospace",
      },
    },
    title: {
      text: null,
    },
    tooltip: {
      backgroundColor: "#2f2f2f",
      style: { color: "#fff" },
      formatter: function () {
        return `${this.point.name}: <b>${this.point.percentage.toFixed(
          1
        )}%</b> ($${this.point.y.toFixed(2)})`;
      },
    },
    plotOptions: {
      pie: {
        size: "70%",
        innerSize: "50%",
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        center: ["50%", "50%"],
        borderColor: "#ac172f",
        borderWidth: 20,
        dataLabels: {
          enabled: true,
          color: "#fff",
          connectorColor: "#fff",
          format: "<b>{point.name}</b>: {point.percentage:.1f}%",
          style: { fontSize: "12px" },
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: true,
            brightness: -0.2,
            borderColor: "#666666",
            borderWidth: 3,
          },
        },
      },
    },
    accessibility: { enabled: false },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "pie",
        data: chartData,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div
      className="referral-network-summary"
      style={{
        marginTop: "50px",
        padding: "20px",
        background: "#1c1c1c",
        borderRadius: "10px",
      }}
    >
      <h1 className="text-simplre bbtm laptop:text-[42px] mb-[12px] laptop:mb-[16px] leading-[120%] font-weight-bold relative">
        {t("referralPage.summary.networkSummaryTitle")}
      </h1>
      {isEmptyState ? (
        <div
          className="empty-state text-center flex justify-center items-center"
          style={{
            padding: "30px",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: "10px",
            height: "414px",
          }}
        >
          <div className="flex flex-col items-center">
            <img
              src={folderOpen}
              alt={t("referralPage.summary.noDataAlt")}
              style={{
                width: "38px",
                height: "38px",
                marginBottom: "12px",
              }}
            />
            <p
              className="primary-text font-semibold mb-2 text-lg"
              style={{ color: "#fff", fontSize: "17px" }}
            >
              {t("referralPage.summary.noReferralData")}
            </p>
            <p
              className="secondary-text text-sm text-gray-300"
              style={{ fontSize: "14px", color: "#8D8D8D" }}
            >
              {t("referralPage.summary.shareLinkToTrack")}
            </p>
            {referralLink && (
              <div
                className="_copyWrapper_1fjrj_1"
                aria-label={t("referralPage.banner.copiedToClipboard")}
              >
                <span className="_copyText_1fjrj_14">
                  {isDesk
                    ? referralLink
                      ? referralLink
                      : ""
                    : referralLink
                    ? formatReferralLinkShort(referralLink)
                    : "" || t("referralPage.summary.loading")}
                </span>
                {copied ? (
                  <div className="_copyBtn_1fjrj_20">
                    <img src={done} alt={t("referralPage.summary.copied")} />
                  </div>
                ) : (
                  referralLink && (
                    <button
                      className="_copyBtn_1fjrj_20"
                      onClick={() => handleCopy(referralLink)}
                    >
                      <img src={copy} alt={t("referralPage.summary.copy")} />
                    </button>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <h3
            style={{
              textAlign: "center",
              color: "#fff",
              marginBottom: "8px",
              fontSize: "17px",
              fontFamily: "'Matter Mono', monospace",
              fontWeight: "bold",
            }}
          >
            {t("referralPage.summary.totalEarnings")}: $
            {totalEarnings.toFixed(2)}
          </h3>
          <div style={{ width: "100%", height: "381px", position: "relative" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </>
      )}
    </div>
  );
}

// ----------- MAIN RANK COMPONENT -----------
const Rank = () => {
  const [staticCondition, setStaticCondition] = useState(false);
  const [isDesk, setIsDesk] = useState(window.innerWidth > 768);
  const [TopEarning, setTopEarning] = useState(true);

  const [topEarningPage, setTopEarningPage] = useState(1);
  const [referralsPage, setReferralsPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const handleResize = () => {
      setIsDesk(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isBlurred2 = useSelector((state) => state.blur.isBlurred2);
  const { modal2, modal3, modal5, modal6 } = useSelector(
    (state) => state.modals
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [drop, setdrop] = useState(false);
  const [isTab, setisTab] = useState(false);
  const [selectedCoin, setselectedCoin] = useState("");

  // Web3 & Referral logic
  const { isConnected, chainId, address } = useWeb3ModalAccount();

  const { rewards } = useSelector((state) => state.referralRewards);
  const { allRewards } = useFetchAllReferralRewards(address);
  const { claimRewards } = useClaimReferralRewards();
  const referralCount = useSelector((state) => state.referralCount.count);
  const novaPoints = useSelector((state) => state.amounts.novaPoints);
  const totalReferralRewardsInDollars = useSelector(
    (state) => state.totalReferralRewards.totalReferralRewardsInDollars
  );
  const pendingReferralRewardsInDollars = useSelector(
    (state) => state.totalReferralRewards.pendingReferralRewardsInDollars
  );
  const referralTransactionsData = useSelector(
    (state) => state.referralRewards.referralTransactionsData
  );
  const userRankingByRewards = useSelector(
    (state) => state.userRankingByRewards.ranking
  );

  const [prices, setPrices] = useState(null);
  useEffect(() => {
    // Wrap your async call in an inner function
    async function getPrices() {
      try {
        const data = await fetchPrices();
        setPrices(data); // store in local state
      } catch (err) {
        console.error("Failed to fetch prices:", err);
      }
    }
    getPrices();
  }, []);

  const {
    referralLink,
    copied,
    error,
    handleCopy,
    loading: referralLoading,
  } = useReferralLink(address, isConnected);

  // For the claim modals
  const totalNetworkReward = useSelector(
    (state) => state.modal3.totalNetworkReward
  );
  const amounts = useSelector((state) => state.modal3.amounts);

  // Fetch top users from Redux (the older approach for "Top Earners")
  useFetchTopUsersByRewards();
  const topUsers = useSelector((state) => state.userRankings.topUsers);

  // "My Referrals" from the new endpoint
  // useFetchReferralTransactions(address);

  // At the top (outside or above your component):
  function getPaginationPages(currentPage, totalPages) {
    // If total pages are <= 3, just show them all
    if (totalPages <= 3) {
      return Array.from({ length: totalPages }, (_, i) => i + 1); // [1..totalPages]
    }

    // Otherwise, try to keep currentPage in the middle
    let start = currentPage - 1;
    let end = currentPage + 1;

    // Clamp lower boundary
    if (start < 1) {
      start = 1;
      end = 3;
    }
    // Clamp upper boundary
    if (end > totalPages) {
      end = totalPages;
      start = totalPages - 2;
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  const displayShortLink = referralLink
    ? formatReferralLinkShort(referralLink)
    : "";
  const displayMediumLink = referralLink
    ? formatReferralLinkMedium(referralLink)
    : "";

  const dropdownRef = useRef(null);
  const toggleDrop = () => {
    setdrop(!drop);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdrop(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  // Format data for Claim
  const coinsMapping = {
    eth: { chainId: 1, coinName: "ETH" },
    bnb: { chainId: 56, coinName: "BNB" },
    plg: { chainId: 137, coinName: "POL" },
    avax: { chainId: 43114, coinName: "AVAX" },
    arbi: { chainId: 42161, coinName: "ARB" },
    opt: { chainId: 10, coinName: "OP" },
    base: { chainId: 8453, coinName: "ETH" },
    zksync: { chainId: 324, coinName: "ZK" },
  };

  const formatRewardData = (chainId, allRewards, coinName) => {
    const coinData = allRewards[chainId][coinName];
    return {
      amount: formatValue(coinData.amount, 4),
      usdValue: parseFloat(coinData.usdValue).toFixed(2),
    };
  };

  const formatTotalNetworkRewardInUSD = (chainId) => {
    if (allRewards && allRewards[chainId]) {
      const rewardInUsd = Object.values(allRewards[chainId]).reduce(
        (acc, { usdValue }) => acc + parseFloat(usdValue),
        0
      );
      return `$${rewardInUsd.toFixed(2)}`;
    }
    return "$0";
  };

  useEffect(() => {
    if (!selectedCoin || !allRewards) return;
    const { chainId, coinName } = coinsMapping[selectedCoin] || {};
    if (!chainId || !coinName) return;
    const totalNetworkReward = formatTotalNetworkRewardInUSD(chainId);
    const coinReward = formatRewardData(chainId, allRewards, coinName);
    const usdtReward = formatRewardData(chainId, allRewards, "USDT");
    const usdcReward = formatRewardData(chainId, allRewards, "USDC");
    const daiReward = formatRewardData(chainId, allRewards, "DAI");
    const amounts = {
      main: coinReward,
      usdt: usdtReward,
      usdc: usdcReward,
      dai: daiReward,
    };
    dispatch(
      setDataForModal3({ network: chainId, totalNetworkReward, amounts })
    );
  }, [selectedCoin, allRewards, dispatch]);

  // Claim Handler
  const handleClaim = async () => {
    try {
      await claimRewards(getChainIdFromSelectedCoin(selectedCoin), {
        totalNetworkReward,
        amounts,
      });
    } catch (error) {
      // Map errors to your modals
      if (
        error.code === 4001 ||
        (error.code === undefined && error.message.includes("User rejected")) ||
        error.message.includes("rejected") ||
        error.message.includes("declined") ||
        error.message.includes("cancelled")
      ) {
        dispatch(
          setModal5({
            isOpen: true,
            data: { error: "Transaction was declined by the user." },
          })
        );
      } else if (
        (error.code === -32000 &&
          (error.message.includes("intrinsic gas too low") ||
            error.message.includes("gas required exceeds allowance") ||
            error.message.includes("out of gas") ||
            error.message.includes("exceeds gas limit"))) ||
        error.message.includes("gas too low")
      ) {
        dispatch(setModal2({ isOpen: true, data: { error: error.message } }));
      } else {
        dispatch(setModal6({ isOpen: true, data: { error: error.message } }));
      }
    }
  };

  // Modal toggles
  const toggleError = () => {
    dispatch(setModal2({ ...modal2, isOpen: !modal2.isOpen }));
  };
  const toggleUniversalError = () => {
    dispatch(setModal6({ ...modal6, isOpen: !modal6.isOpen }));
  };
  const toggleDeclined = () => {
    dispatch(setModal5({ ...modal5, isOpen: !modal5.isOpen }));
  };
  const toggleSuccess = () => {
    dispatch(setModal3({ ...modal3, isOpen: !modal3.isOpen }));
  };

  // FAQ logic
  const faqData = [
    {
      question: t("referralPage.faq.question1"),
      answer: t("referralPage.faq.answer1"),
    },
    {
      question: t("referralPage.faq.question2"),
      answer: t("referralPage.faq.answer2"),
    },
    {
      question: t("referralPage.faq.question3"),
      answer: t("referralPage.faq.answer3"),
    },
    {
      question: t("referralPage.faq.question4"),
      answer: t("referralPage.faq.answer4"),
    },
    {
      question: t("referralPage.faq.question5"),
      answer: t("referralPage.faq.answer5"),
    },
  ];
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  let explorerUrl, txnHash;
  if (modal3.data) {
    const networkExplorer = networkMapping[modal3.data.network];
    if (networkExplorer) {
      explorerUrl = networkExplorer.explorer;
    }
    txnHash = modal3.data.transactionHash;
  }

  const allNetworksNoRewards = allRewards
    ? Object.keys(chainData).every((key) => {
        const cId = chainData[key].chainId;
        if (!allRewards[cId]) return true;
        return Object.values(allRewards[cId]).every(
          (r) => parseFloat(r.usdValue) === 0
        );
      })
    : true;

  // For claim UI
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [isClaimingProcess, setIsClaimingProcess] = useState(false);

  const hasNoRewards = (() => {
    if (!selectedCoin || !allRewards) return true;
    const networkId = getChainIdFromSelectedCoin(selectedCoin);
    if (!networkId || !allRewards[networkId]) return true;
    return Object.values(allRewards[networkId]).every(
      (currency) => Number(currency.amount) === 0
    );
  })();

  const selectTab = (coin) => {
    setdrop(false);
    setisTab(true);
    setShowClaimModal(true);
    if (coin !== selectedCoin) {
      setselectedCoin(coin);
    }
  };

  // useEffect(() => {
  //   if (selectedCoin && allRewards && !hasNoRewards) {
  //     setShowClaimModal(true);
  //   } else {
  //     setShowClaimModal(false);
  //   }
  // }, [selectedCoin, allRewards, hasNoRewards]);

  if (!isConnected) {
    return (
      <div className="topmara now-flex grid grid-cols-1 desktop:grid-cols-2 gap-[48px] tablet:gap-[80px] mt-[20px] laptop:mt-[64px] text-white pb-[48px] laptop:pb-[180px]"></div>
    );
  }

  // MY REFERRALS: flatten each transaction row
  function flattenAllTransactions(referralTransactionsData) {
    if (!referralTransactionsData) return [];
    const allTx = [];
    referralTransactionsData.referralTransactions.forEach((refItem) => {
      refItem.transactions.forEach((tx, idx) => {
        allTx.push({
          index: idx,
          referralAddress: refItem.referralAddress,
          purchaseAmount: tx.purchase_amount,
          network: tx.network,
          currency: tx.currency,
          rewardsUsd: (tx.purchase_amount_dollar ?? 0) * 0.2,

          // [ADDED FIELDS]
          isClaimed: tx.isClaimed || false,
          claimedTxHash: tx.claimedTxHash || null,
          claimedPrice: tx.claimedPrice || 0,
          claimedAt: tx.claimedAt || null,
          cryptoRewards: tx.cryptoRewards || 0,
          snovaRewards: tx.snovaRewards || 0,
          novaRewards: tx.novaRewards || 0,
        });
      });
    });
    return allTx;
  }

  const chainInfo = selectedCoin && chainData[selectedCoin];

  const topEarningTotalPages = topUsers
    ? Math.ceil(topUsers.length / itemsPerPage)
    : 0;

  const topEarningPageData = topUsers
    ? topUsers.slice(
        (topEarningPage - 1) * itemsPerPage,
        topEarningPage * itemsPerPage
      )
    : [];

  const myReferralsData = referralTransactionsData
    ? flattenAllTransactions(referralTransactionsData)
    : [];
  const pendingReferralCount = myReferralsData.filter(
    (tx) => !tx.isClaimed
  ).length;

  const myReferralsTotalPages = Math.ceil(
    myReferralsData.length / itemsPerPage
  );
  const myReferralsPageData = myReferralsData.slice(
    (referralsPage - 1) * itemsPerPage,
    referralsPage * itemsPerPage
  );

  // For blockies of current user (just if needed)
  const iconForCurrentUser = blockies.create({
    seed: address.toLowerCase(),
    size: 8,
    scale: 4,
    color: "#FC6432",
    bgcolor: "#3B334C",
    spotcolor: "#C04000",
  });
  const iconDataURL = iconForCurrentUser.toDataURL();

  return (
    <>
      <div className="sc-iaFMSK fkVKpM">
        <div className="sc-jgyNOb lfUtvf">
          <div className="sc-cgFNHA dpCZJJ" style={{ paddingTop: "95px" }}>
            {/* -------- LEFT COLUMN: INSIGHTS -------- */}
            <div className="sc-jDUNyp gCXHAi custom-ui-container">
              <h1 className="referral-head">
                {t("referralPage.headings.referalInsight")}
              </h1>
              {staticCondition ? (
                <KeyPointsShimmer />
              ) : (
                <div className="container-content flex flex-col gap-3 lg:flex-row lg:gap-5">
                  {/* Total Earnings */}
                  {totalReferralRewardsInDollars !== null ? (
                    <div className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto">
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                      <img
                        src={totalEarnings}
                        style={{ height: "36px" }}
                        alt=""
                      />
                      <p className="font-normal typography-caption1">
                        {t("referralPage.rank.totalEarnings")}
                      </p>
                      <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                        <span className="truncate">
                          {totalReferralRewardsInDollars
                            ? "$" + totalReferralRewardsInDollars.toFixed(2)
                            : "$0"}
                        </span>
                      </h3>
                    </div>
                  ) : (
                    <div className="shimmer-item">
                      <span className="shimmer shimmer-title" />
                      <span className="shimmer shimmer-text" />
                    </div>
                  )}

                  {/* Pending Rewards */}
                  {pendingReferralRewardsInDollars !== null ? (
                    <div className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto">
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                      <img
                        src={pendingRewards}
                        style={{ height: "36px" }}
                        alt=""
                      />
                      <p className="font-normal typography-caption1">
                        {t("referralPage.rank.pendingRewards")}
                      </p>
                      <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                        <span className="truncate">
                          {pendingReferralRewardsInDollars
                            ? "$" + pendingReferralRewardsInDollars.toFixed(2)
                            : "$0"}
                        </span>
                      </h3>
                    </div>
                  ) : (
                    <div className="shimmer-item">
                      <span className="shimmer shimmer-title" />
                      <span className="shimmer shimmer-text" />
                    </div>
                  )}

                  {/* SNOVA Earned */}
                  {rewards?.SNOVA !== null ? (
                    <div className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto">
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                      <img src={snovaCoin} style={{ height: "36px" }} alt="" />
                      <p className="font-normal typography-caption1">
                        {t("referralPage.rank.snovaEarned")}
                      </p>
                      <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                        <span className="truncate">
                          {rewards.SNOVA
                            ? "+" + formatValue(rewards.SNOVA, 2) + " SNOVA"
                            : "+0 SNOVA"}
                        </span>
                      </h3>
                    </div>
                  ) : (
                    <div className="shimmer-item">
                      <span className="shimmer shimmer-title" />
                      <span className="shimmer shimmer-text" />
                    </div>
                  )}

                  {/* Nova Points */}
                  {novaPoints !== null ? (
                    <div className="animate-ui-element-transition flex flex-col flex-1 gap-1 px-0 pb-4 cursor-pointer border-b-[1px] border-stroke md:border md:rounded-lg md:px-5 md:py-4 hover:md:shadow-sm md:w-auto">
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                      <img src={nPoints} style={{ height: "36px" }} alt="" />
                      <p className="font-normal typography-caption1">
                        {t("referralPage.rank.pointsEarned")}
                      </p>
                      <h3 className="font-semibold typography-h3 flex items-baseline gap-[6px] truncate">
                        <span className="truncate">
                          {novaPoints
                            ? "+" + formatValue(novaPoints, 2)
                            : "+0 "}
                          {t("homePage.novaBox.novaPoints")}
                        </span>
                      </h3>
                    </div>
                  ) : (
                    <div className="shimmer-item">
                      <span className="shimmer shimmer-title" />
                      <div className="flex items-center gap-[8px] mt-[16px]">
                        <span className="shimmer shimmer-icon" />
                        <span className="shimmer shimmer-text" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Quick Steps or instructions */}
              <div className="text-left left-cont">
                <ol className="relative flex flex-col gap-10 mb-6 border-[#301748] border-s">
                  <li className="flex ms-6">
                    <span className="absolute top-6 flex items-center justify-center w-9 h-9 text-gray-300 bg-[#301748] rounded-full -start-5 ring-2 ring-white dark:ring-[#3B334C] num -left-[1.2em]">
                      1
                    </span>
                    <span className="flex flex-col text-left">
                      <h3 className="text-lg font-bold leading-tight text-white">
                        {t("referralPage.roadMap.readyToShare")}
                      </h3>
                      <p className="-mt-1 text-gray-400 text-md">
                        {t("referralPage.roadMap.readyToShareDescription")}
                      </p>
                    </span>
                  </li>
                  <li className="flex ms-6">
                    <span className="absolute top-6 flex items-center justify-center w-9 h-9 text-gray-300 bg-[#301748] rounded-full -start-5 ring-2 ring-white dark:ring-[#3B334C] num -left-[1.2em]">
                      2
                    </span>
                    <span className="flex flex-col text-left">
                      <h3 className="text-lg font-bold leading-tight text-white">
                        {t("referralPage.roadMap.wantToEarnBig")}
                      </h3>
                      <p className="-mt-1 text-gray-400 text-md">
                        {t("referralPage.roadMap.wantToEarnBigDescription")}
                      </p>
                    </span>
                  </li>
                  <li className="flex ms-6">
                    <span className="absolute top-6 flex items-center justify-center w-9 h-9 text-gray-300 bg-[#301748] rounded-full -start-5 ring-2 ring-white dark:ring-[#3B334C] num -left-[1.2em]">
                      3
                    </span>
                    <span className="flex flex-col text-left">
                      <h3 className="text-lg font-bold leading-tight text-white">
                        {t("referralPage.roadMap.startEarlyEarnMore")}
                      </h3>
                      <p className="-mt-1 text-gray-400 text-md">
                        {t(
                          "referralPage.roadMap.startEarlyEarnMoreDescription"
                        )}
                      </p>
                    </span>
                  </li>
                </ol>
              </div>

              {/* FAQ block */}
              <div
                className="sc-gikAfH sc-HLtgk desktop-faq  jHniMi eIIHDG"
                style={{
                  backgroundColor: "rgb(28, 28, 28)",
                  border: "1px solid rgb(80, 80, 80)",
                  borderRadius: 16,
                  overflow: "hidden",
                  color: "rgb(169, 169, 169)",
                  padding: "12px 16px",
                }}
              >
                <div
                  className="sc-gswNZR sc-iBYQkv sc-ftTHYK sc-ifasWZ gIgClI cuevGy zgtPq ggFMkW"
                  style={{
                    backgroundColor: "rgb(28, 28, 28)",
                    cursor: "default",
                  }}
                >
                  <div
                    className="css-dzm8si ftsize"
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("Staking.banner.faqs")}
                  </div>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    <path
                      d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                      fill="#fc6432"
                    />
                  </svg>
                </div>
                <div
                  className="sc-gVzlyS gwRDJF mini-faq"
                  style={{
                    opacity: isOpen ? 1 : 0,
                    overflow: "hidden",
                    transition: "max-height 0.3s ease, opacity 0.3s ease",
                  }}
                >
                  <div style={{ marginTop: "12px" }} />
                  <div className="faq-map">
                    {faqData.map((item, index) => (
                      <div
                        key={index}
                        className="faq-item faq-slide ui-flex ui-w-full ui-flex-col ui-hover ui-border-x-0 ui-border-solid ui-border-[#131314] ui-pb-4 ui-pr-6 ui-pt-4 ui-border-y"
                      >
                        <div
                          onClick={() => toggleFAQ(index)}
                          className={`${
                            activeIndex === index ? "orange-color" : ""
                          } faq-question ui-space-between ui-flex ui-w-full ui-cursor-pointer ui-items-center ui-fill-txt-2 ui-py-2 ui-transition-all hover:ui-fill-primary hover:ui-text-primary`}
                        >
                          <div className="b1sb ui-flex-1">{item.question}</div>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`ui-items-center ui-justify-center ${
                              activeIndex === index ? "" : "ui-rotate-180"
                            }`}
                          >
                            <path
                              d="M7.5 13.1786L7.5 15L12 15L16.5 15L16.5 13.1786L12 9L7.5 13.1786Z"
                              fill={`${
                                activeIndex === index ? "#fc6432" : "white"
                              }`}
                            ></path>
                          </svg>
                        </div>
                        <div
                          className={`faq-answer b2 ui-max-w-[850px] ui-transition-all ${
                            activeIndex === index
                              ? "ui-block ui-h-auto ui-pt-2 ui-opacity-100"
                              : "ui-hidden ui-h-0 ui-pt-0 ui-opacity-0"
                          }`}
                        >
                          {item.answer}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* -------- RIGHT COLUMN: REFERRAL CLAIM / SUMMARY -------- */}
            <div className="sc-ePVRMS kWWrlt">
              <h1 className="referral-head">
                {t("referralPage.headings.manageReferal")}
              </h1>
              {staticCondition ? (
                <RewardsShimmer />
              ) : (
                <div
                  className="laptop:p-[24px] tablet:backdrop-blur-[12px]"
                  style={{
                    background: "rgb(28, 28, 28)",
                    borderRadius: "10px",
                    padding: "30px",
                  }}
                >
                  <div className="relative">
                    <h1 className="text-simplre bbtm laptop:text-[42px] mb-[12px] laptop:mb-[16px] leading-[120%] font-weight-bold relative">
                      {t("referralPage.rank.claimReward")}
                    </h1>
                  </div>
                  <p className="text-14px laptop:text-[16px] text-[#D6D6D6] mb-[20px] laptop:mb-[32px] leading-[150%]">
                    {t("referralPage.rank.rewardDescription")}
                  </p>

                  {/* Referral link + referees count */}
                  {referralLink && referralCount !== null ? (
                    <div className="confo gap-[8px] laptop:gap-[0px] flex-col mobile:flex-row flex mb-[20px] tablet:mb-[42px]">
                      <div
                        className="py-[11px] px-[17px] relative"
                        style={{
                          background: "#0000008c",
                          flex: "0 1 100%",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="inline-flex items-center mb-[4px]">
                          <span className="mr-[6px] text-[#79716B] text-[14px] font-[500] leading-[170%]">
                            {t("referralPage.rank.yourReferralLink")}
                          </span>
                          {copied ? (
                            <div
                              style={{ marginLeft: "0" }}
                              className="_copyBtn_1fjrj_201"
                            >
                              <img src={done} className="don-ing" alt="Done" />
                            </div>
                          ) : (
                            <div
                              className="cursor-pointer"
                              onClick={() => handleCopy(referralLink)}
                            >
                              {/* <TooltipMain text={displayMediumLink}>
                                <img
                                  src={CircleInfo}
                                  style={{ height: "16px" }}
                                  alt=""
                                />
                              </TooltipMain> */}
                            </div>
                          )}
                        </div>
                        <div
                          className="_copyWrapper_1fjrj_1"
                          aria-label={t(
                            "referralPage.banner.copiedToClipboard"
                          )}
                        >
                          {isConnected && referralLink ? (
                            <>
                              <span className="_copyText_1fjrj_14">
                                {isDesk
                                  ? referralLink
                                  : displayShortLink || "Loading..."}
                              </span>
                              {copied ? (
                                <div
                                  className="_copyBtn_1fjrj_20"
                                  onClick={() => handleCopy(referralLink)}
                                >
                                  <img src={done} alt="Copied" />
                                </div>
                              ) : (
                                <div
                                  className="_copyBtn_1fjrj_20"
                                  onClick={() => handleCopy(referralLink)}
                                >
                                  <img src={copy} alt="Copy" />
                                </div>
                              )}
                            </>
                          ) : (
                            <span className="_copyText_1fjrj_14">
                              {error || "No referral link available"}
                            </span>
                          )}
                        </div>
                        <div className="w-[1px] h-[75%] border border-solid border-[#110f1652] absolute right-[0] top-[12px] bottom-[12px]" />
                      </div>
                      {/* <div
                        className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                        style={{ background: "#0000008c" }}
                        >
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <span className="text-[#79716B] text-[14px] font-[500] leading-[170%]">
                            {t("referralPage.rank.yourReferees")}
                          </span>
                          <div className="cursor-pointer">
                            <TooltipMain text={t("toolTips.investorReferral")}>
                              <img
                                src={CircleInfo}
                                style={{ height: "16px" }}
                                alt=""
                              />
                            </TooltipMain>
                          </div>
                        </div>
                        <span className="text-white text-[20px] font-[500] leading-[140%]">
                          {referralCount ? referralCount : "0"}
                        </span>
                      </div> */}
                    </div>
                  ) : (
                    <div className=" my-shimmer-effect-container shimmer confo gap-[8px] laptop:gap-[0px] flex-col mobile:flex-row flex mb-[20px] tablet:mb-[42px]">
                      <div
                        className="flex-[0_1_50%] py-[11px] px-[17px] relative"
                        style={{
                          background: "#0000008c",
                          flex: "0 1 100%",
                        }}
                      >
                        <div className="inline-flex items-center mb-[4px]" />
                        <div
                          className="_copyWrapper_1fjrj_1"
                          style={{ opacity: 0 }}
                        />
                        <div className="shimmer-divider" />
                      </div>
                      {/* <div
                        className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                        style={{ background: "#0000008c" }}
                      >
                        <div className="inline-flex items-center" />
                      </div> */}
                    </div>
                  )}

                  {/* Claim Network Selection */}
                  <div style={{ position: "relative" }}>
                    <div
                      className={` ${
                        isBlurred2 ? "blur-none relative" : "relative"
                      }`}
                    >
                      {allRewards === undefined ? (
                        <div
                          className={`my-shimmer-effect-container blur-area ${
                            isBlurred2 ? "blur-comp" : ""
                          }`}
                        >
                          <div
                            ref={dropdownRef}
                            className="shimmer relative MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth stswap-select css-xa1gac"
                          >
                            <div className="css-qiwgdb"></div>
                          </div>
                        </div>
                      ) : (
                        <TooltipDropdown
                          text="No claimable rewards at this time. Check back later!"
                          disabled={allNetworksNoRewards}
                        >
                          <div
                            className={`blur-area ${
                              isBlurred2 ? "blur-comp" : ""
                            }`}
                          >
                            <div
                              ref={dropdownRef}
                              className="relative MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth stswap-select css-xa1gac"
                            >
                              <div
                                tabIndex={0}
                                role="combobox"
                                onClick={
                                  allNetworksNoRewards ? undefined : toggleDrop
                                }
                                aria-controls=":r2:"
                                aria-expanded="false"
                                aria-haspopup="listbox"
                                aria-label="Without label"
                                className={`MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-qiwgdb select-dropdown ${
                                  drop ? "active" : ""
                                } ${allNetworksNoRewards ? "disabled" : ""}`}
                              >
                                {allNetworksNoRewards ? (
                                  <span style={{ opacity: 0.7 }}>
                                    {t("referralPage.rank.selectNetwork")}
                                  </span>
                                ) : isTab &&
                                  allRewards &&
                                  chainData[selectedCoin] ? (
                                  <div className="flex items-center">
                                    <div className="w-[28px] h-[28px] relative mr-[28px]">
                                      <img
                                        src={
                                          chainData[selectedCoin]
                                            .selectedCoinImg
                                        }
                                        alt="selectedCoin"
                                      />
                                    </div>
                                    <div className="text-[#EEF2F6] mr-[40px]">
                                      {chainData[selectedCoin].name}
                                    </div>
                                    <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono">
                                      {formatTotalNetworkRewardInUSD(
                                        chainData[selectedCoin].chainId
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <em>
                                    {t("referralPage.rank.selectNetwork")}
                                  </em>
                                )}
                              </div>

                              <input
                                aria-invalid="false"
                                aria-hidden="true"
                                tabIndex={-1}
                                className="MuiSelect-nativeInput css-1k3x8v3"
                              />
                              <svg
                                style={
                                  allNetworksNoRewards
                                    ? {
                                        transform: drop
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)",
                                        transition: "all 0.3s ease",
                                        fill: "#57534e",
                                      }
                                    : {
                                        transform: drop
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)",
                                        transition: "all 0.3s ease",
                                        fill: "#fc6432",
                                      }
                                }
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="ArrowDropDownIcon"
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </svg>

                              <fieldset
                                aria-hidden="true"
                                className="MuiOutlinedInput-notchedOutline css-igs3ac"
                              >
                                <legend className="css-ihdtdm">
                                  <span className="notranslate">​</span>
                                </legend>
                              </fieldset>

                              {drop && !allNetworksNoRewards && (
                                <div
                                  className="MuiPaper-root absas MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper MuiMenu-paper MuiMenu-paper css-pwxzbm"
                                  tabIndex={-1}
                                  style={{ boxShadow: "none" }}
                                >
                                  <ul
                                    className="MuiList-root MuiList-padding MuiMenu-list css-r8u8y9"
                                    role="listbox"
                                    tabIndex={-1}
                                    id=":r2:"
                                  >
                                    <li
                                      className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters Mui-selected MuiMenuItem-root MuiMenuItem-gutters Mui-selected stswap-select_placeholder css-1km1ehz"
                                      tabIndex={0}
                                      role="option"
                                      aria-selected="true"
                                      data-value
                                    >
                                      <em>Select Chain</em>
                                      <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </li>
                                    {Object.keys(chainData).map((key) => {
                                      const info = chainData[key];
                                      const rewardInUsd = allRewards?.[
                                        info.chainId
                                      ]
                                        ? Object.values(
                                            allRewards[info.chainId]
                                          ).reduce(
                                            (acc, r) =>
                                              acc + parseFloat(r.usdValue),
                                            0
                                          )
                                        : 0;

                                      const disabledItem = rewardInUsd <= 0;
                                      return (
                                        <li
                                          key={key}
                                          className={`hover-tab MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-1km1ehz ${
                                            selectedCoin === key
                                              ? "selectedli"
                                              : ""
                                          } ${
                                            disabledItem ? "disabledItem" : ""
                                          }`}
                                          tabIndex={-1}
                                          role="option"
                                          aria-selected="false"
                                          onClick={
                                            disabledItem
                                              ? undefined
                                              : () => selectTab(key)
                                          }
                                        >
                                          <div className="flex items-center">
                                            <div className="w-[28px] h-[28px] relative mr-[28px] network-icon">
                                              <img
                                                src={info.selectedCoinImg}
                                                alt={key}
                                              />
                                            </div>
                                            <div className="text-[#EEF2F6] mr-[40px] network-icon">
                                              {info.name}
                                            </div>
                                            <div className="mr-[40px] text-[#D7D3D0] font-DM_Mono reward-amount">
                                              ${rewardInUsd.toFixed(2)}
                                            </div>
                                          </div>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </TooltipDropdown>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <ReferralNetworkSummary
                totalEarnings={totalReferralRewardsInDollars || 0}
                referralLink={referralLink}
                handleCopy={handleCopy}
                copied={copied}
                isDesk={isDesk}
              />
            </div>
          </div>
        </div>

        {/* -------- BOTTOM: TOP EARNERS vs MY REFERRALS -------- */}
        <div
          className="user-list rank-updated  main-nets padding220"
          style={{ width: "100%" }}
        >
          <h1 className="referral-head">
            {t("referralPage.referalActivity.heading")}
          </h1>
          <div className="dab-k no-dbk">
            <div className="ant-space-item mbtool">
              <div className="ant-flex bjKEmbw css-dev-only-do-not-override-1q8nizr ant-flex-align-end ant-flex-justify-space-between">
                <div className="css-lb1r9s justify-center dir-mbl">
                  <div
                    onClick={() => setTopEarning(true)}
                    className={`${
                      TopEarning ? "active" : "hover-one "
                    }  cnt-width css-grbf26 relative`}
                  >
                    <div className="css-gmuwbf">
                      {TopEarning && (
                        <>
                          {" "}
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                        </>
                      )}
                      <p className="text-[16px] flex items-center font-[500] tracking-[-0.8px] laptop:tracking-normal">
                        {t("referralPage.referalActivity.topEarners")}{" "}
                        <img
                          className="icon-bta"
                          style={{ opacity: TopEarning ? "1" : "0.6" }}
                          src={topEarnerIcon}
                          alt=""
                        />
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => setTopEarning(false)}
                    className={`${
                      TopEarning ? "hover-one " : "active"
                    }  cnt-width css-grbf26 relative`}
                  >
                    <div className="css-gmuwbf">
                      {!TopEarning && (
                        <>
                          {" "}
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                        </>
                      )}
                      <p className="text-[16px] flex items-center font-[500] tracking-[-0.8px] laptop:tracking-normal">
                        {t("referralPage.referalActivity.myReferal")}{" "}
                        <img
                          className="icon-bta"
                          style={{ opacity: TopEarning ? "0.6" : "1" }}
                          src={myRefferalIcon}
                          alt=""
                        />
                        <span className="my-referrals-icon-wrapper ml-2 relative">
                          {pendingReferralCount > 0 && (
                            <span className="badge radiating-badge">
                              {pendingReferralCount}
                            </span>
                          )}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* {!TopEarning && referralCount ? (
              <div
                className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                style={{
                  background: "#0000008c",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span className="text-[#79716B] text-[14px] font-[500] leading-[170%]">
                    {t("referralPage.rank.yourReferees")}
                  </span>
                  <div className="cursor-pointer">
                    <TooltipMain text={t("toolTips.investorReferral")}>
                      <img src={CircleInfo} style={{ height: "16px" }} alt="" />
                    </TooltipMain>
                  </div>
                </div>
                <span className="text-white text-[20px] font-[500] leading-[140%]">
                  {referralCount ? referralCount : "0"}
                </span>
              </div>
            ) : (
              ""
            )} */}
            <div className="_line_18dqk_1 mb-[8px]" style={{ width: "100%" }} />
            {/** LOADING / ERROR states for My Referrals */}
            <>
              {/* ---- DESKTOP: TOP EARNERS or MY REFERRALS ---- */}
              {isDesk ? (
                TopEarning ? (
                  // --------------- DESKTOP: TOP EARNERS ---------------
                  <>
                    <>
                      <div
                        className="pr-[20px] pl-[12px] flex items-center text-[16px] font-medium leading-[120%] mb-[18px] thishid"
                        style={{ color: "rgba(255, 255, 255, 0.32)" }}
                      >
                        <span className="flex-[1_1_12%] pl-[8px]">#</span>
                        <span className="flex-[1_1_25%]">
                          <span className="flex gap-[8px] items-center">
                            <img className="max-w-[20px]" src={Eth} />
                            {t("referralPage.referalActivity.evmWallt")}
                          </span>
                        </span>
                        <span className="flex-[1_1_20%]">
                          {t("referralPage.referalActivity.referals")}
                        </span>
                        <span className="flex-[1_1_18%] text-right">
                          {t("referralPage.referalActivity.totalEarnings")}
                        </span>
                      </div>
                      {topUsers === null ? (
                        <UserListShimmerPlaceholder />
                      ) : (
                        <div className="flex flex-col gap-[6px] laptop:gap-[4px]">
                          <li
                            className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
                            style={{
                              background:
                                "linear-gradient(135deg, #1c1c1c, #fc643233)",

                              border: "0.7px solid #FC6432",
                            }}
                          >
                            <span className="flex-[1_1_12%] flex">
                              <span className="w-[32px] h-[32px] flex justify-center items-center relative">
                                {/* corner borders */}
                                <svg
                                  fill="none"
                                  height="34"
                                  width="34"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 34 34"
                                >
                                  <path
                                    d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                    stroke="#fc6432"
                                    strokeOpacity="0.5"
                                  />
                                  <path
                                    d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                    stroke="#fc6432"
                                    strokeOpacity="0.5"
                                  />
                                  <path
                                    d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                    stroke="#fc6432"
                                    strokeOpacity="0.5"
                                  />
                                  <path
                                    d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                    stroke="#fc6432"
                                    strokeOpacity="0.5"
                                  />
                                  <text
                                    x="50%"
                                    y="53%"
                                    fill="#FAFAFA"
                                    fontFamily="Matter SQ"
                                    fontSize="16"
                                    fontWeight="bold"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                  >
                                    {userRankingByRewards
                                      ? userRankingByRewards
                                      : "..."}
                                  </text>
                                </svg>
                              </span>
                            </span>
                            <span className="flex-[1_1_25%] flex-evm">
                              <div className="w-[32px] h-[32px] rounded-full bg-defaultOrange overflow-hidden mr-[8px]">
                                <img src={iconDataURL} alt="user-avatar" />
                              </div>
                              {address.slice(0, 6) + "..." + address.slice(-4)}
                            </span>
                            <span className="flex-[1_1_20%]">
                              {referralCount || referralCount === 0
                                ? referralCount
                                : "..."}
                            </span>
                            <span className="flex-[1_1_18%] text-right">
                              $
                              {totalReferralRewardsInDollars ||
                              totalReferralRewardsInDollars === 0
                                ? totalReferralRewardsInDollars.toFixed(2)
                                : "..."}
                            </span>
                          </li>
                          {topEarningPageData.map((user, index) => {
                            const icon = blockies.create({
                              seed: user.wallet_address.toLowerCase(),
                              size: 8,
                              scale: 4,
                              color: "#FC6432",
                              bgcolor: "#3B334C",
                              spotcolor: "#C04000",
                            });
                            const iconDataURL = icon.toDataURL();
                            // 1) Convert both addresses to lowercase
                            const isCurrentUser =
                              address &&
                              address.toLowerCase() ===
                                user.wallet_address.toLowerCase();

                            return (
                              <li
                                key={index}
                                className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
                                style={{
                                  background: isCurrentUser
                                    ? "linear-gradient(135deg, #1c1c1c, #fc643233)"
                                    : "rgb(28,28,28)",
                                  border: "1px solid #2e2e2e",
                                  // border: isCurrentUser
                                  //   ? "1px solid #FC6432"
                                  //   : "1px solid #2e2e2e",
                                }}
                              >
                                <span className="flex-[1_1_12%] flex">
                                  <span className="w-[32px] h-[32px] flex justify-center items-center relative">
                                    {/* corner borders */}
                                    <svg
                                      fill="none"
                                      height="34"
                                      width="34"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 34 34"
                                    >
                                      <path
                                        d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                        stroke="#fc6432"
                                        strokeOpacity="0.5"
                                      />
                                      <path
                                        d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                        stroke="#fc6432"
                                        strokeOpacity="0.5"
                                      />
                                      <path
                                        d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                        stroke="#fc6432"
                                        strokeOpacity="0.5"
                                      />
                                      <path
                                        d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                        stroke="#fc6432"
                                        strokeOpacity="0.5"
                                      />
                                      <text
                                        x="50%"
                                        y="53%"
                                        fill="#FAFAFA"
                                        fontFamily="Matter SQ"
                                        fontSize="16"
                                        fontWeight="bold"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                      >
                                        {user.rewards_ranking}
                                      </text>
                                    </svg>
                                  </span>
                                </span>
                                <span className="flex-[1_1_25%] flex-evm">
                                  <div className="w-[32px] h-[32px] rounded-full bg-defaultOrange overflow-hidden mr-[8px]">
                                    <img src={iconDataURL} alt="user-avatar" />
                                  </div>
                                  {user.wallet_address.slice(0, 6) +
                                    "..." +
                                    user.wallet_address.slice(-4)}
                                </span>
                                <span className="flex-[1_1_20%]">
                                  {user.number_of_referrals}
                                </span>
                                <span className="flex-[1_1_18%] text-right">
                                  $
                                  {parseFloat(user.amount_in_dollars).toFixed(
                                    2
                                  )}
                                </span>
                              </li>
                            );
                          })}
                        </div>
                      )}
                    </>
                  </>
                ) : (
                  <>
                    <div
                      className="pr-[20px] pl-[12px] flex items-center text-[16px] font-medium leading-[120%] mb-[18px] thishid"
                      style={{ color: "rgba(255, 255, 255, 0.32)" }}
                    >
                      {/* Column #1: Date */}
                      <span
                        className="ipad-my-referral-rewards-ernead-header"
                        style={{ width: "12%" }}
                      >
                        {t("referralPage.referalActivity.date")}
                      </span>

                      {/* Column #2: Referral Wallet */}
                      <span
                        className="ipad-my-referral-rewards-ernead-header gap-[8px] inline-flex"
                        style={{ width: "25%" }}
                      >
                        <img className="max-w-[20px]" src={Eth} />
                        {t("referralPage.referalActivity.referralWallet")}
                      </span>

                      {/* Column #3: Purchase Amount */}
                      <span
                        className="ipad-my-referral-rewards-ernead-header"
                        style={{ width: "20%" }}
                      >
                        {t("referralPage.referalActivity.purchaseAmount")}
                      </span>

                      {/* Column #4: Network */}
                      <span
                        className="ipad-my-referral-rewards-ernead-header"
                        style={{ width: "20%" }}
                      >
                        {t("referralPage.referalActivity.network")}
                      </span>

                      {/* Column #5: Rewards Earned */}
                      <span
                        className="ipad-my-referral-rewards-ernead-header"
                        style={{ width: "23%" }}
                      >
                        {t("referralPage.referalActivity.rewardsEarned")}
                      </span>
                    </div>

                    <div className="flex flex-col gap-[6px] laptop:gap-[4px]">
                      {referralCount ? (
                        <li
                          className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
                          style={{ background: "rgb(28,28,28)" }}
                        >
                          <div
                            className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                            style={{
                              background: "#0000008c",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span className="text-[#79716B] text-[14px] font-[500] leading-[170%]">
                                {t("referralPage.rank.yourReferees")}
                              </span>
                              <div className="cursor-pointer">
                                <TooltipMain
                                  text={t("toolTips.investorReferral")}
                                >
                                  <img
                                    src={CircleInfo}
                                    style={{ height: "16px" }}
                                    alt=""
                                  />
                                </TooltipMain>
                              </div>
                            </div>
                            <span className="text-white text-[20px] font-[500] leading-[140%]">
                              {referralCount ? referralCount : "0"}
                            </span>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                      {referralTransactionsData === null ? (
                        // If referralTransactionsData is still null, show shimmer
                        <ReferralsListShimmerPlaceholder />
                      ) : myReferralsPageData?.length === 0 ? (
                        // If we actually have data but it's empty, show "No data" UI
                        <div
                          className="empty-state text-center flex justify-center items-center"
                          style={{
                            padding: "30px",
                            backgroundColor: "rgba(0,0,0,0.5)",
                            borderRadius: "10px",
                            height: "414px",
                          }}
                        >
                          <div className="flex flex-col items-center">
                            <img
                              src={folderOpen}
                              alt="No data"
                              style={{
                                width: "45px",
                                height: "45px",
                                marginBottom: "12px",
                              }}
                            />
                            <p
                              className="primary-text font-semibold mb-2 text-lg"
                              style={{ color: "#fff", fontSize: "20px" }}
                            >
                              {t("referralPage.referalActivity.noreferalData")}
                            </p>
                            <p
                              className="secondary-text text-sm text-gray-300"
                              style={{
                                fontSize: "14px",
                                color: "#8D8D8D",
                                paddingBottom: "12px",
                              }}
                            >
                              {t("referralPage.referalActivity.inviteOthers")}
                            </p>
                            {referralLink && (
                              <div
                                className="_copyWrapper_1fjrj_1"
                                aria-label={t(
                                  "referralPage.banner.copiedToClipboard"
                                )}
                              >
                                <span className="_copyText_1fjrj_14">
                                  {referralLink || "Loading.."}
                                </span>
                                {copied ? (
                                  <div className="_copyBtn_1fjrj_20">
                                    <img src={done} alt="Copied" />
                                  </div>
                                ) : (
                                  referralLink && (
                                    <button
                                      className="_copyBtn_1fjrj_20"
                                      onClick={() => handleCopy(referralLink)}
                                    >
                                      <img src={copy} alt="Copy" />
                                    </button>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        // Else, map out all the referrals data
                        myReferralsPageData.map((txItem, i) => {
                          const tokenMap = {
                            ETH: "ethereum",
                            BNB: "binancecoin",
                            POL: "polygon",
                            AVAX: "avalanche-2",
                            ARB: "arbitrum",
                            OP: "optimism",
                            ZK: "zk-rollups",
                            USDT: "USDT",
                            USDC: "USDC",
                            DAI: "DAI",
                          };
                          // Create the blockies for your referral address
                          const blocky = blockies.create({
                            seed: txItem.referralAddress.toLowerCase(),
                            size: 8,
                            scale: 4,
                            color: "#FC6432",
                            bgcolor: "#3B334C",
                            spotcolor: "#C04000",
                          });
                          const blockyDataURL = blocky.toDataURL();

                          // Which row number are we on?
                          const overallIndex =
                            (referralsPage - 1) * itemsPerPage + i + 1;

                          // Use helpers to grab the chain + token images
                          const chainInfo = getChainInfoByChainId(
                            Number(txItem.network)
                          );
                          const tokenImage = getTokenImage(
                            Number(txItem.network),
                            txItem.currency
                          );
                          return (
                            <li
                              key={i}
                              className="py-[11px] single-rk-row pl-[11px] pr-[19px] text-[#FAFAFA] flex items-center text-[16px] font-medium leading-[120%] rounded-[8px] border border-borderColor"
                              style={{ background: "rgb(28,28,28)" }}
                            >
                              {/* Column #1: Row index or date */}

                              <span
                                className="flex ipad-my-referral-rewards-ernead"
                                style={{ width: "12%" }}
                              >
                                <TooltipMain
                                  text={
                                    txItem.claimedAt
                                      ? formatDateTimeUtc(txItem.claimedAt)
                                      : "Pending rewards - claim in dashboard"
                                  }
                                >
                                  <span className=" rt-Text sc-qZrbh iHUxZB">
                                    {txItem.claimedAt ? (
                                      formatDateOnly(txItem.claimedAt)
                                    ) : (
                                      <div className="inline-flex text">
                                        <div
                                          className="liveDot"
                                          style={{
                                            marginLeft: "5px",
                                            marginRight: "15px",
                                            marginTop: "3px",
                                          }}
                                        ></div>
                                        {t(
                                          "referralPage.referalActivity.pending"
                                        )}
                                      </div>
                                    )}
                                  </span>
                                </TooltipMain>
                              </span>

                              {/* Column #2: Referral Wallet Address */}
                              <span
                                className="flex-evm ipad-my-referral-rewards-ernead"
                                style={{ width: "25%" }}
                              >
                                <div className="w-[32px] h-[32px] rounded-full bg-defaultOrange overflow-hidden mr-[8px]">
                                  <img
                                    src={blockyDataURL}
                                    alt="referral-wallet"
                                  />
                                </div>
                                {txItem.referralAddress.slice(0, 6) +
                                  "..." +
                                  txItem.referralAddress.slice(-4)}
                              </span>

                              {/* Column #3: Purchase Amount + Token icon */}
                              <span
                                className="flex items-center gap-2 ipad-my-referral-rewards-ernead"
                                style={{ width: "20%" }}
                              >
                                {tokenImage && (
                                  <img
                                    src={tokenImage}
                                    alt={txItem.currency}
                                    style={{ width: 18, height: 18 }}
                                  />
                                )}
                                {txItem.purchaseAmount.toFixed(3)}
                              </span>

                              {/* Column #4: Network icon + Name */}
                              <span
                                className="flex items-center gap-2 ipad-my-referral-rewards-ernead"
                                style={{ width: "20%" }}
                              >
                                {chainInfo && (
                                  <img
                                    src={chainInfo.selectedCoinImg}
                                    alt={chainInfo.name}
                                    style={{ width: 18, height: 18 }}
                                  />
                                )}
                                {chainInfo ? chainInfo.name : txItem.network}
                              </span>

                              {/* Column #5: Rewards Earned */}
                              <span
                                className="inline-flex text-right ipad-my-referral-rewards-ernead-margin"
                                style={{ width: "23%" }}
                              >
                                <TooltipMain
                                  text={
                                    txItem.rewardsUsd
                                      ? txItem.isClaimed
                                        ? "Claimed Crypto Reward: $" +
                                          (
                                            txItem.cryptoRewards *
                                            (txItem.claimedPrice || 0)
                                          ).toFixed(2)
                                        : `Claimable Crypto Reward: $${(
                                            txItem.cryptoRewards *
                                            (prices[
                                              tokenMap[
                                                txItem.currency.toUpperCase()
                                              ]
                                            ]?.usd ?? 0)
                                          ).toFixed(2)}`
                                      : ""
                                  }
                                >
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <img
                                      src={tokenImage}
                                      alt={txItem.currency}
                                      style={{
                                        width: 18,
                                        height: 18,
                                        marginRight: "5px",
                                      }}
                                    />
                                    {txItem.cryptoRewards.toFixed(3)}
                                  </span>{" "}
                                </TooltipMain>

                                <span className="inline-flex">
                                  <span
                                    className="iHUxZB"
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <span> / </span>
                                  </span>{" "}
                                  <TooltipMain
                                    text={"Claiming will be available post-TGE"}
                                  >
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <img
                                        src={snovaCoin}
                                        style={{
                                          height: "18px",
                                          marginRight: "5px",
                                        }}
                                        alt=""
                                      />
                                      {txItem.snovaRewards.toFixed(3)}{" "}
                                    </span>{" "}
                                  </TooltipMain>
                                </span>

                                <span className="inline-flex">
                                  <span
                                    className="iHUxZB"
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <span> / </span>
                                  </span>{" "}
                                  <img
                                    src={nPoints}
                                    style={{
                                      height: "16px",
                                      marginRight: "5px",
                                    }}
                                    alt=""
                                  />
                                  {txItem.novaRewards.toFixed(2)}
                                </span>
                              </span>
                            </li>
                          );
                        })
                      )}
                    </div>
                  </>
                )
              ) : TopEarning ? (
                // --------------- MOBILE: TOP EARNERS ---------------
                <>
                  {topUsers === null ? (
                    <UserListShimmerPlaceholderMobile />
                  ) : (
                    <div className="flex flex-col gap-[6px] laptop:gap-[4px]">
                      <li
                        className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
                        style={{
                          background:
                            "linear-gradient(135deg, #1c1c1c, #fc643233)",
                          color: "rgba(255, 255, 255, 0.32)",
                          border: "0.7px solid #FC6432",
                        }}
                      >
                        <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
                          <img
                            className="_Image_15l8v_1 _load_15l8v_7 _fadeIn_15l8v_15 absolute w-full max-w-full max-h-full top-0 left-0 z-[-1]"
                            src={bgRank}
                            style={{
                              objectFit: "cover",
                            }}
                            alt="bg"
                          />
                          <div className="flex items-center">
                            <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px] text-[#FAFAFA]">
                              {/* corners */}
                              <svg
                                fill="none"
                                height="34"
                                width="34"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 34 34"
                              >
                                <path
                                  d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                  stroke="#fc6432"
                                  strokeOpacity="0.5"
                                />
                                <path
                                  d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                  stroke="#fc6432"
                                  strokeOpacity="0.5"
                                />
                                <path
                                  d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                  stroke="#fc6432"
                                  strokeOpacity="0.5"
                                />
                                <path
                                  d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                  stroke="#fc6432"
                                  strokeOpacity="0.5"
                                />
                                <text
                                  x="50%"
                                  y="53%"
                                  fill="#FAFAFA"
                                  fontFamily="Matter SQ"
                                  fontSize="16"
                                  fontWeight="bold"
                                  textAnchor="middle"
                                  dominantBaseline="middle"
                                >
                                  {userRankingByRewards
                                    ? userRankingByRewards
                                    : "..."}
                                </text>
                              </svg>
                            </span>
                            <span className="flex gap-[8px] items-center">
                              {/* <img className="max-w-[20px]" src={Eth} /> */}
                              EVM
                            </span>
                          </div>
                          <span className="text-[#FAFAFA]">
                            {address.slice(0, 6) + "..." + address.slice(-4)}
                          </span>
                        </div>
                        <div className="flex flex-col gap-[12px] text-white">
                          <div className="flex justify-between items-center">
                            <span>
                              {t("referralPage.referalActivity.referals")}
                            </span>
                            <span className="text-[#FAFAFA]">
                              {referralCount || referralCount === 0
                                ? referralCount
                                : "..."}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span>
                              {t("referralPage.referalActivity.totalEarnings")}{" "}
                              ($)
                            </span>
                            <span className="text-[#FAFAFA]">
                              $
                              {totalReferralRewardsInDollars ||
                              totalReferralRewardsInDollars === 0
                                ? totalReferralRewardsInDollars.toFixed(2)
                                : "..."}
                            </span>
                          </div>
                        </div>
                      </li>
                      {topEarningPageData.map((user, index) => {
                        const icon = blockies.create({
                          seed: user.wallet_address.toLowerCase(),
                          size: 8,
                          scale: 4,
                          color: "#FC6432",
                          bgcolor: "#3B334C",
                          spotcolor: "#C04000",
                        });
                        const isCurrentUser =
                          address &&
                          address.toLowerCase() ===
                            user.wallet_address.toLowerCase();

                        return (
                          <li
                            key={index}
                            className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
                            style={{
                              background: isCurrentUser
                                ? "linear-gradient(135deg, #1c1c1c, #fc643233)"
                                : "rgba(250, 250, 250, 0.04)",
                              color: "rgba(255, 255, 255, 0.32)",
                            }}
                          >
                            <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
                              <img
                                className="_Image_15l8v_1 _load_15l8v_7 _fadeIn_15l8v_15 absolute w-full max-w-full max-h-full top-0 left-0 z-[-1]"
                                src={bgRank}
                                style={{ objectFit: "cover" }}
                                alt="bg"
                              />
                              <div className="flex items-center">
                                <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px] text-[#FAFAFA]">
                                  {/* corners */}
                                  <svg
                                    fill="none"
                                    height="34"
                                    width="34"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 34 34"
                                  >
                                    <path
                                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <path
                                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <path
                                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <path
                                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <text
                                      x="50%"
                                      y="53%"
                                      fill="#FAFAFA"
                                      fontFamily="Matter SQ"
                                      fontSize="16"
                                      fontWeight="bold"
                                      textAnchor="middle"
                                      dominantBaseline="middle"
                                    >
                                      {user.rewards_ranking}
                                    </text>
                                  </svg>
                                </span>
                                <span className="flex gap-[8px] items-center">
                                  {/* <img className="max-w-[20px]" src={Eth} /> */}
                                  EVM
                                </span>
                              </div>
                              <span className="text-[#FAFAFA]">
                                {user.wallet_address.slice(0, 6) +
                                  "..." +
                                  user.wallet_address.slice(-4)}
                              </span>
                            </div>
                            <div className="flex flex-col gap-[12px] text-white">
                              <div className="flex justify-between items-center">
                                <span>
                                  {t("referralPage.referalActivity.referals")}
                                </span>
                                <span className="text-[#FAFAFA]">
                                  {user.number_of_referrals}
                                </span>
                              </div>
                              <div className="flex justify-between items-center">
                                <span>
                                  {t(
                                    "referralPage.referalActivity.totalEarnings"
                                  )}{" "}
                                  ($)
                                </span>
                                <span className="text-[#FAFAFA]">
                                  $
                                  {parseFloat(user.amount_in_dollars).toFixed(
                                    2
                                  )}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  )}
                </>
              ) : (
                // --------------- MOBILE: MY REFERRALS ---------------
                <>
                  {referralTransactionsData === null ? (
                    <ReferralsListShimmerPlaceholderMobile />
                  ) : myReferralsPageData?.length === 0 ? (
                    // If we actually have data but it's empty, show "No data" UI
                    <div
                      className="empty-state text-center flex justify-center items-center"
                      style={{
                        padding: "30px",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        borderRadius: "10px",
                        height: "414px",
                      }}
                    >
                      <div className="flex flex-col items-center">
                        <img
                          src={folderOpen}
                          alt="No data"
                          style={{
                            width: "38px",
                            height: "38px",
                            marginBottom: "12px",
                          }}
                        />
                        <p
                          className="primary-text font-semibold mb-2 text-lg"
                          style={{ color: "#fff", fontSize: "17px" }}
                        >
                          {t("referralPage.referalActivity.noreferalData")}
                        </p>
                        <p
                          className="secondary-text text-sm text-gray-300"
                          style={{
                            fontSize: "14px",
                            color: "#8D8D8D",
                            paddingBottom: "12px",
                          }}
                        >
                          {t("referralPage.referalActivity.inviteOthers")}
                        </p>
                        {referralLink && (
                          <div
                            className="_copyWrapper_1fjrj_1"
                            aria-label={t(
                              "referralPage.banner.copiedToClipboard"
                            )}
                          >
                            <span className="_copyText_1fjrj_14">
                              {displayShortLink || "Loading.."}
                            </span>
                            {copied ? (
                              <div className="_copyBtn_1fjrj_20">
                                <img src={done} alt="Copied" />
                              </div>
                            ) : (
                              referralLink && (
                                <button
                                  className="_copyBtn_1fjrj_20"
                                  onClick={() => handleCopy(referralLink)}
                                >
                                  <img src={copy} alt="Copy" />
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-[6px] laptop:gap-[4px]">
                      {referralCount ? (
                        <li
                          className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
                          style={{ background: "rgb(28,28,28)" }}
                        >
                          <div
                            className="flex flex-[1_0_40%] items-center justify-center flex-col gap-[4px] p-[4px]"
                            style={{
                              background: "#0000008c",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <span className="text-[#79716B] text-[14px] font-[500] leading-[170%]">
                                {t("referralPage.rank.yourReferees")}
                              </span>
                              <div className="cursor-pointer">
                                <TooltipMain
                                  text={t("toolTips.investorReferral")}
                                >
                                  <img
                                    src={CircleInfo}
                                    style={{ height: "16px" }}
                                    alt=""
                                  />
                                </TooltipMain>
                              </div>
                            </div>
                            <span className="text-white text-[20px] font-[500] leading-[140%]">
                              {referralCount ? referralCount : "0"}
                            </span>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                      {myReferralsPageData.map((txItem, i) => {
                        const overallIndex =
                          (referralsPage - 1) * itemsPerPage + i + 1;

                        const tokenMap = {
                          ETH: "ethereum",
                          BNB: "binancecoin",
                          POL: "polygon",
                          AVAX: "avalanche-2",
                          ARB: "arbitrum",
                          OP: "optimism",
                          ZK: "zk-rollups",
                          USDT: "USDT",
                          USDC: "USDC",
                          DAI: "DAI",
                        };

                        // Helpers for chain + token images
                        const chainInfo = getChainInfoByChainId(
                          Number(txItem.network)
                        );
                        const tokenImage = getTokenImage(
                          Number(txItem.network),
                          txItem.currency
                        );

                        return (
                          <li
                            key={i}
                            className="p-[12px] rounded-[8px] border border-borderColor flex flex-col gap-[16px] text-[16px] font-medium leading-[120%]"
                            style={{
                              background: "rgba(250, 250, 250, 0.04)",
                              color: "rgba(255, 255, 255, 0.32)",
                            }}
                          >
                            <div className="flex justify-between items-center py-[8px] pl-[8px] pr-[16px] relative">
                              <img
                                className="_Image_15l8v_1 _load_15l8v_7 _fadeIn_15l8v_15 absolute w-full max-w-full max-h-full top-0 left-0 z-[-1]"
                                src={bgRank}
                                style={{ objectFit: "cover" }}
                                alt="bg"
                              />
                              <div className="flex items-center">
                                <span className="w-[32px] h-[32px] flex justify-center items-center relative mr-[12px] text-[#FAFAFA]">
                                  {/* corner borders */}
                                  <svg
                                    fill="none"
                                    height="34"
                                    width="34"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 34 34"
                                  >
                                    <path
                                      d="M1 7L1 3C1 1.89543 1.89543 1 3 1L7 1"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <path
                                      d="M1 27V31C1 32.1046 1.89543 33 3 33H7"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <path
                                      d="M27 33L31 33C32.1046 33 33 32.1046 33 31L33 27"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <path
                                      d="M33 7L33 3C33 1.89543 32.1046 1 31 1L27 1"
                                      stroke="#fc6432"
                                      strokeOpacity="0.5"
                                    />
                                    <text
                                      x="50%"
                                      y="53%"
                                      fill="#FAFAFA"
                                      fontFamily="Matter SQ"
                                      fontSize="16"
                                      fontWeight="bold"
                                      textAnchor="middle"
                                      dominantBaseline="middle"
                                    >
                                      {overallIndex}
                                    </text>
                                  </svg>
                                </span>
                                <span className="flex gap-[8px] items-center">
                                  {t(
                                    "referralPage.referalActivity.referralWallet"
                                  )}
                                </span>
                              </div>
                              <span className="text-[#FAFAFA]">
                                {txItem.referralAddress.slice(0, 6) +
                                  "..." +
                                  txItem.referralAddress.slice(-4)}
                              </span>
                            </div>

                            <div className="flex flex-col gap-[12px] text-white">
                              {/* Purchase Amount + Token icon */}
                              <div className="flex justify-between items-center">
                                <span>
                                  {t(
                                    "referralPage.referalActivity.purchaseAmount"
                                  )}
                                </span>
                                <span className="text-[#FAFAFA] flex items-center gap-2">
                                  {tokenImage && (
                                    <img
                                      src={tokenImage}
                                      alt={txItem.currency}
                                      style={{ width: 16, height: 16 }}
                                    />
                                  )}
                                  {txItem.purchaseAmount}
                                </span>
                              </div>

                              {/* Network icon + Name */}
                              <div className="flex justify-between items-center">
                                <span>
                                  {t("referralPage.referalActivity.network")}
                                </span>
                                <span className="text-[#FAFAFA] flex items-center gap-2">
                                  {chainInfo && (
                                    <img
                                      src={chainInfo.selectedCoinImg}
                                      alt={chainInfo.name}
                                      style={{ width: 16, height: 16 }}
                                    />
                                  )}
                                  {chainInfo ? chainInfo.name : txItem.network}{" "}
                                </span>
                              </div>

                              {/* Rewards Earned */}
                              <div className="flex justify-between items-center">
                                <span>
                                  {t(
                                    "referralPage.referalActivity.rewardsEarned"
                                  )}
                                </span>
                                <span className="text-[#FAFAFA] mobile-m-ref-rewards">
                                  <span className="inline-flex text-right">
                                    <img
                                      className="mobile-icon-for-crypto-rew"
                                      src={tokenImage}
                                      alt={txItem.currency}
                                      style={{
                                        height: "18px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <span
                                      className="flex justify-between items-center"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        txItem.rewardsUsd
                                          ? txItem.isClaimed
                                            ? "Claimed Crypto Reward: $" +
                                              (
                                                txItem.cryptoRewards *
                                                (txItem.claimedPrice || 0)
                                              ).toFixed(2)
                                            : `Claimable Crypto Reward: $${(
                                                txItem.cryptoRewards *
                                                (prices[
                                                  tokenMap[
                                                    txItem.currency.toUpperCase()
                                                  ]
                                                ]?.usd ?? 0)
                                              ).toFixed(2)}`
                                          : ""
                                      }
                                    >
                                      {" "}
                                      {txItem.cryptoRewards.toFixed(3)}
                                      <Tooltip id="my-tooltip" />
                                    </span>{" "}
                                    <span
                                      className="iHUxZB"
                                      style={{
                                        marginLeft: "15px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <span> / </span>
                                    </span>{" "}
                                    <img
                                      src={snovaCoin}
                                      style={{
                                        height: "18px",
                                        paddingRight: "5px",
                                      }}
                                      alt=""
                                    />
                                    {/* <Tooltip
                                        text={
                                          "Claiming will be available post-TGE"
                                        }
                                      > */}
                                    <span
                                      className="flex justify-between items-center"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Claiming will be available post-TGE"
                                    >
                                      {" "}
                                      {txItem.snovaRewards.toFixed(3)}
                                      <Tooltip id="my-tooltip" />
                                      {/* </Tooltip> */}{" "}
                                    </span>{" "}
                                    <span
                                      className="iHUxZB"
                                      style={{
                                        marginLeft: "25px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <span> / </span>
                                    </span>
                                    <img
                                      src={nPoints}
                                      style={{
                                        height: "18px",
                                        paddingRight: "5px",
                                      }}
                                      alt=""
                                    />
                                    {txItem.novaRewards.toFixed(2)}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  )}
                </>
              )}

              {/* Example pagination UI (if desired) */}
              <div className="flex justify-center mt-[20px]">
                {(() => {
                  // Figure out which "page" + "totalPages" to use depending on the tab
                  const currentPage = TopEarning
                    ? topEarningPage
                    : referralsPage;
                  const totalPages = TopEarning
                    ? topEarningTotalPages
                    : myReferralsTotalPages;

                  // Generate an array of up to 3 pages to display
                  const displayedPages = getPaginationPages(
                    currentPage,
                    totalPages
                  );

                  if (
                    TopEarning ||
                    referralTransactionsData === null ||
                    myReferralsPageData?.length === 0 ||
                    topUsers === null
                  ) {
                    return null;
                  }

                  return (
                    //if TopEarners|| referralTransactionsData === null ||myReferralsPageData?.length === 0||topUsers === null //do not show it
                    <div
                      className="flex tablet:justify-center justify-between items-center mt-[20px] gap-[16px] relative z-[2]"
                      bis_skin_checked={1}
                    >
                      {/* PREVIOUS BUTTON */}
                      <button
                        style={{ padding: "20px", position: "relative" }}
                        className="transition-all rotate-[180deg] w-[48px] h-[48px] flex justify-center items-center active:scale-[0.98]"
                        onClick={() => {
                          // Decrement only if valid
                          if (TopEarning && topEarningPage > 1) {
                            setTopEarningPage(topEarningPage - 1);
                          } else if (!TopEarning && referralsPage > 1) {
                            setReferralsPage(referralsPage - 1);
                          }
                        }}
                        disabled={
                          TopEarning ? topEarningPage <= 1 : referralsPage <= 1
                        }
                      >
                        <i className="fa-solid fa-chevron-right"></i>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                      </button>

                      {/* 3 (or fewer) PAGE LINKS */}
                      <div
                        className="text-[16px] font-[500] leading-[120%] flex gap-[8px]"
                        style={{ color: "rgba(250, 250, 250, 0.32)" }}
                        bis_skin_checked={1}
                      >
                        {displayedPages.map((pageNum) => (
                          <span
                            key={pageNum}
                            className={`cursor-pointer ${
                              pageNum === currentPage ? "text-[#FAFAFA]" : ""
                            }`}
                            onClick={() => {
                              // Jump directly to clicked page
                              if (TopEarning) {
                                setTopEarningPage(pageNum);
                              } else {
                                setReferralsPage(pageNum);
                              }
                            }}
                          >
                            {pageNum}
                          </span>
                        ))}
                      </div>

                      {/* NEXT BUTTON */}
                      <button
                        style={{ padding: "20px", position: "relative" }}
                        className="transition-all w-[48px] h-[48px] flex justify-center items-center active:scale-[0.98]"
                        onClick={() => {
                          // Increment only if valid
                          if (
                            TopEarning &&
                            topEarningPage < topEarningTotalPages
                          ) {
                            setTopEarningPage(topEarningPage + 1);
                          } else if (
                            !TopEarning &&
                            referralsPage < myReferralsTotalPages
                          ) {
                            setReferralsPage(referralsPage + 1);
                          }
                        }}
                        disabled={
                          TopEarning
                            ? topEarningPage >= topEarningTotalPages
                            : referralsPage >= myReferralsTotalPages
                        }
                      >
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0"></div>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                        <i className="fa-solid fa-chevron-right"></i>
                      </button>
                    </div>
                  );
                })()}
              </div>
            </>
          </div>
        </div>
      </div>

      <TooltipMain id="my-tooltip" place="top" className="myrewardstool" />
      {showClaimModal && !hasNoRewards && chainInfo && (
        <>
          <ModalPortal>
            <div>
              <div
                onClick={() => {
                  if (!isClaimingProcess) {
                    setShowClaimModal(false);
                    // We deliberately do NOT call setselectedCoin("");
                  }
                }}
                className="modl-over"
              ></div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 9999,
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head flex items-center gap-2">
                      <img
                        src={chainInfo.selectedCoinImg}
                        alt={chainInfo.name}
                        style={{ width: "24px", height: "24px" }}
                      />
                      {t("referralPage.referalActivity.pendingRewardsSummary")}
                    </h2>
                    <div
                      onClick={() => {
                        if (!isClaimingProcess) {
                          setShowClaimModal(false);
                          // We deliberately do NOT call setselectedCoin("");
                        }
                      }}
                      className="cross-ic"
                    >
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <h2 className="orange mibjz">
                    {t("referralPage.referalActivity.readyToSecure")}{" "}
                    {chainInfo.name}?
                  </h2>
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <img
                      src={Usd}
                      className="purchased-snova"
                      alt="usd"
                      style={{ height: "25px" }}
                    />
                    <span className="your-tokens">
                      {totalNetworkReward || "$0"}
                    </span>
                  </h3>
                  <div className="modal-body" style={{ position: "relative" }}>
                    {isClaimingProcess && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backdropFilter: "blur(5px)",
                          transition: "filter 0.3s ease-in-out;",
                          pointerEvents: "none",
                          cursor: "no-drop",
                          zIndex: 9999,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={LoaderIcon}
                          alt="Loading..."
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                    )}
                    <TokenBlock
                      chainInfo={chainInfo}
                      allRewards={allRewards}
                      formatValue={formatValue}
                      isClaimingProcess={isClaimingProcess}
                      isDesk={isDesk}
                    />
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={async () => {
                        try {
                          setIsClaimingProcess(true);
                          await handleClaim();
                          setShowClaimModal(false);
                          setselectedCoin("");
                        } catch (error) {
                          console.error(error);
                        } finally {
                          setIsClaimingProcess(false);
                        }
                      }}
                      className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                        cursor: isClaimingProcess ? "not-allowed" : "pointer",
                        opacity: isClaimingProcess ? 0.5 : 1,
                      }}
                      disabled={isClaimingProcess}
                    >
                      {isClaimingProcess
                        ? t("referralPage.rank.claiming")
                        : t("referralPage.rank.claimReward")}
                    </button>
                  </div>
                  <div
                    className="text-text-tip text-2xs text-center"
                    style={{ paddingTop: "8px" }}
                  >
                    <i>{t("referralPage.referalActivity.rewardsInclude")} </i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      )}

      {modal2.isOpen ? (
        <div onClick={toggleError} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal2.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  top: "33%",
                  position: "fixed",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {/* Error Modal */}
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={transactionError}
                        className="purchase-summary"
                      />

                      {t("popups.errorModal.transactionError")}
                    </h2>
                    <div onClick={toggleError} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseError}
                      className="purchase-success"
                      alt="Purhase Error"
                      style={{ width: "200px" }}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("popups.errorModal.transactionErrorDetail")}
                    </h2>
                  </div>
                  <h3 className="text-center bjpar your-tokens-bg">
                    <span className="your-tokens">
                      {t("popups.errorModal.insuficientGas.title")}
                    </span>
                  </h3>
                  <div className="card-stam">
                    <div className="card-item-stam">
                      <ol className="list-stam">
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.insuficientGas.errorTable.heading1"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.insuficientGas.errorTable.description1"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.insuficientGas.errorTable.heading2"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.insuficientGas.errorTable.description2"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.insuficientGas.errorTable.heading3"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.insuficientGas.errorTable.description3"
                          )}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleError}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.errorModal.tryAgain")}
                    </button>

                    <a
                      href="https://t.me/StacknovaBTC"
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      target="_blank"
                      rel="noreferrer"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      {t("popups.errorModal.getSupport")}
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.errorModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal3.isOpen ? (
        <div onClick={toggleSuccess} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal3.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img src={purchaseSummary} className="purchase-summary" />
                      {t("popups.summaryModal.rewardsSummary")}
                    </h2>
                    <div onClick={toggleSuccess} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>

                  <h2 id="radix-:R1r6mH1:" className="orange mibj">
                    {t("popups.summaryModal.wellDone")}
                  </h2>
                  <div className="confirmation-block">
                    <img src={claimSuccess} className="claim-success" />
                  </div>
                  <h3 className="text-center double-blk bjpar your-tokens-bg">
                    <div>
                      <img src={Usd} className="purchased-snova" alt="usd" />
                      <span className="your-tokens">
                        {modal3.data
                          ? modal3.data.totalNetworkReward + " USD"
                          : "0 USD"}
                      </span>
                    </div>
                  </h3>
                  <table className="table-cl">
                    <tbody>
                      <tr style={{ borderBottom: "2px solid #fc6432" }}>
                        <td
                          style={{ borderBottom: "none" }}
                          className="table-header no-tp nobot column-dark"
                        >
                          {t("referralPage.referalActivity.network")}{" "}
                        </td>
                        <td
                          className="table-cell no-tp column-light"
                          style={{ display: "flex", borderBottom: "none" }}
                        >
                          <img
                            src={
                              modal3.data
                                ? networkMap[modal3.data.network].icon
                                : Eth
                            }
                            className="network-icon"
                            alt="network"
                          />
                          {modal3.data
                            ? networkMap[modal3.data.network].name
                            : "Ethereum"}
                          <TooltipMain
                            text={t("homePage.popup.viewTransactionDetails")}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${explorerUrl}${txnHash}`}
                            >
                              <img
                                src={transactionLink}
                                className="transaction-link add-space"
                                alt="transaction link"
                              />
                            </a>
                          </TooltipMain>
                        </td>
                      </tr>
                      {(() => {
                        let rowCounter = 0;
                        const rows = [];
                        if (
                          modal3.data &&
                          modal3.data.amounts.main.amount > 0
                        ) {
                          rows.push(
                            <tr key="main">
                              <td
                                className={`table-header no-tp nmop ${
                                  rowCounter % 2 === 0
                                    ? "column-light"
                                    : "column-dark"
                                }`}
                              >
                                {networkMap[modal3.data.network].mainCurrency +
                                  " Claimed"}
                              </td>
                              <td
                                className={`table-cell no-tp flex items-centers ${
                                  rowCounter % 2 === 0
                                    ? "column-dark"
                                    : "column-light"
                                }`}
                              >
                                <img
                                  src={
                                    currencyMap[
                                      networkMap[modal3.data.network]
                                        .mainCurrency
                                    ].symbol
                                  }
                                  className="network-icon"
                                  alt="main token"
                                />{" "}
                                {modal3.data.amounts.main.amount +
                                  " " +
                                  networkMap[modal3.data.network].mainCurrency +
                                  " ($" +
                                  modal3.data.amounts.main.usdValue +
                                  ")"}
                              </td>
                            </tr>
                          );
                          rowCounter++;
                        }

                        if (
                          modal3.data &&
                          modal3.data.amounts.usdt.amount > 0
                        ) {
                          rows.push(
                            <tr key="usdt">
                              <td
                                className={`table-header ${
                                  rowCounter % 2 === 0
                                    ? "column-light"
                                    : "column-dark"
                                }`}
                              >
                                USDT Claimed
                              </td>
                              <td
                                className={`table-cell nb ${
                                  rowCounter % 2 === 0
                                    ? "column-dark"
                                    : "column-light"
                                }`}
                                style={{ display: "flex", borderTop: "none" }}
                              >
                                <img
                                  src={Tether}
                                  className="payment-icon"
                                  alt="usdt"
                                />
                                {modal3.data.amounts.usdt.amount +
                                  " USDT" +
                                  " ($" +
                                  modal3.data.amounts.usdt.usdValue +
                                  ")"}
                              </td>
                            </tr>
                          );
                          rowCounter++;
                        }

                        if (
                          modal3.data &&
                          modal3.data.amounts.usdc.amount > 0
                        ) {
                          rows.push(
                            <tr key="usdc">
                              <td
                                className={`table-header ${
                                  rowCounter % 2 === 0
                                    ? "column-light"
                                    : "column-dark"
                                }`}
                              >
                                USDC {t("referralPage.referalActivity.claimed")}{" "}
                              </td>
                              <td
                                className={`table-cell nb ${
                                  rowCounter % 2 === 0
                                    ? "column-dark"
                                    : "column-light"
                                }`}
                                style={{ display: "flex", borderTop: "none" }}
                              >
                                <img
                                  src={UsdC}
                                  className="network-icon"
                                  alt="usdc"
                                />
                                {modal3.data.amounts.usdc.amount +
                                  " USDC" +
                                  " ($" +
                                  modal3.data.amounts.usdc.usdValue +
                                  ")"}
                              </td>
                            </tr>
                          );
                          rowCounter++;
                        }

                        if (modal3.data && modal3.data.amounts.dai.amount > 0) {
                          rows.push(
                            <tr key="dai">
                              <td
                                className={`table-header ${
                                  rowCounter % 2 === 0
                                    ? "column-light"
                                    : "column-dark"
                                }`}
                              >
                                DAI {t("referralPage.referalActivity.claimed")}{" "}
                              </td>
                              <td
                                className={`table-cell nb ${
                                  rowCounter % 2 === 0
                                    ? "column-dark"
                                    : "column-light"
                                }`}
                                style={{ display: "flex", borderTop: "none" }}
                              >
                                <img
                                  src={Dai}
                                  className="payment-icon"
                                  alt="dai"
                                />
                                {modal3.data.amounts.dai.amount +
                                  " DAI" +
                                  " ($" +
                                  modal3.data.amounts.dai.usdValue +
                                  ")"}
                              </td>
                            </tr>
                          );
                          rowCounter++;
                        }

                        return rows;
                      })()}
                    </tbody>
                  </table>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleSuccess}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.summaryModal.referMore")}
                    </button>
                    <NavLink
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      to="/dashboard"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      {t("popups.summaryModal.accessDashboard")}
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                    </NavLink>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.summaryModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal5.isOpen ? (
        <div onClick={toggleDeclined} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal5.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={transactionError}
                        className="purchase-summary"
                        alt="error"
                      />
                      {t("popups.errorModal.transactionError")}
                    </h2>
                    <div onClick={toggleDeclined} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseError}
                      className="purchase-success"
                      alt="Purhase Error"
                      style={{ width: "200px" }}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("popups.errorModal.transactionErrorDetail")}
                    </h2>
                  </div>
                  <h3 className="text-center bjpar your-tokens-bg">
                    <span className="your-tokens">
                      {t("popups.errorModal.rejection.title")}
                    </span>
                  </h3>
                  <div className="card-stam">
                    <div className="card-item-stam">
                      <ol className="list-stam">
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.rejection.errorTable.heading1"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.rejection.errorTable.description1"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.rejection.errorTable.heading2"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.rejection.errorTable.description2"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.rejection.errorTable.heading3"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.rejection.errorTable.description3"
                          )}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleDeclined}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.errorModal.tryAgain")}
                    </button>

                    <a
                      href="http://t.me/StacknovaBTC"
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      target="_blank"
                      rel="noreferrer"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      {t("popups.errorModal.getSupport")}
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.errorModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
      {modal6.isOpen ? (
        <div onClick={toggleUniversalError} className="modl-over"></div>
      ) : (
        ""
      )}
      {modal6.isOpen ? (
        <>
          <ModalPortal>
            <div>
              <div
                className="heo"
                style={{
                  // position: "absolute",
                  // top: `${scrollPosition}px`,
                  position: "fixed",
                  top: "33%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="relative myaa border-border bg-bg z-[999] flex w-full max-w-[550px] flex-col gap-2 rounded border p-2">
                  <div className="flex space-between">
                    <h2 className="scale-head">
                      <img
                        src={transactionError}
                        className="purchase-summary"
                        alt="error"
                      />
                      {t("popups.errorModal.transactionError")}
                    </h2>
                    <div onClick={toggleUniversalError} className="cross-ic">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H1.71429V1.71429H0V0ZM3.42857 3.42857H1.71429V1.71429H3.42857V3.42857ZM5.14286 5.14286H3.42857V3.42857H5.14286V5.14286ZM6.85714 5.14286H5.14286V6.85714H3.42857V8.57143H1.71429V10.2857H0V12H1.71429V10.2857H3.42857V8.57143H5.14286V6.85714H6.85714V8.57143H8.57143V10.2857H10.2857V12H12V10.2857H10.2857V8.57143H8.57143V6.85714H6.85714V5.14286ZM8.57143 3.42857V5.14286H6.85714V3.42857H8.57143ZM10.2857 1.71429V3.42857H8.57143V1.71429H10.2857ZM10.2857 1.71429V0H12V1.71429H10.2857Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="text-image-container">
                    <img
                      src={purchaseError}
                      className="purchase-success"
                      alt="Purhase Error"
                      style={{ width: "200px" }}
                    />
                    <h2 id="radix-:R1r6mH1:" className="orange mibj">
                      {t("popups.errorModal.transactionErrorDetail")}
                    </h2>
                  </div>
                  <h3 className="text-center bjpar your-tokens-bg">
                    <span className="your-tokens">
                      {t("popups.errorModal.universalFailure.title")}
                    </span>
                  </h3>
                  <div className="card-stam">
                    <div className="card-item-stam">
                      <ol className="list-stam">
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.universalFailure.errorTable.heading1"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.universalFailure.errorTable.description1"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.universalFailure.errorTable.heading2"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.universalFailure.errorTable.description2"
                          )}
                        </li>
                        <li className="list-item-stam">
                          <b>
                            {t(
                              "popups.errorModal.universalFailure.errorTable.heading3"
                            )}
                          </b>{" "}
                          {t(
                            "popups.errorModal.universalFailure.errorTable.description3"
                          )}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex main-gb">
                    <button
                      onClick={toggleUniversalError}
                      className="ant-btn css-dev-only-do-not-override-1q8nizr ant-btn-primary font-weight-bold"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(252, 100, 50)",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(192, 64, 0) 6px 6px",
                      }}
                    >
                      {t("popups.errorModal.tryAgain")}
                    </button>

                    <a
                      href="https://t.me/StacknovaBTC"
                      className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary secondary-transform"
                      target="_blank"
                      rel="noreferrer"
                      style={{ background: "transparent" }}
                    >
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                      {t("popups.errorModal.getSupport")}
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0" />
                      <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0" />
                    </a>
                  </div>
                  <div className="text-text-tip text-2xs text-center">
                    <i>{t("popups.errorModal.footerNote")}</i>
                  </div>
                </div>
              </div>
            </div>
          </ModalPortal>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Rank;
