import { useEffect, useRef } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useSelector } from "react-redux";

export default function IntercomInitializer() {
  const user = useSelector((state) => state.user.user);
  const hasInitializedRef = useRef(false);

  useEffect(() => {
    if (!hasInitializedRef.current) {
      hasInitializedRef.current = true;
      // console.log("Initialized");
      const intercomSettings = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      };

      if (user?._id) {
        intercomSettings.user_id = user._id.toString();
        if (user.connected_since) {
          intercomSettings.created_at = Math.floor(
            new Date(user.connected_since).getTime() / 1000
          );
        }
      }

      Intercom(intercomSettings);
    }
  }, [user]);

  return null;
}
