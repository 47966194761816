import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topUsers: null,
  topUsersByNovaPoints: null,
};

const userRankingsSlice = createSlice({
  name: "userRankings",
  initialState,
  reducers: {
    setTopUsersByReward: (state, action) => {
      state.topUsers = action.payload;
    },
    setTopUsersByNovaPoints: (state, action) => {
      state.topUsersByNovaPoints = action.payload;
    },
  },
});

export const { setTopUsersByReward, setTopUsersByNovaPoints } =
  userRankingsSlice.actions;

export default userRankingsSlice.reducer;
